import React, { useState } from 'react';
import { CognitoUser, AuthenticationDetails } from 'amazon-cognito-identity-js';
import userPool from '../../userPool';
import { Container, Row, Col, Form, Button, FloatingLabel, InputGroup, Modal } from "react-bootstrap";
import './Login.css';
import { ErrorAlert, SuccessAlert } from '../../utils/AlertUtils';
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import axiosInstance from '../../services/AxiosConfig';

const Login = ({ onPasswordChangeRequired }) => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [validated, setValidated] = useState(false);
    const [message, setMessage] = useState("");
    const [showError, setShowError] = useState(false);
    const [showSuccess, setShowSuccess] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [showForgotPasswordModal, setShowForgotPasswordModal] = useState(false);
    const [otp, setOtp] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [stage, setStage] = useState('email'); // 'email', 'otp'


    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    const handleShowError = (message) => {
        setShowError(true);
        setMessage(message);
    };

    const handleHideError = () => {
        setShowError(false);
    };

    const handleShowSuccess = (message) => {
        setShowSuccess(true);
        setMessage(message);
    };

    const handleSucessClose = () => {
        setShowSuccess(false);
    };

    const handleLogin = (e) => {
        const form = e.currentTarget;
        e.preventDefault();
        e.stopPropagation();

        if (form.checkValidity() === true) {
            const userData = {
                Username: email,
                Pool: userPool,
            };

            const authenticationDetails = new AuthenticationDetails({
                Username: email,
                Password: password,
            });

            const cognitoUser = new CognitoUser(userData);

            cognitoUser.authenticateUser(authenticationDetails, {
                onSuccess: (result) => {
                    const idToken = result.getIdToken().getJwtToken();
                    const accessToken = result.getAccessToken().getJwtToken();
                    const refreshToken = result.getRefreshToken().getToken();

                    localStorage.setItem('accessToken', accessToken);
                    handleShowSuccess("Login Successful");
                    window.location.href = "/"; // Force a full page reload
                },
                onFailure: (err) => {
                    handleShowError(err.message);
                },
                newPasswordRequired: (userAttributes, requiredAttributes) => {
                    console.log('New password required:', userAttributes);
                    // Trigger password change UI
                    onPasswordChangeRequired(cognitoUser, userAttributes);
                },
            });
            setValidated(false);
        }
        setValidated(true);
    };

    // const initiateForgotPassword = () => {
    //     const cognitoUser = new CognitoUser({ Username: email, Pool: userPool });
    //     cognitoUser.forgotPassword({
    //         onSuccess: () => setStage('otp'),
    //         onFailure: (err) => handleShowError(err.message),
    //     });
    // };
    const initiateForgotPassword = async () => {
        try {
            const response = await axiosInstance.post('/api/auth/forgot-password', null, { params: { username: email } });
            handleShowSuccess(response.data || "OTP sent successfully.");
            setStage('otp');
        } catch (error) {
            handleShowError(error.response?.data || "Failed to send OTP. Please try again.");
        }
    };

    const confirmNewPassword = async () => {
        try {
            const response = await axiosInstance.post('/api/auth/confirm-forgot-password', null, {
                params: {
                    username: email,
                    otp,
                    newPassword
                }
            });
            handleShowSuccess(response.data || "Password reset successful. Please log in.");
            setShowForgotPasswordModal(false);
            setStage('email');
        } catch (error) {
            handleShowError(error.response?.data || "Failed to reset password. Please try again.");
        }
    };

    // const confirmNewPassword = () => {
    //     const cognitoUser = new CognitoUser({ Username: email, Pool: userPool });
    //     cognitoUser.confirmPassword(otp, newPassword, {
    //         onSuccess: () => {
    //             handleShowSuccess("Password reset successful. Please log in.");
    //             setShowForgotPasswordModal(false);
    //             setStage('email');
    //         },
    //         onFailure: (err) => handleShowError(err.message),
    //     });
    // };

    return (
        <div>
            <Container className="container">
                <Row>
                    <Col lg="6" className="header">
                        <div>
                            <h1 className="header-title">ANTS</h1>
                            <h3 className="header-subtitle">Annexure Tracking System</h3>
                        </div>
                    </Col>
                    <Col lg="6">
                        <div className="form-container">
                            <div className="form-content">
                                <Form noValidate validated={validated} onSubmit={handleLogin}>
                                    <FloatingLabel label="Email" controlId="email">
                                        <Form.Control
                                            autoFocus
                                            required
                                            type="email"
                                            placeholder="Email"
                                            onChange={(e) => setEmail(e.target.value)}
                                        />
                                    </FloatingLabel>
                                    <br />
                                    <InputGroup>
                                        <FloatingLabel label="Password" controlId="password">
                                            <Form.Control
                                                type={showPassword ? "text" : "password"}
                                                required
                                                placeholder="Password"
                                                onChange={(e) => setPassword(e.target.value)}
                                            />
                                        </FloatingLabel>
                                        <InputGroup.Text onClick={togglePasswordVisibility}>
                                            {showPassword ? <FaEyeSlash /> : <FaEye />}
                                        </InputGroup.Text>
                                    </InputGroup>
                                    <br />
                                    <Button variant="primary" type="submit"> Login </Button>
                                    <br />
                                    <br />
                                    <Button variant="link" onClick={() => setShowForgotPasswordModal(true)}>
                                        Forgot Password?
                                    </Button>
                                    <ErrorAlert
                                        showError={showError}
                                        onHide={handleHideError}
                                        message={message}
                                    />
                                    <SuccessAlert
                                        showSucess={showSuccess}
                                        onHide={handleSucessClose}
                                        message={message}
                                    />
                                </Form>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>

            {/* Forgot Password Modal */}
            <Modal show={showForgotPasswordModal} onHide={() => {
                setShowForgotPasswordModal(false);
                setStage('email'); // Reset to email stage
            }}>
                <Modal.Header closeButton>
                    <Modal.Title>{stage === 'email' ? "Forgot Password" : "Enter OTP"}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {stage === 'email' ? (
                        <FloatingLabel label="Email" controlId="forgotEmail">
                            <Form.Control
                                type="email"
                                placeholder="Enter your email"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                            />
                        </FloatingLabel>
                    ) : (
                        <>
                            <FloatingLabel label="OTP" controlId="otp">
                                <Form.Control
                                    type="text"
                                    placeholder="Enter OTP"
                                    value={otp}
                                    onChange={(e) => setOtp(e.target.value)}
                                />
                            </FloatingLabel>
                            <br />
                            <FloatingLabel label="New Password" controlId="newPassword">
                                <Form.Control
                                    type="password"
                                    placeholder="New Password"
                                    value={newPassword}
                                    onChange={(e) => setNewPassword(e.target.value)}
                                />
                            </FloatingLabel>
                        </>
                    )}
                </Modal.Body>
                <Modal.Footer>
                    {stage === 'email' ? (
                        <Button variant="primary" onClick={initiateForgotPassword}>Send OTP</Button>
                    ) : (
                        <Button variant="primary" onClick={confirmNewPassword}>Reset Password</Button>
                    )}
                </Modal.Footer>
            </Modal>
        </div>
    );
};

export default Login;