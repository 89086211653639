import { loadStripe } from '@stripe/stripe-js';
import axiosInstance from '../../services/AxiosConfig';
import { createPaymentDTO } from '../checkout/PaymentDTO';

// const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY); // Load your Stripe public key here
const stripePromise = loadStripe('pk_test_51Q1iOwP4ODtJBhqBwclV5XfYVfEyxTENemA7z26PkTA1C6Td8smqIz6UOCI3Zf8auYO5uwBIWz9lz8AhpGZ12aAT00YokLQKIV'); // Replace with your publishable key

export const handleCheckout = async (annexureParentId, onError) => {
  try {
    const paymentDTO = createPaymentDTO(annexureParentId);
    const response = await axiosInstance.post('/api/payment/create-checkout-session', paymentDTO);

    if (!response.data) {
      onError(`Stripe checkout error: ${response.message}`);
      return;
    }

    const session = response.data;

    const stripe = await stripePromise;
    const { error } = await stripe.redirectToCheckout({
      sessionId: session.id,
    });

    if (error) {
      onError(`Stripe checkout error: ${error.message}`);
    }
  } catch (error) {
    onError(`Stripe checkout error: ${error.message}`);
  }
};