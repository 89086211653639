// 'use strict';
import React, { useMemo, useState } from 'react';
import { AgGridReact } from 'ag-grid-react';
import "ag-grid-community/styles/ag-grid.css"; // Mandatory CSS required by the grid
import "ag-grid-community/styles/ag-theme-quartz.css"; // Optional Theme appli
import { ModuleRegistry } from 'ag-grid-community';
import { ClientSideRowModelModule } from 'ag-grid-community';
import { Row, Col, Button } from 'react-bootstrap';

ModuleRegistry.registerModules([ClientSideRowModelModule]);

const SupplierDetails = () => {
    const [rowData, setRowData] = useState([
        { Supplier: 'Amazon', Email: '123@123.com', Status: 'Active', 'Locations': '3', 'Contacts': '2' },
        { Supplier: 'Amazon', Email: '123@123.com', Status: 'Active', 'Locations': '3', 'Contacts': '2' },
        { Supplier: 'Amazon', Email: '123@123.com', Status: 'Active', 'Locations': '3', 'Contacts': '2' },
        { Supplier: 'Amazon', Email: '123@123.com', Status: 'Active', 'Locations': '3', 'Contacts': '2' },
        { Supplier: 'Amazon', Email: '123@123.com', Status: 'Active', 'Locations': '3', 'Contacts': '2' },
        { Supplier: 'Amazon', Email: '123@123.com', Status: 'Active', 'Locations': '3', 'Contacts': '2' },
    ]);

    const [columnDefs, setColumnDefs] = useState([
        {
            field: "Supplier",
            checkboxSelection: true,
            editable: true,
            cellEditor: 'agSelectCellEditor'
        },
        { field: "Email" },
        { field: "Status" },
        { field: "Locations" },
        { field: "Contacts" }
    ]);

    const defaultColDef = useMemo(() => {
        return {
            filter: 'agTextColumnFilter',
            floatingFilter: true,
        }
    }, []);

    return (
        <div style={{ width: "100%", backgroundColor: "rgba(178, 224, 171,.4", padding: "1%", borderRadius: "10px" }}>
            <Row>

                <Col lg="9"><span></span></Col>
                <Col>
                    <Row>
                        <Col><Button variant="primary" size="sm" href="/annexform">Modify</Button></Col>
                        <Col><Button variant="primary" size="sm" href="/annexform">Delete</Button></Col>
                    </Row>
                </Col>
            </Row>
            <br />
            <div className="ag-theme-quartz" style={{ height: 400 }}>
                <AgGridReact
                    rowData={rowData}
                    columnDefs={columnDefs}
                    defaultColDef={defaultColDef}
                    rowSelection="single"
                    suppressRowClickSelection={true}
                    pagination={true}
                    paginationPageSize={10}
                    paginationPageSizeSelector={[10, 25, 50]}
                />
            </div>
        </div>
    );
}

export default SupplierDetails;