import React, { useState } from 'react';
import Login from '../Login/Login';
import SignUp from '../SignUp/SignUp';
import './HomePage.css';
import ResetTempPassword from '../../services/ResetTempPassword';

function HomePage() {
  const [activeTab, setActiveTab] = useState('login');
  const [cognitoUser, setCognitoUser] = useState(null);
  const [userAttributes, setUserAttributes] = useState(null);

  const handlePasswordChangeRequired = (user, attributes) => {
    setCognitoUser(user);
    setUserAttributes(attributes);
  };

  const renderTabContent = () => {
    switch (activeTab) {
      case 'login':
        return (
          <div>
            {cognitoUser ? (
              <ResetTempPassword cognitoUser={cognitoUser} userAttributes={userAttributes} />
            ) : (
              <Login onPasswordChangeRequired={handlePasswordChangeRequired} />
            )}
          </div>
        );
      case 'signup':
        return <SignUp />;
      default:
        return (
          <div>
            {cognitoUser ? (
              <ResetTempPassword cognitoUser={cognitoUser} userAttributes={userAttributes} />
            ) : (
              <Login onPasswordChangeRequired={handlePasswordChangeRequired} />
            )}
          </div>
        );
    }
  };

  return (
    <div className="homepage-container">
      <div className="tab-container">
        <button
          className={`tab ${activeTab === 'login' ? 'active' : ''}`}
          onClick={() => setActiveTab('login')}
        >
          Login
        </button>
        <button
          className={`tab ${activeTab === 'signup' ? 'active' : ''}`}
          onClick={() => setActiveTab('signup')}
        >
          Sign Up
        </button>
      </div>
      <div className="tab-content">
        {renderTabContent()}
      </div>
    </div>
  );
}

export default HomePage;