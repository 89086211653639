import React from 'react';
import { Col, Form, Row, FloatingLabel, Button, InputGroup } from 'react-bootstrap';
import DatePicker from 'react-datepicker';

const RecoveryTab = ({ annexureParent, onUpdate, validated, onValidation, moveToNextTab }) => {
  const handleChange = (e) => {
    const { name, value } = e.target;
    onUpdate({ [name]: value });
  };

  const handleSubmit = (event) => {
    const form = event.currentTarget;
    event.preventDefault();
    event.stopPropagation();

    const formErrors = form.querySelectorAll(':invalid').length;

    if (formErrors === 0) {
      onValidation(0); // No errors
      moveToNextTab(); // Move to the next tab
    } else {
      onValidation(formErrors); // Update error count
    }
  };

  return (
    <Form noValidate validated={validated} onSubmit={handleSubmit}>
      <div style={{ width: "100%", backgroundColor: "rgba(178, 224, 171,.4", padding: "2%", borderRadius: "10px" }}>
        <Row className="mb-3">
          <Col xs={12} md={4} lg={4}>
            <FloatingLabel controlId="floatingRecoveryOperation" label="Recovery Operation">
              <Form.Control
                placeholder="Enter Recovery Operation"
                name="recoveryOperation"
                onChange={handleChange}
                value={annexureParent.recoveryOperation}
              />
            </FloatingLabel>
          </Col>

          <Col xs={12} md={8} lg={4}>
            <Form.Check
              type="radio"
              name="recoveryFacility"
              value="Recovery Facility"
              label="Recovery Facility"
              onChange={handleChange}
              checked={annexureParent.recoveryFacility === 'Recovery Facility'}
            />
            <Form.Check
              type="radio"
              name="recoveryFacility"
              value="Laboratory"
              label="Laboratory"
              onChange={handleChange}
              checked={annexureParent.recoveryFacility === 'Laboratory'}
            />
          </Col>
        </Row>

        <Row className="mb-3">
          <Col xs={12} md={4} lg={4}>
            <FloatingLabel controlId="floatingFacilityName" label="Name">
              <Form.Control
                placeholder="Enter Name"
                name="facilityName"
                onChange={handleChange}
                value={annexureParent.facilityName}
                disabled
              />
            </FloatingLabel>
          </Col>

          <Col xs={12} md={4} lg={4}>
            <FloatingLabel controlId="floatingAddress" label="Address">
              <Form.Control
                placeholder="Enter Address"
                name="facilityAddress"
                onChange={handleChange}
                value={annexureParent.facilityAddress}
                disabled
              />
            </FloatingLabel>
          </Col>

          <Col xs={12} md={4} lg={4}>
            <FloatingLabel controlId="floatingContactPerson" label="Contact Person">
              <Form.Control
                placeholder="Enter Contact Name"
                name="facilityContactPerson"
                onChange={handleChange}
                value={annexureParent.facilityContactPerson}
                disabled
              />
            </FloatingLabel>
          </Col>
        </Row>

        <Row className="mb-3">
          <Col xs={12} md={4} lg={4}>
            <FloatingLabel controlId="floatingTelephone" label="Telephone">
              <Form.Control
                placeholder="Enter Telephone"
                name="facilityTelephone"
                onChange={handleChange}
                value={annexureParent.facilityTelephone}
                disabled
              />
            </FloatingLabel>
          </Col>

          <Col xs={12} md={4} lg={4}>
            <FloatingLabel controlId="floatingFax" label="Fax">
              <Form.Control
                placeholder="Enter Fax"
                name="facilityFax"
                onChange={handleChange}
                value={annexureParent.facilityFax}
                disabled
              />
            </FloatingLabel>
          </Col>

          <Col xs={12} md={4} lg={4}>
            <FloatingLabel controlId="floatingEmail" label="Email">
              <Form.Control
                placeholder="Enter Email Address"
                name="facilityEmail"
                onChange={handleChange}
                value={annexureParent.facilityEmail}
                disabled
              />
            </FloatingLabel>
          </Col>
        </Row>

        {/* <Row>
          <Col>
            <div style={{ display: "flex", flexDirection: "row-reverse" }}>
              <Button disabled={shipperDisplay} size="sm" onClick={e => handleRecoverySave(details.index)}>
                Save & Next
              </Button>
            </div>
          </Col>
        </Row> */}
        {/* <Form.Group>
        <Form.Label>Facility Name</Form.Label>
        <Form.Control
          type="text"
          name="facilityName"
          value={annexureParent.facilityName}
          onChange={handleChange}
        />
      </Form.Group>
      <Form.Group>
        <Form.Label>Facility Address</Form.Label>
        <Form.Control
          type="text"
          name="facilityAddress"
          value={annexureParent.facilityAddress}
          onChange={handleChange}
        />
      </Form.Group>
      Add more recovery-related fields */}
        <Row>
          <Col>
            <div style={{ display: "flex", flexDirection: "row-reverse" }}>
              <Button variant="primary" type="submit">Save and Next</Button>
            </div>
          </Col>
        </Row>
      </div>
    </Form>
  );
};

export default RecoveryTab;