import React, { useState } from 'react';
import { Modal, Button } from 'react-bootstrap'; // Make sure to have react-bootstrap installed
import UploadSignature from '../Signature/UploadSignature';


const SignatureModal = ({ showModal, signatureUrl, handleContinue, handleUpload, handleClose }) => {
    const [showUpload, setShowUpload] = useState(false);

    const handleChangeSignature = () => {
        setShowUpload(true); // Toggle to show the upload component
    };

    const handleModalClose = () => {
        setShowUpload(false); // Reset showUpload state to false
        handleClose(); // Call the provided close function
    };

    const handleModalUpload = () => {
        setShowUpload(false); // Reset showUpload state to false
        handleUpload(); // Call the provided upload function
    };

    return (
        <Modal show={showModal} onHide={handleModalClose}>
            <Modal.Header closeButton>
                <Modal.Title>Signature Verification</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {signatureUrl ? ( // Check if signatureUrl is present
                    showUpload ? (
                        // Show the UploadSignature component if the user chooses to change their signature
                        <UploadSignature onUploadSuccess={handleModalUpload} />
                    ) : (
                        <>
                            <p>Your current signature:</p>
                            <img src={signatureUrl} alt="Current Signature" style={{ width: '100%' }} />
                            <p>Do you want to continue with this signature or upload a new one?</p>
                        </>
                    )
                ) : (
                    // If signatureUrl is not present, show only the upload option
                    <>
                        <p>You do not have a signature uploaded.</p>
                        <p>Please upload your signature:</p>
                        <UploadSignature onUploadSuccess={handleUpload} />
                    </>
                )}
            </Modal.Body>
            <Modal.Footer>
                {showUpload || !signatureUrl ? (
                    // Show only the Cancel button if uploading or no signature present
                    <Button variant="secondary" onClick={handleClose}>Cancel</Button>
                ) : (
                    <>
                        <Button variant="secondary" onClick={handleChangeSignature}>
                            Upload New Signature
                        </Button>
                        {/* Hide this button if there is no signature */}
                        {signatureUrl && (
                            <Button variant="primary" onClick={handleContinue}>
                                Continue with Current Signature
                            </Button>
                        )}
                    </>
                )}
            </Modal.Footer>
        </Modal>
    );
};

export default SignatureModal;