import axios from 'axios';
import { jwtDecode } from 'jwt-decode';
import { Navigate } from 'react-router-dom';
import React from 'react';

// Create an instance of Axios
const axiosInstance = axios.create({
    baseURL: 'http://98.81.188.104:8080', //'http://localhost:8080', // Replace with your API base URL
    // baseURL: 'http://localhost:8080',
    timeout: 100000, // Optional: Set a default timeout
});

// Request Interceptor
axiosInstance.interceptors.request.use(
    (config) => {
        const token = localStorage.getItem('accessToken');
        if (token) {
            try {
                const decodedToken = jwtDecode(token);
                const currentTime = Math.floor(Date.now() / 1000); // Current time in seconds

                // Check if auth_time is less than the current time
                if (decodedToken?.exp && decodedToken.exp < currentTime) {
                    // Perform redirection to logout page
                    localStorage.clear();
                    window.location.href = '/';
                    return Promise.reject('Session expired. Redirecting to logout.');
                }
                config.headers.Authorization = `Bearer ${token}`;
            } catch (error) {
                console.error("Invalid token", error);
                localStorage.clear();
                window.location.href = '/';
                return Promise.reject('Invalid token. Redirecting to login.');
            }
        } else {
            console.log(config.url);
            if (config.method === 'post' && config.url === '/api/signUp') {
                return config;
            } else if (config.method === 'get' && (config.url.startsWith('/api/location'))) {
                return config;
            } else if (config.method === 'get' && config.url.startsWith('/api/email/verify-email')) {
                return config;
            } else if (config.method === 'post' && config.url.startsWith('/api/auth')) {
                return config;
            }

            console.error("No token");
            localStorage.clear();
            window.location.href = '/login';
            return Promise.reject('No token found. Redirecting to login.');
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

// Response Interceptor
axiosInstance.interceptors.response.use(
    (response) => {
        return response;
    },
    (error) => {
        console.log("response: ", error);
        if (error.response && error.response.status === 401) {
            // Handle unauthorized errors (e.g., redirect to login page)
            console.error('Unauthorized! Redirecting to login...');
            <Navigate to="/logIn" replace />
        }
        return Promise.reject(error);
    }
);

export default axiosInstance;