// src/SignUp.js
import React, { useEffect, useState } from 'react';
import './SignUp.css';
import { Form, Button, Spinner } from "react-bootstrap";
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import InputGroup from 'react-bootstrap/InputGroup';
import Alert from 'react-bootstrap/Alert';
import { ErrorAlert, SuccessAlert } from '../../utils/AlertUtils';
import axiosInstance from '../../services/AxiosConfig';
import { Typeahead } from 'react-bootstrap-typeahead';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import countryPhoneCodes from '../../data/countryCodes.json'; // Import country data
import { parsePhoneNumberFromString, parsePhoneNumberWithError, ParseError } from 'libphonenumber-js';

function SignUp() {
  const [formData, setFormData] = useState({
    userDTO: {
      firstName: '',
      lastName: '',
      emailId: '',
      phoneNumber: '',
      phoneCountryCode: '',
      faxNumber: '',
      faxCountryCode: '',
      primaryUser: true,
      language: 'English',
      status: '',
      roleName: '',
      entityId: ''
    },
    locationRequestDTO: {
      locationId: '',
      entityId: '',
      addressLine1: '',
      addressLine2: '',
      addressLine3: '',
      city: '',
      state: '',
      county: '',
      country: '',
      zipCode: '',
      isHeadQuarter: true,
      status: '',
      primaryContactId: '',
      nonPrimaryContactIds: []
    },
    entityRequestDTO: {
      entityType: '',
      entityName: '',
      referralId: ''
    }
  });

  const [validated, setValidated] = useState(false);
  const [message, setMessage] = useState("");
  const [showError, setShowError] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [selectedCountryPhone, setSelectedCountryPhone] = useState([]);
  const [selectedCountryFax, setSelectedCountryFax] = useState([]);
  const [phoneNumber, setPhoneNumber] = useState('');
  const [faxNumber, setFaxNumber] = useState('');
  const [isValidPhoneNumber, setIsValidPhoneNumber] = useState(true);
  const [isValidFaxNumber, setIsValidFaxNumber] = useState(true);
  const [phoneErrorMsg, setPhoneErrorMsg] = useState('');
  const [faxErrorMsg, setFaxErrorMsg] = useState('');
  const [countryCodePhone, setCountryCodePhone] = useState('');
  const [dialCodePhone, setDialCodePhone] = useState('');
  const [countryCodeFax, setCountryCodeFax] = useState('');
  const [dialCodeFax, setDialCodeFax] = useState('');
  const [emailValid, setEmailValid] = useState(true); // State to track email validity
  const [isValidating, setIsValidating] = useState(false);  // State for loading indicator


  useEffect(() => {
    // Fetch countries data on component load
    axiosInstance.get('/api/location/countries')
      .then(response => setCountries(response.data))
      .catch(error => handleShowError('Error fetching countries'));
  }, []);

  // Handle country selection from Typeahead
  const handleCountryChangePhoneNumber = (selected) => {
    setSelectedCountryPhone(selected);
    if (selected.length > 0) {
      // const { dial_code, code } = selected[0];
      setDialCodePhone(selected[0].dial_code);
      setCountryCodePhone(selected[0].code);
    } else {
      setDialCodePhone('');
      setCountryCodePhone('');
    }
  };

  const handleCountryChangeFaxNumber = (selected) => {
    setSelectedCountryFax(selected);
    if (selected.length > 0) {
      // const { dial_code, code } = selected[0];
      setDialCodeFax(selected[0].dial_code);
      setCountryCodeFax(selected[0].code);
    } else {
      setDialCodeFax('');
      setCountryCodeFax('');
    }
  };

  // Handle phone number input and validate using libphonenumber-js
  const handlePhoneNumberChange = (e) => {
    const value = e.target.value;
    setPhoneNumber(value);
    if (dialCodePhone) {
      setFormData((prevData) => ({
        ...prevData,
        userDTO: {
          ...prevData.userDTO,
          phoneNumber: value,
          phoneCountryCode: countryCodePhone
        }
      }));

      const fullNumber = `${value}`;
      try {
        const phoneNumberObj = parsePhoneNumberWithError(fullNumber, countryCodePhone);
        // If valid, clear error and set valid state
        setIsValidPhoneNumber(phoneNumberObj.isValid());
        setPhoneErrorMsg(''); // Clear error message when valid
      } catch (error) {
        if (error instanceof ParseError) {
          // Handle specific errors from the libphonenumber-js library
          console.log(error.message);
          switch (error.message) {
            case 'NOT_A_NUMBER':
              setPhoneErrorMsg('The input is not a valid number.');
              break;
            case 'TOO_SHORT':
              setPhoneErrorMsg('The phone number is too short for the selected country.');
              break;
            case 'TOO_LONG':
              setPhoneErrorMsg('The phone number is too long.');
              break;
            default:
              setPhoneErrorMsg('Invalid phone number.');
          }
        } else {
          // Handle any unexpected errors
          setPhoneErrorMsg('An unexpected error occurred.');
        }
        setIsValidPhoneNumber(false);
      }
    } else {
      setIsValidPhoneNumber(false); // Assume valid if no country is selected yet
    }
  };

  const handleFaxNumberChange = (e) => {
    const value = e.target.value;
    setFaxNumber(value);
    if (dialCodeFax) {
      setFormData((prevData) => ({
        ...prevData,
        userDTO: {
          ...prevData.userDTO,
          faxNumber: value,
          faxCountryCode: countryCodeFax
        }
      }));

      const fullNumber = `${value}`;
      // const phoneNumberObj = parsePhoneNumberFromString(fullNumber, countryCode);
      try {
        const phoneNumberObj = parsePhoneNumberWithError(fullNumber, countryCodeFax);
        // If valid, clear error and set valid state
        setIsValidFaxNumber(phoneNumberObj.isValid());
        setFaxErrorMsg(''); // Clear error message when valid
      } catch (error) {
        console.log(error);
        if (error instanceof ParseError) {
          // Handle specific errors from the libphonenumber-js library
          console.log(error.message);
          switch (error.message) {
            case 'NOT_A_NUMBER':
              setFaxErrorMsg('The input is not a valid number.');
              break;
            case 'TOO_SHORT':
              setFaxErrorMsg('The phone number is too short for the selected country.');
              break;
            case 'TOO_LONG':
              setFaxErrorMsg('The phone number is too long.');
              break;
            default:
              setFaxErrorMsg('Invalid phone number.');
          }
        } else {
          // Handle any unexpected errors
          setFaxErrorMsg('An unexpected error occurred.');
        }
        setIsValidFaxNumber(false);
      }
    } else {
      setIsValidFaxNumber(false); // Assume valid if no country is selected yet
    }
  };

  // Custom filter function
  const filterByPhoneNumber = (countryCode, props) => {
    const query = props.text.toLowerCase(); // normalize the search query to lowercase
    return (
      countryCode.name.toLowerCase().includes(query) || // search by country name
      countryCode.code.toLowerCase().includes(query) || // search by country code (IN, US, etc.)
      countryCode.dial_code.includes(query) // search by dial code (+91, +1, etc.)
    );
  };


  const handleCountryChange = (selected) => {
    const country = selected[0];

    if (country) {
      // Update country in form data and reset state and city
      setFormData((prevData) => ({
        ...prevData,
        locationRequestDTO: {
          ...prevData.locationRequestDTO,
          country: country.name,
          state: '',  // Reset state when country changes
          city: ''    // Reset city when country changes
        }
      }));

      // Fetch states based on the selected country
      axiosInstance.get(`/api/location/states?countryId=${country.id}`)
        .then(response => {
          setStates(response.data);  // Set the fetched states
          setCities([]);             // Reset cities as new country is selected
        })
        .catch(error => handleShowError('Error fetching states'));
    } else {
      // Reset country, state, and city if selection is cleared
      setFormData((prevData) => ({
        ...prevData,
        locationRequestDTO: {
          ...prevData.locationRequestDTO,
          country: '',
          state: '',  // Reset state when country is cleared
          city: ''    // Reset city when country is cleared
        }
      }));

      setStates([]); // Clear states if no country is selected
      setCities([]); // Clear cities as well
    }
  };

  const handleStateChange = (selected) => {
    const state = selected[0];

    if (state) {
      // Update state in form data and reset city
      setFormData((prevData) => ({
        ...prevData,
        locationRequestDTO: {
          ...prevData.locationRequestDTO,
          state: state.name,
          city: '' // Reset city when state changes
        }
      }));

      // Fetch cities based on the selected state
      axiosInstance.get(`/api/location/cities?stateId=${state.id}`)
        .then(response => {
          setCities(response.data);
        })
        .catch(error => handleShowError('Error fetching cities'));
    } else {
      // Reset state and city if selection is cleared
      setFormData((prevData) => ({
        ...prevData,
        locationRequestDTO: {
          ...prevData.locationRequestDTO,
          state: '',
          city: '' // Reset city when state is cleared
        }
      }));

      setCities([]); // Clear cities if no state is selected
    }
  };

  const handleCityChange = (selected) => {
    const city = selected[0];

    if (city) {
      // Update city in form data
      setFormData((prevData) => ({
        ...prevData,
        locationRequestDTO: {
          ...prevData.locationRequestDTO,
          city: city.name
        }
      }));
    } else {
      // Reset city if selection is cleared
      setFormData((prevData) => ({
        ...prevData,
        locationRequestDTO: {
          ...prevData.locationRequestDTO,
          city: ''
        }
      }));
    }
  };


  const validatePhoneNumber = (number) => {
    // Regex for validating phone numbers (10 digits)
    const phoneRegex = /^\d{10}$/;
    return phoneRegex.test(number);
  };

  const handleShowError = (message) => {
    setShowError(true);
    setMessage(message);
  };

  const handleHideError = () => {
    setShowError(false);
  };

  const handleShowSuccess = (message) => {
    setShowSuccess(true);
    setShowError(false);
    setMessage(message);
  };

  const handleSucessClose = () => {
    setShowSuccess(false);
  };

  const handleChange = (e) => {
    const { name, value, dataset } = e.target;

    setFormData((prevData) => {
      // Create a new state object
      const updatedData = {
        ...prevData,
        [dataset.formSection]: {
          ...prevData[dataset.formSection],
          [name]: value
        }
      };

      // If the changed field is entityType, update roleName in userDTO
      if (name === 'entityType') {
        let roleName = '';
        switch (value) {
          case 'SUPPLIER':
            roleName = 'ROLE_SUPPLIER';
            break;
          case 'SHIPPER':
            roleName = 'ROLE_SHIPPER';
            break;
          case 'CONSIGNEE':
            roleName = 'ROLE_CONSIGNEE';
            break;
          default:
            roleName = '';
        }

        // Update roleName in userDTO
        updatedData.userDTO = {
          ...updatedData.userDTO,
          roleName: roleName
        };
      }

      return updatedData;
    });
  };

  // Function to call the backend for email verification
  const verifyEmailFromServer = async (email) => {
    try {
      const response = await axiosInstance.get("/api/email/verify-email", {
        params: { emailId: email },
      });
      return response.data; // Return true if email is valid
    } catch (error) {
      console.error("Error verifying email", error);
      return false; // Return false if an error occurs
    }
  };

  const formatSubmissionData = () => {
    const formattedPhoneNumber = `${countryMap.get(formData.userDTO.phoneCountryCode)?.dial_code ?? ''}${formData.userDTO.phoneNumber ?? ''}`;
    const formattedFaxNumber = `${countryMap.get(formData.userDTO.faxCountryCode)?.dial_code ?? ''}${formData.userDTO.faxNumber ?? ''}`;

    return {
      ...formData,
      userDTO: {
        ...formData.userDTO,
        phoneNumber: formattedPhoneNumber,
        faxNumber: formattedFaxNumber,
      }
    };
  };


  const handleSubmit = async (e) => {
    e.preventDefault();
    e.stopPropagation();

    const form = e.currentTarget;

    // Ensure form validation before submitting
    if (form.checkValidity() === false) {
      setValidated(true);
      return;
    }

    setIsValidating(true);
    const isEmailValid = await verifyEmailFromServer(formData.userDTO.emailId);
    setIsValidating(false);
    console.log(isEmailValid);
    if (isEmailValid) {
      setEmailValid(true); // Email is valid
    } else {
      setEmailValid(false);
      setValidated(true);
      return;
    }

    try {
      // Format data for submission
      const formattedData = formatSubmissionData();

      // Submit the formatted data using axios
      const response = await axiosInstance.post('/api/signUp', formattedData);

      if (response.status === 200) {
        // Handle success case
        handleShowSuccess("Please check your email for further steps.");
      } else {
        // Extract and throw the error if any
        const errorData = await response.text();
        throw new Error(errorData || 'Something went wrong');
      }

    } catch (error) {
      // Handle error more gracefully
      const errorMessage = error?.response?.data || error.message || 'Submission failed';
      handleShowError(errorMessage);
    } finally {
      setValidated(false); // Reset validation after form submission
    }
  };

  const countryMap = new Map(
    countryPhoneCodes.map((country) => [country.code, country])
  );

  return (
    <div className='signup-form'>
      <h2>Sign Up</h2>
      <Form noValidate onSubmit={handleSubmit}>
        <div className="signup-form-container">
          <div className='company-info'>
            <h3>Company Information</h3>
            <Form.Group size="lg" controlId="entityType">
              <Form.Select
                autoFocus
                required
                name="entityType"
                value={formData.entityRequestDTO.entityType}
                onChange={handleChange}
                data-form-section="entityRequestDTO"
                isInvalid={validated && !formData.entityRequestDTO.entityType}
              >
                <option value="" disabled>Select Company Type</option>
                <option value="SUPPLIER">Supplier</option>
                <option value="SHIPPER">Shipper</option>
                <option value="CONSIGNEE">Consignee</option>
              </Form.Select>
              <Form.Control.Feedback type="invalid">
                Please select one of the values from the company type.
              </Form.Control.Feedback>
            </Form.Group>
            <br />
            <FloatingLabel controlId="entityName" label="Company Name">
              <Form.Control
                required
                type="text"
                name="entityName"
                value={formData.entityRequestDTO.entityName}
                placeholder="Company Name"
                onChange={handleChange}
                data-form-section="entityRequestDTO"
                isInvalid={validated && !formData.entityRequestDTO.entityName}
              />
              <Form.Control.Feedback type="invalid">
                Please provide a valid company name.
              </Form.Control.Feedback>
            </FloatingLabel>
            <br />
            <FloatingLabel label="Referral Company ID" controlId="referralId">
              <Form.Control
                type="text"
                name="referralId"
                value={formData.entityRequestDTO.referralId}
                placeholder="Referral ID"
                onChange={handleChange}
                data-form-section="entityRequestDTO"
              />
              <Form.Control.Feedback type="invalid">
                Please provide a valid referral ID.
              </Form.Control.Feedback>
            </FloatingLabel>
            <br />
          </div>

          <div className='user-info'>
            <h3>User Information</h3>
            <FloatingLabel label="First Name" controlId="firstName">
              <Form.Control
                required
                type="text"
                name="firstName"
                value={formData.userDTO.firstName}
                placeholder="First Name"
                onChange={handleChange}
                data-form-section="userDTO"
                isInvalid={validated && !formData.userDTO.firstName}
              />
              <Form.Control.Feedback type="invalid">
                Please provide a valid first name.
              </Form.Control.Feedback>
            </FloatingLabel>
            <br />

            <FloatingLabel label="Last Name" controlId="lastName">
              <Form.Control
                required
                type="text"
                name="lastName"
                value={formData.userDTO.lastName}
                placeholder="Last Name"
                onChange={handleChange}
                data-form-section="userDTO"
                isInvalid={validated && !formData.userDTO.lastName}
              />
              <Form.Control.Feedback type="invalid">
                Please provide a valid last name.
              </Form.Control.Feedback>
            </FloatingLabel>
            <br />

            {console.log(validated && (!formData.userDTO.emailId || !emailValid), validated, formData.userDTO.emailId, emailValid)}
            <FloatingLabel label="Email ID" controlId="emailId">
              <Form.Control
                required
                type="email"
                name="emailId"
                value={formData.userDTO.emailId}
                placeholder="Email ID"
                onChange={handleChange}
                data-form-section="userDTO"
                isInvalid={validated && (!formData.userDTO.emailId || !emailValid)}
              />
              <Form.Control.Feedback type="invalid">
                {emailValid
                  ? "Please provide a valid email ID."
                  : "This email is invalid according to server validation."}
              </Form.Control.Feedback>
            </FloatingLabel>
            <br />

            <InputGroup>
              <FloatingLabel controlId="floatingCountryPhone">
                <Typeahead
                  id="country-typeahead"
                  labelKey={(option) => `${option.dial_code} (${option.code})`}
                  filterBy={filterByPhoneNumber}
                  options={countryPhoneCodes}
                  placeholder="Code"
                  onChange={handleCountryChangePhoneNumber}
                  selected={selectedCountryPhone}
                  renderMenuItemChildren={(option) => (
                    <div>
                      {option.name} <small>({option.dial_code})</small>
                    </div>
                  )}
                  className="floating-typeahead" // Custom class for styling
                />
              </FloatingLabel>
              <FloatingLabel controlId="floatingPhoneNumber" label="Phone Number">
                <Form.Control
                  type="text"
                  placeholder="Enter phone number"
                  value={formData.userDTO.phoneNumber}
                  onChange={handlePhoneNumberChange}
                  isInvalid={validated && (!formData.userDTO.phoneNumber || !isValidPhoneNumber)}
                />
                <Form.Control.Feedback type="invalid">
                  {phoneErrorMsg ||
                    (selectedCountryPhone.length > 0
                      ? `Invalid phone number for ${selectedCountryPhone[0].name}.`
                      : 'Please select a country to validate the phone number.')
                  }
                </Form.Control.Feedback>
              </FloatingLabel>
            </InputGroup>
            <br />

            <InputGroup>
              <FloatingLabel controlId="floatingCountryFax">
                <Typeahead
                  id="country-typeahead"
                  labelKey={(option) => `${option.dial_code} (${option.code})`}
                  filterBy={filterByPhoneNumber}
                  options={countryPhoneCodes}
                  placeholder="Code"
                  onChange={handleCountryChangeFaxNumber}
                  selected={selectedCountryFax}
                  renderMenuItemChildren={(option) => (
                    <div>
                      {option.name} <small>({option.dial_code})</small>
                    </div>
                  )}
                  className="floating-typeahead" // Custom class for styling
                />
              </FloatingLabel>
              <FloatingLabel controlId="floatingFaxNumber" label="Fax Number">
                <Form.Control
                  type="text"
                  placeholder="Enter fax number"
                  value={formData.userDTO.faxNumber}
                  onChange={handleFaxNumberChange}
                  isInvalid={validated && !isValidFaxNumber}
                />
                <Form.Control.Feedback type="invalid">
                  {faxErrorMsg ||
                    (selectedCountryFax.length > 0
                      ? `Invalid fax number for ${selectedCountryFax[0].name}.`
                      : 'Please select a country to validate the fax number.')
                  }
                </Form.Control.Feedback>
              </FloatingLabel>
            </InputGroup>
            <br />

            {/* <InputGroup>
              <Form.Select
                type="text"
                required
                name="phoneCountryCode"
                value={formData.userDTO.phoneCountryCode}
                onChange={handleChange}
                data-form-section="userDTO"
              >
                <option value="" disabled>Select Country Code</option>
                {[...countryMap.values()].map((country) => (
                  <option key={country.code} value={country.code}>
                    {country.flag} {country.dial_code} ({country.name})
                  </option>
                ))}
              </Form.Select>
              <FloatingLabel label="Phone Number" controlId="phoneNumber">
                <Form.Control
                  required
                  type="text"
                  name="phoneNumber"
                  value={formData.userDTO.phoneNumber}
                  placeholder="Phone Number"
                  onChange={handleChange}
                  data-form-section="userDTO"
                  pattern="^\d{10}$"
                  isInvalid={validated && !validatePhoneNumber(formData.userDTO.phoneNumber)}
                />
                {console.log(validated, validatePhoneNumber(formData.userDTO.phoneNumber))}
                <Form.Control.Feedback type="invalid">
                  Please enter a valid 10-digit phone number.
                </Form.Control.Feedback>
              </FloatingLabel>
            </InputGroup>
            <br /> */}

            {/* 
            This logic is sample to check both values in a filed.
            <FloatingLabel label="Phone Number" controlId="DpphoneNumber">
              <Form.Control
                
                required
                type="text"
                name="DpphoneNumber"
                value={formData.userDTO.DpphoneNumber}
                placeholder="Phone Number"
                onChange={handleChange}
                data-form-section="userDTO"
                pattern={formData.userDTO.phoneNumber}
                isInvalid={validated && formData.userDTO.DpphoneNumber !== formData.userDTO.phoneNumber}
              />
              <Form.Control.Feedback type="invalid">
                {errors.phoneNumber}
              </Form.Control.Feedback>
            </FloatingLabel>
            <br /> */}


            {/* <InputGroup>
              <Form.Select
                type="text"
                name="faxCountryCode"
                value={formData.userDTO.faxCountryCode}
                onChange={handleChange}
                data-form-section="userDTO"
              >
                <option value="">Select Country Code</option>
                {[...countryMap.values()].map((country) => (
                  <option key={country.code} value={country.code}>
                    {country.flag} {country.dial_code} ({country.name})
                  </option>
                ))}
              </Form.Select>
              <FloatingLabel label="Fax Number" controlId="faxNumber">
                <Form.Control
                  type="text"
                  name="faxNumber"
                  value={formData.userDTO.faxNumber}
                  placeholder="Fax Number"
                  onChange={handleChange}
                  pattern="^\d{10}$"
                  data-form-section="userDTO"
                />
                <Form.Control.Feedback type="invalid">
                  Please provide a valid fax number.
                </Form.Control.Feedback>
              </FloatingLabel>
            </InputGroup>
            <br /> */}
          </div>

          <div className='location-info'>
            <h3>Location Information</h3>
            <FloatingLabel label="Office No" controlId="addressLine1">
              <Form.Control
                required
                type="text"
                name="addressLine1"
                value={formData.locationRequestDTO.addressLine1}
                placeholder="Office No"
                onChange={handleChange}
                data-form-section="locationRequestDTO"
                isInvalid={validated && !formData.locationRequestDTO.addressLine1}
              />
              <Form.Control.Feedback type="invalid">
                Please provide a valid Office number or Name.
              </Form.Control.Feedback>
            </FloatingLabel>
            <br />

            <FloatingLabel label="Street Name" controlId="addressLine2">
              <Form.Control
                required
                type="text"
                name="addressLine2"
                value={formData.locationRequestDTO.addressLine2}
                placeholder="Street Name"
                onChange={handleChange}
                data-form-section="locationRequestDTO"
                isInvalid={validated && !formData.locationRequestDTO.addressLine2}
              />
              <Form.Control.Feedback type="invalid">
                Please provide a valid street name.
              </Form.Control.Feedback>
            </FloatingLabel>
            <br />

            <FloatingLabel label="Landmark" controlId="addressLine3">
              <Form.Control
                type="text"
                name="addressLine3"
                value={formData.locationRequestDTO.addressLine3}
                placeholder="Landmark"
                onChange={handleChange}
                data-form-section="locationRequestDTO"
              />
              <Form.Control.Feedback type="invalid">
                Please provide a valid Landmark.
              </Form.Control.Feedback>
            </FloatingLabel>
            <br />

            <Form.Group>
              <Typeahead
                id="country"
                labelKey="name"
                options={countries}
                placeholder="Select Country"
                onChange={handleCountryChange}
                selected={countries.filter(c => c.name === formData.locationRequestDTO.country)}
                isInvalid={validated && !formData.locationRequestDTO.country}
              />
              <Form.Control.Feedback type="invalid">Please select a valid country.</Form.Control.Feedback>
            </Form.Group>
            <br />

            <Form.Group>
              <Typeahead
                id="state"
                labelKey="name"
                options={states}
                placeholder="Select State"
                onChange={handleStateChange}
                selected={states.filter(s => s.name === formData.locationRequestDTO.state)}
                isInvalid={validated && !formData.locationRequestDTO.state}
              />
              <Form.Control.Feedback type="invalid">Please select a valid state.</Form.Control.Feedback>
            </Form.Group>
            <br />

            <Form.Group>
              <Typeahead
                id="city"
                labelKey="name"
                options={cities}
                placeholder="Select City"
                onChange={handleCityChange}
                selected={cities.filter(c => c.name === formData.locationRequestDTO.city)}
                isInvalid={validated && !formData.locationRequestDTO.city}
              />
              <Form.Control.Feedback type="invalid">Please select a valid city.</Form.Control.Feedback>
            </Form.Group>
            <br />

            <FloatingLabel label="Zip Code" controlId="zipCode">
              <Form.Control
                required
                type="text"
                name="zipCode"
                value={formData.locationRequestDTO.zipCode}
                placeholder="Zip Code"
                onChange={handleChange}
                data-form-section="locationRequestDTO"
                isInvalid={validated && !formData.locationRequestDTO.zipCode}
              />
              <Form.Control.Feedback type="invalid">
                Please provide a valid zip code.
              </Form.Control.Feedback>
            </FloatingLabel>
            <br />
          </div>
        </div>
        <ErrorAlert
          showError={showError}
          onHide={handleHideError}
          message={message} />
        <SuccessAlert
          showSucess={showSuccess}
          onHide={handleSucessClose}
          message={message}
        />
        {/* Show loading spinner while verifying email */}
        {isValidating && (
          <div className="d-flex justify-content-center">
            <Spinner animation="border" role="status">
              <span className="visually-hidden">Validating...</span>
            </Spinner>
          </div>
        )}

        <Button variant="primary" type="submit"> SignUp </Button>
      </Form >
    </div >
  );
}

export default SignUp;