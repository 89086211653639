import dashboard from "./dashboardData.json";
import { useState, useEffect } from "react";
import { Row, Col, Button, Container, Table } from 'react-bootstrap';
import ConsigneeDashboard from "./ConsigneeDashboard";
import React from 'react';


function Consignee(details) {


    const ConsigneeHeader = () => {

        return (
            <div style={{ marginTop: "1%" }}>
                <h2>Consignee Dashboard</h2>

                <div style={{ width: "100%", backgroundColor: "rgba(178, 224, 171,.5)", padding: "1%", borderRadius: "10px", marginTop: "1%" }}>
                    <Row>
                        <Col style={{ display: "flex", flexDirection: "row" }}>
                            <p><b>Consignee: </b>{details.userInfo.firstName + " " + details.userInfo.lastName}</p>
                        </Col>
                        <Col lg="8" style={{ display: "flex", justifyContent: "flex-start" }}><p><b>Referral Code: </b>{details.userInfo.entityInfo.referralId}</p></Col>
                    </Row>
                </div>

                <br />
            </div>
        );
    }


    return (
        <Container>
            <ConsigneeHeader />
            <ConsigneeDashboard username={details.userInfo.username} />
        </Container>
    );
}

export default Consignee;