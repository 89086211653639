// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* src/SignUp.css */
.signup-form-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 0 auto;
  gap: 20px;
}

.signup-form {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 0 auto;
  gap: 20px;
  background-color: #b2e0ab66;
}

.company-info,
.location-info {
  flex: 1;
  padding: 10px;
  border-radius: 5px;
}

.signup-form-container h2,
h3 {
  text-align: center;
  margin-bottom: 20px;
}

.form-group {
  margin-bottom: 15px;
}

.form-group label {
  margin-bottom: 5px;
}

.form-group input {
  width: 100%;
  padding: 8px;
  box-sizing: border-box;
}

.error {
  color: red;
  font-size: 12px;
  margin-top: 5px;
}

button {
  padding: 10px;
  background-color: #28a745;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

button:hover {
  background-color: #218838;
}`, "",{"version":3,"sources":["webpack://./src/pages/Shipper/CreateNewEntry.css"],"names":[],"mappings":"AAAA,mBAAmB;AACnB;EACE,aAAa;EACb,mBAAmB;EACnB,8BAA8B;EAC9B,cAAc;EACd,SAAS;AACX;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,8BAA8B;EAC9B,cAAc;EACd,SAAS;EACT,2BAA2B;AAC7B;;AAEA;;EAEE,OAAO;EACP,aAAa;EACb,kBAAkB;AACpB;;AAEA;;EAEE,kBAAkB;EAClB,mBAAmB;AACrB;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,sBAAsB;AACxB;;AAEA;EACE,UAAU;EACV,eAAe;EACf,eAAe;AACjB;;AAEA;EACE,aAAa;EACb,yBAAyB;EACzB,WAAW;EACX,YAAY;EACZ,kBAAkB;EAClB,eAAe;AACjB;;AAEA;EACE,yBAAyB;AAC3B","sourcesContent":["/* src/SignUp.css */\n.signup-form-container {\n  display: flex;\n  flex-direction: row;\n  justify-content: space-between;\n  margin: 0 auto;\n  gap: 20px;\n}\n\n.signup-form {\n  display: flex;\n  flex-direction: column;\n  justify-content: space-between;\n  margin: 0 auto;\n  gap: 20px;\n  background-color: #b2e0ab66;\n}\n\n.company-info,\n.location-info {\n  flex: 1;\n  padding: 10px;\n  border-radius: 5px;\n}\n\n.signup-form-container h2,\nh3 {\n  text-align: center;\n  margin-bottom: 20px;\n}\n\n.form-group {\n  margin-bottom: 15px;\n}\n\n.form-group label {\n  margin-bottom: 5px;\n}\n\n.form-group input {\n  width: 100%;\n  padding: 8px;\n  box-sizing: border-box;\n}\n\n.error {\n  color: red;\n  font-size: 12px;\n  margin-top: 5px;\n}\n\nbutton {\n  padding: 10px;\n  background-color: #28a745;\n  color: #fff;\n  border: none;\n  border-radius: 5px;\n  cursor: pointer;\n}\n\nbutton:hover {\n  background-color: #218838;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
