import React, { useState, useEffect, useContext } from "react";
import { Container, Tabs, Tab } from "react-bootstrap";
import axiosInstance from "../../services/AxiosConfig";
import { UserContext } from "../../context/UserContext";
import CompanyInfo from "./CompanyInfo";
import UserList from "./UserList";
import LocationList from "./LocationList";

function Profile() {
    const [supplierData, setSupplierData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState("");
    const [editMode, setEditMode] = useState({
        company: false,
        users: [],
        locations: []
    });
    const [changedData, setChangedData] = useState({
        entityInfo: null,
        contacts: [],
        locations: []
    });
    const { userInfo } = useContext(UserContext);

    useEffect(() => {
        if (userInfo?.emailId) {
            const fetchSupplierData = async () => {
                try {
                    const response = await axiosInstance.get(`/api/entity`);
                    setSupplierData(response.data);
                    setEditMode({
                        company: false,
                        users: new Array(response.data.contacts.length).fill(false),
                        locations: new Array(response.data.locations.length).fill(false)
                    });
                    setChangedData({
                        entityInfo: { ...response.data.entityInfo },
                        contacts: response.data.contacts.map(contact => ({ ...contact })),
                        locations: response.data.locations.map(location => ({ ...location }))
                    });
                } catch (err) {
                    setError("Error fetching supplier data.");
                    console.error("Axios error:", err);
                } finally {
                    setLoading(false);
                }
            };

            fetchSupplierData();
        } else {
            setLoading(false);
            setError("No user email found.");
        }
    }, [userInfo]);

    const handleEditToggle = (section, index) => {
        setEditMode(prev => {
            const newState = { ...prev };
            if (section === "users") {
                newState.users[index] = !newState.users[index];
            } else if (section === "locations") {
                newState.locations[index] = !newState.locations[index];
            } else {
                newState[section] = !newState[section];
            }
            return newState;
        });
    };

    const handleInputChange = (section, index, e) => {
        const { name, value } = e.target;
        setChangedData(prev => {
            const newData = { ...prev };
            if (section === "entityInfo") {
                newData.entityInfo = { ...prev.entityInfo, [name]: value };
            } else if (section === "contacts") {
                newData.contacts[index] = { ...prev.contacts[index], [name]: value };
            } else if (section === "locations") {
                newData.locations[index] = { ...prev.locations[index], [name]: value };
            }
            return newData;
        });
    };

    const handleSaveChanges = async (section, index) => {
        try {
            if (section === "entityInfo") {
                // await axiosInstance.put(`/api/entity`, { entityInfo: changedData.entityInfo });
            } else if (section === "contacts") {
                const contact = changedData.contacts[index];
                if (contact.isNew) {
                    await axiosInstance.post(`/api/users/create`, contact);
                } else {
                    await axiosInstance.put(`/api/users/update`, contact);
                }
            } else if (section === "locations") {
                const location = changedData.locations[index];
                if (location.isNew) {
                    await axiosInstance.post(`/api/location/create`, location);
                } else {
                    await axiosInstance.put(`/api/location/update`, location);
                }
            }
            setSupplierData({
                ...supplierData,
                contacts: [...changedData.contacts],
                locations: [...changedData.locations],
            });
            handleEditToggle(section, index);
        } catch (err) {
            setError("Error updating data.");
            console.error("Axios error:", err);
        }
    };

    const handleAddNewUser = () => {
        setChangedData(prev => ({
            ...prev,
            contacts: [...prev.contacts, { userId: Date.now(), firstName: "", lastName: "", emailId: "", phoneNumber: "", faxNumber: "", isNew: true }]
        }));
        setEditMode(prev => ({
            ...prev,
            users: [...prev.users, true] // Automatically enter edit mode for the new user
        }));
    };

    const handleAddNewLocation = () => {
        setChangedData(prev => ({
            ...prev,
            locations: [...prev.locations, { id: Date.now(), addressLine1: "", addressLine2: "", city: "", state: "", zipCode: "", isNew: true }]
        }));
        setEditMode(prev => ({
            ...prev,
            locations: [...prev.locations, true] // Automatically enter edit mode for the new location
        }));
    };

    if (loading) {
        return <div>Loading...</div>;
    }

    if (error) {
        return <div>{error}</div>;
    }

    return (
        <Container>
            <Tabs defaultActiveKey="user" id="profile-tabs" className="mb-3">
                <Tab eventKey="company" title="Company">
                    <CompanyInfo
                        data={changedData.entityInfo}
                        editMode={editMode.company}
                        onEditToggle={() => handleEditToggle("company")}
                        onInputChange={(e) => handleInputChange("entityInfo", null, e)}
                        onSave={() => handleSaveChanges("entityInfo", null)}
                    />
                </Tab>
                <Tab eventKey="user" title="User">
                    <UserList
                        contacts={changedData.contacts}
                        editMode={editMode.users}
                        onEditToggle={handleEditToggle}
                        onInputChange={handleInputChange}
                        onSave={handleSaveChanges}
                        onAddNew={handleAddNewUser}
                    />
                </Tab>
                <Tab eventKey="location" title="Location">
                    <LocationList
                        locations={changedData.locations}
                        editMode={editMode.locations}
                        onEditToggle={handleEditToggle}
                        onInputChange={handleInputChange}
                        onSave={handleSaveChanges}
                        onAddNew={handleAddNewLocation}
                    />
                </Tab>
            </Tabs>
        </Container>
    );
}

export default Profile;