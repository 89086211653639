import React from 'react';
import { Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const ViewRenderer = (props) => {
//   const handleClick = () => {
//     alert(`Button clicked for row ${props.node.rowIndex}, AnnexNo: ${props.data.AnnexNo}`);
//   };

// var annexNo = props.data.AnnexNo;

var url = "/annexform?id=".concat(props.data.AnnexId);

  return (
    <Button size='sm'
    // onClick={handleClick}
    as={Link}
    to={url}
    >
     View
    </Button>
  );
};

export default ViewRenderer;