// import { useState, useEffect } from "react";
import { Row, Col, Button, Container } from 'react-bootstrap';
import SupplierDashboard from "./SupplierDashboard";
import { UserContext } from "../../context/UserContext";
import { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import React from 'react';

function Supplier(details) {

    // console.log('details: ',details.username);

    const [supplierName, setSupplierName] = useState(null);

    // useEffect(() => {
    //         setSupplierName(details.username);
    // }, []);

    const { userInfo, setUserInfo } = useContext(UserContext);

    const [showUploadModal, setShowUploadModal] = useState(false);

    const handleOpenModal = () => {
        setShowUploadModal(true);
    };

    const handleCloseModal = () => {
        setShowUploadModal(false);
    };


    useEffect(() => {
        if (userInfo?.emailId) {
            setSupplierName(userInfo?.firstName + " " + userInfo?.lastName);
        }
    }, [userInfo]);

    const SupplierHeader = () => {

        return (
            <div style={{ marginTop: "1%" }}>
                <h2>Supplier Dashboard</h2>

                <div style={{ width: "100%", backgroundColor: "rgba(178, 224, 171,.5)", padding: "1%", borderRadius: "10px" }}>
                    <Row>
                        <Col lg="8" style={{ display: "flex", justifyContent: "flex-start" }}><p><b>Supplier: </b>{supplierName}</p></Col>
                        <Col lg="8" style={{ display: "flex", justifyContent: "flex-start" }}><p><b>Referral Code: </b>{details.userInfo.entityInfo.referralId}</p></Col>
                        <Col lg="4" >
                            <Row>
                                <Col><Button variant="primary" size="sm" as={Link} to="/shipper-creation" state={{ entityType: "Shipper" }}>Create Shipper</Button></Col>
                                <Col><Button variant="primary" size="sm" as={Link} to="/link-companies" state={{ entityType: "Shipper" }}>Link Shipper</Button></Col>
                                {/* <Col><Button variant="primary" size="sm" href="/consignee-creation">Consignee</Button></Col> */}
                                {/* <Col><Button variant="primary" size="sm" href="/annexform">Annexure7</Button></Col> */}
                            </Row>
                        </Col>
                    </Row>
                </div>

                <br />
            </div>
        );
    }


    return (
        <Container>
            <SupplierHeader />
            <SupplierDashboard username={supplierName} />
        </Container>
    );
}

export default Supplier;