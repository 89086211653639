import React from 'react';
import { Form, FloatingLabel, Button } from 'react-bootstrap';

function CompanyInfo({ data, editMode, onEditToggle, onSave }) {
  return (
    <div className='company-info'>
      <h3>Company Information</h3>
      <Form.Group size="lg" controlId="entityType">
        <Form.Select
          disabled={!editMode}
          value={data.entityType}
          onChange={(e) => onEditToggle('entityInfo', e.target.value)}
        >
          <option value="SUPPLIER">Supplier</option>
          <option value="SHIPPER">Shipper</option>
          <option value="CONSIGNEE">Consignee</option>
        </Form.Select>
      </Form.Group>
      <FloatingLabel controlId="entityName" label="Company Name">
        <Form.Control
          type="text"
          value={data.entityName}
          onChange={(e) => onEditToggle('entityInfo', e.target.value)}
          disabled={!editMode}
        />
      </FloatingLabel>
      <FloatingLabel controlId="creationDate" label="Creation Date">
        <Form.Control
          type="text"
          value={new Date(data.creationDate).toLocaleDateString()}
          disabled
        />
      </FloatingLabel>
      <FloatingLabel controlId="lastUpdateDate" label="Last Update Date">
        <Form.Control
          type="text"
          value={new Date(data.lastUpdateDate).toLocaleDateString()}
          disabled
        />
      </FloatingLabel>
      <Button variant="secondary" onClick={onEditToggle}>
        {editMode ? 'Cancel' : 'Edit'}
      </Button>
      {editMode && <Button variant="primary" onClick={onSave}>Save</Button>}
    </div>
  );
}

export default CompanyInfo;