import React, { useContext } from 'react';
import { Form, Button, Row, Col, FloatingLabel } from 'react-bootstrap';
import TruckCarrier from './TruckCarrier';
import ShipCarrier from './ShipCarrier';
import { UserContext } from '../../context/UserContext';
import AnnexApproval from './AnnexApproval';


const CarrierTab = ({ annexureParent, shippingLineInfo, entityData, onUpdate }) => {
    const { userInfo } = useContext(UserContext);
    const handleUpdateCarrier = (carrierData) => {
        onUpdate({ ...annexureParent, ...carrierData });
    };

    const updateTruckCarrier = (truckCarrierData) => {
        const updateState = {
            ...annexureParent,
            truckCarrier: {
                ...annexureParent.truckCarrier,
                ...truckCarrierData,
            },
        }
        onUpdate(updateState);
    };

    const updateShipCarrier = (shipCarrierData) => {
        console.log("updateShipCarrier: ", shipCarrierData);
        const updateState = {
            ...annexureParent,
            shipCarrier: {
                ...annexureParent.shipCarrier,
                ...shipCarrierData,
            },
        }
        onUpdate(updateState);
    };

    const handleChange = (e) => {
        console.log("handleChange: in carrier tab ", e.target.name, " - ", e.target.value);
        const { name, value } = e.target;
        onUpdate({ [name]: value });
    };

    return (
        <>
            <div style={{ backgroundColor: "rgba(178, 224, 171, .4)", padding: "2%", borderRadius: "10px", marginBottom: "20px" }}>
                <h5>Carrier Details</h5>
                <Row className="mb-3">
                    <Col xs={12} md={4}>
                        <FloatingLabel controlId="bookingNumber" label="Booking Number">
                            <Form.Control
                                placeholder="Enter Booking Number"
                                name="bookingNumber"
                                onChange={handleChange}
                                value={annexureParent.bookingNumber || ""}
                            />
                        </FloatingLabel>
                    </Col>
                    <Col xs={12} md={4}>
                        <FloatingLabel controlId="BLNumber" label="BL Number">
                            <Form.Control
                                placeholder="Enter BL Number"
                                name="blnumber"
                                onChange={handleChange}
                                value={annexureParent.blnumber || ""}
                            />
                        </FloatingLabel>
                    </Col>
                </Row>
            </div>
            {/* <ShipCarrier carrier={annexureParent.shipCarrier} onUpdate={handleUpdateCarrier} />
      <TruckCarrier carrier={annexureParent.truckCarrier} onUpdate={handleUpdateCarrier} />
      <AnnexureList annexureList={annexureParent.annexureList} onUpdate={handleUpdateCarrier} /> */}
            {userInfo?.roleName != "ROLE_CONSIGNEE" && (
                <TruckCarrier
                    carrier={annexureParent.truckCarrier}
                    onUpdate={updateTruckCarrier}
                />
            )}
            <ShipCarrier
                annexureParent={annexureParent}
                carrier={annexureParent.shipCarrier}
                shippingLineInfo={shippingLineInfo}
                entityData={entityData}
                onUpdate={updateShipCarrier}
                onParentUpdate={onUpdate} />

            {(userInfo?.roleName === "ROLE_CONSIGNEE" || userInfo?.roleName === "ROLE_SUPPLIER") && (<AnnexApproval
                annexureParent={annexureParent}
                role={userInfo.roleName}
                onUpdateAnnexData={onUpdate}
            />)}
        </>
    );
};

export default CarrierTab;