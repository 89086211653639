import { Row, Col, Container } from "react-bootstrap";
import SupplierDetails from "./SupplierDetails";
function Suppliers() {

    return (
        <div>
            <Container>
                <SupplierDetails />
            </Container>
        </div>
    );
}

export default Suppliers;