import { Route, Routes } from "react-router-dom";
import LogOut from './pages/Home/Logout';
import Dashboard from './pages/Dashboard/Dashboard';
import Profile from './pages/Profile/Profile';
import Reports from './pages/Reports/Reports';
import { Provider } from 'react-redux';
import annexStore from './store/store';
import ConsigneeCreation from './pages/Consignee/ConsigneeCreation';
import ShipperCreation from './pages/Shipper/ShipperCreation';
import Tracking from './pages/Tracking/Tracking';
import AnnexForm from './pages/AnnexV2/AnnexFormNew';
import SupplierCreation from './pages/Supplier/SupplierCreation';
import Test from './pages/Test/Test';
import HomePage from "./pages/Home/HomePage";
import PasswordResetRequest from "./services/PasswordResetRequest";
import PasswordReset from "./services/PasswordReset";
import ResetTempPassword from "./services/ResetTempPassword";
import { useEffect, useState } from "react";
import CreateNewEntry from "./pages/Shipper/CreateNewEntry";
import LinkExistingEntry from "./pages/Shipper/LinkExistingEntry";
import React from 'react';


export default function AppRoutes() {
    const [isLoggedIn, setIsLoggedIn] = useState(false);

    useEffect(() => {
        // Check if the user is authenticated by checking the presence of a JWT token in localStorage
        const token = localStorage.getItem('accessToken');
        setIsLoggedIn(!!token);
    }, []);

    return (
        <Routes>
            {/* <Route path="/" element={<Dashboard />}/> */}
            {/* <Route index element={<HomePage />} /> */}
            <Route path="/reset-request" element={<PasswordResetRequest />} />
            <Route path="/change-password" element={<ResetTempPassword />} />
            <Route path="/reset" element={<PasswordReset />} />
            <Route path="Logout" element={<LogOut />} />
            <Route path="/logIn" element={<HomePage />} />

            {/* Private routes */}
            <Route
                path="/"
                element={isLoggedIn ? <Dashboard /> : <HomePage />}
            />
            <Route
                path="/tracking"
                element={isLoggedIn ? <Tracking /> : <HomePage />}
            />
            <Route
                path="/reports"
                element={isLoggedIn ? <Reports /> : <HomePage />}
            />
            <Route
                path="/profile"
                element={isLoggedIn ? <Profile /> : <HomePage />}
            />
            <Route
                path="/home"
                element={isLoggedIn ? <Dashboard /> : <HomePage />}
            />
            <Route
                path="/annexform"
                element={isLoggedIn ? <AnnexForm /> : <HomePage />}
            />
            <Route
                path="/shipper-creation"
                element={isLoggedIn ? <CreateNewEntry /> : <HomePage />}
            />
            <Route
                path="/consignee-creation"
                element={isLoggedIn ? <CreateNewEntry /> : <HomePage />}
            />
            <Route
                path="/supplier-creation"
                element={isLoggedIn ? <CreateNewEntry /> : <HomePage />}
            />
            <Route
                path="/link-companies"
                element={isLoggedIn ? <LinkExistingEntry /> : <HomePage />}
            />
            <Route
                path="/test"
                element={isLoggedIn ? <Dashboard /> : <HomePage />}
            />

            {/* <Route path="*" element={<HomePage />} /> */}
        </Routes>
    );
}