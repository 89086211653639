import React, { useState } from 'react';
import { Button, Col, FloatingLabel, Form, Row } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import './WasteTab.css';

const WasteTab = ({ annexureParent, onUpdate, validated, onValidation, moveToNextTab }) => {
  const handleChange = (e) => {
    const { name, value } = e.target;
    onUpdate({ [name]: value });
  };

  const handleSubmit = (event) => {
    const form = event.currentTarget;
    event.preventDefault();
    event.stopPropagation();

    const formErrors = form.querySelectorAll(':invalid').length;

    if (formErrors === 0) {
      onValidation(0); // No errors
      moveToNextTab(); // Move to the next tab
    } else {
      onValidation(formErrors); // Update error count
    }
  };

  const [selectedDateOfShipment, setSelectedDateOfShipment] = useState(null);

  const handleDateOfShipmentChange = (date) => {
    setSelectedDateOfShipment(date);
  };


  return (
    <Form noValidate validated={validated} onSubmit={handleSubmit}>
      {/* <Form.Group>
        <Form.Label>Waste Description</Form.Label>
        <Form.Control
          type="text"
          name="wasteDescription"
          value={annexureParent.wasteDescription}
          onChange={handleChange}
        />
      </Form.Group>
      <Form.Group>
        <Form.Label>Waste Identification Basel</Form.Label>
        <Form.Control
          type="text"
          name="wasteIdentificationBasel"
          value={annexureParent.wasteIdentificationBasel}
          onChange={handleChange}
        />
      </Form.Group> */}

      <div style={{ width: "100%", backgroundColor: "rgba(178, 224, 171,.4)", padding: "2%", borderRadius: "10px" }}>
        <Row className="mb-3">
          <Col xs={12} md={4} lg={4}>
            <FloatingLabel controlId="wasteGeneratorName" label="Name">
              <Form.Control
                placeholder="Enter Name"
                aria-label="Name"
                name="wasteGeneratorName"
                value={annexureParent.wasteGeneratorName || ''}
                onChange={handleChange}
                disabled
              />
            </FloatingLabel>
          </Col>
          <Col xs={12} md={4} lg={4}>
            <FloatingLabel controlId="wasteGeneratorAddress" label="Address">
              <Form.Control
                placeholder="Enter Address"
                aria-label="Address"
                name="wasteGeneratorAddress"
                value={annexureParent.wasteGeneratorAddress || ''}
                onChange={handleChange}
                disabled
              />
            </FloatingLabel>
          </Col>
          <Col xs={12} md={4} lg={4}>
            <FloatingLabel controlId="wasteGeneratorContactPerson" label="Contact Person">
              <Form.Control
                placeholder="Enter Contact Name"
                aria-label="Contact Name"
                name="wasteGeneratorContactPerson"
                value={annexureParent.wasteGeneratorContactPerson || ''}
                onChange={handleChange}
                disabled
              />
            </FloatingLabel>
          </Col>
        </Row>

        <Row className="mb-3">
          <Col xs={12} md={4} lg={4}>
            <FloatingLabel controlId="wasteGeneratorTelephone" label="Telephone">
              <Form.Control
                placeholder="Enter Telephone"
                aria-label="Telephone"
                name="wasteGeneratorTelephone"
                value={annexureParent.wasteGeneratorTelephone || ''}
                onChange={handleChange}
                disabled
              />
            </FloatingLabel>
          </Col>
          <Col xs={12} md={4} lg={4}>
            <FloatingLabel controlId="wasteGeneratorFax" label="Fax">
              <Form.Control
                placeholder="Enter Fax"
                aria-label="Fax"
                name="wasteGeneratorFax"
                value={annexureParent.wasteGeneratorFax || ''}
                onChange={handleChange}
                disabled
              />
            </FloatingLabel>
          </Col>
          <Col xs={12} md={4} lg={4}>
            <FloatingLabel controlId="wasteGeneratorEmail" label="Email">
              <Form.Control
                placeholder="Enter Email Address"
                aria-label="Email"
                name="wasteGeneratorEmail"
                value={annexureParent.wasteGeneratorEmail || ''}
                onChange={handleChange}
                disabled
              />
            </FloatingLabel>
          </Col>
        </Row>

        <Row className="mb-3">
          <Col xs={12}>
            <FloatingLabel controlId="wasteDescription" label="Description of Waste">
              <Form.Control
                placeholder="Enter description of waste"
                aria-label="Description of Waste"
                name="wasteDescription"
                value={annexureParent.wasteDescription || ''}
                onChange={handleChange}
              />
            </FloatingLabel>
          </Col>
        </Row>

        <Row className="mb-3">
          <Col xs={12} md={4} lg={4}>
            <FloatingLabel controlId="wasteIdentificationBasel" label="Basel Annex IX">
              <Form.Control
                placeholder="Enter Basel"
                aria-label="Basel"
                name="wasteIdentificationBasel"
                value={annexureParent.wasteIdentificationBasel || ''}
                onChange={handleChange}
              />
            </FloatingLabel>
          </Col>
          <Col xs={12} md={4} lg={4}>
            <FloatingLabel controlId="wasteIdentificationOECD" label="OECD">
              <Form.Control
                placeholder="Enter OECD"
                aria-label="OECD"
                name="wasteIdentificationOECD"
                value={annexureParent.wasteIdentificationOECD || ''}
                onChange={handleChange}
              />
            </FloatingLabel>
          </Col>
          <Col xs={12} md={4} lg={4}>
            <FloatingLabel controlId="wasteIdentificationNationalCode" label="National Code">
              <Form.Control
                placeholder="Enter National Code"
                aria-label="National Code"
                name="wasteIdentificationNationalCode"
                value={annexureParent.wasteIdentificationNationalCode || ''}
                onChange={handleChange}
              />
            </FloatingLabel>
          </Col>
        </Row>

        <Row className="mb-3">
          <Col xs={12} md={4} lg={4}>
            <FloatingLabel controlId="wasteIdentification3a" label="Annex IIIA (4)">
              <Form.Control
                placeholder="Enter 3A"
                aria-label="3A"
                name="wasteIdentification3a"
                value={annexureParent.wasteIdentification3a || ''}
                onChange={handleChange}
              />
            </FloatingLabel>
          </Col>
          <Col xs={12} md={4} lg={4}>
            <FloatingLabel controlId="wasteIdentification3b" label="Annex IIIB (5)">
              <Form.Control
                placeholder="Enter 3B"
                aria-label="3B"
                name="wasteIdentification3b"
                value={annexureParent.wasteIdentification3b || ''}
                onChange={handleChange}
              />
            </FloatingLabel>
          </Col>
          <Col xs={12} md={4} lg={4}>
            <FloatingLabel controlId="wasteIdentificationEc" label="EC list of wastes">
              <Form.Control
                placeholder="Enter 3C"
                aria-label="3C"
                name="wasteIdentificationEc"
                value={annexureParent.wasteIdentificationEc || ''}
                onChange={handleChange}
              />
            </FloatingLabel>
          </Col>
        </Row>

        <Row className="mb-3">
          <Col xs={12} md={4} lg={4}>
            <FloatingLabel controlId="transit1" label="Transit 1">
              <Form.Control
                placeholder="Enter Transit 1"
                aria-label="Transit 1"
                name="transit1"
                value={annexureParent.transit1 || ''}
                onChange={handleChange}
              />
            </FloatingLabel>
          </Col>
          <Col xs={12} md={4} lg={4}>
            <FloatingLabel controlId="transit2" label="Transit 2">
              <Form.Control
                placeholder="Enter Transit 2"
                aria-label="Transit 2"
                name="transit2"
                value={annexureParent.transit2 || ''}
                onChange={handleChange}
              />
            </FloatingLabel>
          </Col>
          <Col xs={12} md={4} lg={4}>
            <FloatingLabel controlId="transit3" label="Transit 3">
              <Form.Control
                placeholder="Enter Transit 3"
                aria-label="Transit 3"
                name="transit3"
                value={annexureParent.transit3 || ''}
                onChange={handleChange}
              />
            </FloatingLabel>
          </Col>
        </Row>

        <Row className="mb-3">
          <Col xs={12} md={4} lg={4}>
            <FloatingLabel controlId="exportCountry" label="Export Country">
              <Form.Control
                placeholder="Enter Export Country"
                aria-label="Export Country"
                name="exportCountry"
                value={annexureParent.exportCountry || ''}
                onChange={handleChange}
              />
            </FloatingLabel>
          </Col>
          <Col xs={12} md={4} lg={4}>
            <FloatingLabel controlId="importCountry" label="Import Country">
              <Form.Control
                placeholder="Enter Import Country"
                aria-label="Import Country"
                name="importCountry"
                value={annexureParent.importCountry || ''}
                onChange={handleChange}
              />
            </FloatingLabel>
          </Col>
        </Row>
        <Row>
          <Col>
            <div style={{ display: "flex", flexDirection: "row-reverse" }}>
              <Button variant="primary" type="submit">Save and Next</Button>
            </div>
          </Col>
        </Row>
      </div>
    </Form>
  );
};

export default WasteTab;