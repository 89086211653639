import React, { useContext, useEffect, useState } from 'react';
import { Button, FloatingLabel, Form } from 'react-bootstrap';
import './MainTab.css'
import { UserContext, UserProvider } from '../../context/UserContext';

const MainTab = ({ annexureParent, onUpdate, entityData, validated, onValidation, moveToNextTab }) => {

  const { userInfo } = useContext(UserContext);
  const [filteredSupplierContacts, setFilteredSupplierContacts] = useState([]);
  const [filteredSupplierLocations, setFilteredSupplierLocations] = useState([]);
  const [filteredConsigneeContacts, setFilteredConsigneeContacts] = useState([]);
  const [filteredConsigneeLocations, setFilteredConsigneeLocations] = useState([]);
  const [selectedSupplierEntity, setSelectedSupplierEntity] = useState([]);

  useEffect(() => {
    // If supplierId is present, load default supplier data

    if (annexureParent?.supplierId) {
      const selectedSupplier = entityData.supplier.find((supplier) => {
        return supplier.entityInfo.entityId === parseInt(annexureParent.supplierId);
      });

      setSelectedSupplierEntity(selectedSupplier);
      setFilteredSupplierContacts(selectedSupplier?.contacts || []);
      setFilteredSupplierLocations(selectedSupplier?.locations || []);

      const selectedContact = selectedSupplier?.contacts?.find((contact) => {
        return contact.userId === parseInt(annexureParent.supplierContactId)
      })
      const selectedLocation = selectedSupplier?.locations?.find((location) => {
        return location.id === parseInt(annexureParent.supplierLocationId)
      })
      if (selectedSupplier) {
        onUpdate({
          supplierId: selectedSupplier?.entityInfo?.entityId,
          supplierName: selectedSupplier?.entityInfo?.entityName,
          supplierContactId: selectedContact?.userId,
          supplierContactName: selectedContact?.firstName + " " + selectedContact?.lastName,
          supplierTelephone: selectedContact?.phoneNumber,
          supplierEmail: selectedContact?.emailId,
          supplierFax: selectedContact?.faxNumber,
          supplierLocationId: selectedLocation?.id,
          supplierLocationName: selectedLocation?.addressLine1 + ", " + selectedLocation?.city + ", " + selectedLocation?.state
        });
      }
    }

    // If consigneeId is present, load default consignee data
    if (annexureParent?.consigneeId) {
      const selectedConsignee = entityData.consignee.find((consignee) => {
        return consignee.entityInfo.entityId === parseInt(annexureParent.consigneeId)
      });

      setFilteredConsigneeContacts(selectedConsignee?.contacts || []);
      setFilteredConsigneeLocations(selectedConsignee?.locations || []);

      const selectedContact = selectedConsignee?.contacts?.find((contact) => {
        return contact.userId === parseInt(annexureParent.consigneeContactId)
      })
      const selectedLocation = selectedConsignee?.locations?.find((location) => {
        return location.id === parseInt(annexureParent.consigneeLocationId)
      })

      if (selectedConsignee && selectedContact && selectedLocation) {
        onUpdate({
          consigneeId: selectedConsignee?.entityInfo?.entityId,
          consigneeName: selectedConsignee?.entityInfo?.entityName,
          consigneeContactId: selectedContact?.userId,
          consigneeContactName: selectedContact?.firstName + " " + selectedContact?.lastName,
          consigneeTelephone: selectedContact?.phoneNumber,
          consigneeEmail: selectedContact?.emailId,
          consigneeFax: selectedContact?.faxNumber,
          consigneeLocationId: selectedLocation?.id,
          consigneeLocationName: selectedLocation?.addressLine1 + ", " + selectedLocation?.city + ", " + selectedLocation?.state
        });

      }
    }

    if (annexureParent?.shipperId) {
      const selectedContact = entityData?.currentEntity?.contacts?.find((contact) => {
        return contact.userId === parseInt(annexureParent.shipperContactId)
      })
      const selectedLocation = entityData?.currentEntity?.locations?.find((location) => {
        return location.id === parseInt(annexureParent.shipperLocationId)
      })

      if (selectedContact) {
        onUpdate({
          shipperId: entityData?.currentEntity?.entityInfo?.entityId,
          shipperName: entityData?.currentEntity?.entityInfo?.entityName,
          shipperContactId: selectedContact?.userId,
          shipperContactName: selectedContact?.firstName + " " + selectedContact?.lastName,
          shipperTelephone: selectedContact?.phoneNumber,
          shipperEmail: selectedContact?.emailId,
          shipperFax: selectedContact?.faxNumber,
          shipperLocationId: selectedLocation?.id,
          shipperLocationName: selectedLocation?.addressLine1 + ", " + selectedLocation?.city + ", " + selectedLocation?.state
        });

      }
    }

  }, []);


  const handleChange = (e) => {
    const { name, value } = e.target;
    onUpdate({ [name]: value });
  };

  const handleSupplierChange = (e) => {
    const { name, value } = e.target;
    if (name === 'supplierName') {
      const selectedSupplier = entityData.supplier.find((supplier) => {
        return supplier.entityInfo.entityId === parseInt(value)
      });

      onUpdate({
        supplierId: value,
        supplierName: selectedSupplier?.entityInfo?.entityName,
        supplierContactId: "", // Reset supplier contact when supplier changes
        supplierLocationId: "", // Reset supplier location when supplier changes
      });

      // Dynamically update contacts and locations based on the selected supplier
      setSelectedSupplierEntity(selectedSupplier);
      setFilteredSupplierContacts(selectedSupplier?.contacts || []);
      setFilteredSupplierLocations(selectedSupplier?.locations || []);
    } else {
      if (name === 'supplierContactId') {
        const selectedContact = filteredSupplierContacts.find((contact) => {
          return contact.userId === parseInt(value)
        })
        onUpdate({
          supplierContactId: value,
          supplierContactName: selectedContact?.firstName + " " + selectedContact?.lastName,
          supplierTelephone: selectedContact?.phoneNumber,
          supplierEmail: selectedContact?.emailId,
          supplierFax: selectedContact?.faxNumber
        });
      } else if (name === 'supplierLocationId') {
        const selectedLocation = filteredSupplierLocations.find((location) => {
          return location.id === parseInt(value)
        })
        onUpdate({
          supplierLocationId: value,
          supplierLocationName: selectedLocation?.addressLine1 + ", " + selectedLocation?.city + ", " + selectedLocation?.state
        });
      } else {
        onUpdate({ [name]: value });
      }
    }
  }

  const handleConsigneeChange = (e) => {
    const { name, value } = e.target;
    if (name === 'consigneeName') {
      const selectedConsignee = entityData.consignee.find((consignee) => {
        return consignee.entityInfo.entityId === parseInt(value)
      });
      onUpdate({
        consigneeId: value,
        consigneeName: selectedConsignee?.entityInfo?.entityName,
        consigneeContactId: "", // Reset consignee contact when consignee changes
        consigneeLocationId: "", // Reset consignee location when consignee changes
      });
      // Dynamically update contacts and locations based on the selected consignee
      setFilteredConsigneeContacts(selectedConsignee?.contacts || []);
      setFilteredConsigneeLocations(selectedConsignee?.locations || []);
    } else {
      if (name === 'consigneeContactId') {
        const selectedContact = filteredConsigneeContacts.find((contact) => {
          return contact.userId === parseInt(value)
        })
        onUpdate({
          consigneeContactId: value,
          consigneeContactName: selectedContact?.firstName + " " + selectedContact?.lastName,
          consigneeTelephone: selectedContact?.phoneNumber,
          consigneeEmail: selectedContact?.emailId,
          consigneeFax: selectedContact?.faxNumber
        });
      } else if (name === 'consigneeLocationId') {
        const selectedLocation = filteredConsigneeLocations.find((location) => {
          return location.id === parseInt(value)
        })
        onUpdate({
          consigneeLocationId: value,
          consigneeLocationName: selectedLocation?.addressLine1 + ", " + selectedLocation?.city + ", " + selectedLocation?.state
        });
      } else {
        onUpdate({ [name]: value });
      }
    }
  }

  const handleShipperChange = (e) => {
    const { name, value } = e.target;
    if (name === 'shipperContactId') {
      const selectedContact = entityData?.currentEntity?.contacts?.find((contact) => {
        return contact.userId === parseInt(value)
      })

      onUpdate({
        shipperId: entityData?.currentEntity?.entityInfo?.entityId,
        shipperName: entityData?.currentEntity?.entityInfo?.entityName,
        shipperContactId: value,
        shipperContactName: selectedContact?.firstName + " " + selectedContact?.lastName,
        shipperTelephone: selectedContact?.phoneNumber,
        shipperEmail: selectedContact?.emailId,
        shipperFax: selectedContact?.faxNumber
      });
    } else if (name === 'shipperLocationId') {
      const selectedLocation = entityData?.currentEntity?.locations?.find((location) => {
        return location.id === parseInt(value)
      })
      onUpdate({
        shipperLocationId: value,
        shipperLocationName: selectedLocation?.addressLine1 + ", " + selectedLocation?.city + ", " + selectedLocation?.state
      });
    } else {
      onUpdate({ [name]: value });
    }
  }

  const handleSubmit = (event) => {
    const form = event.currentTarget;
    event.preventDefault();
    event.stopPropagation();

    const formErrors = form.querySelectorAll(':invalid').length;

    if (formErrors === 0) {
      onValidation(0); // No errors
      moveToNextTab(); // Move to the next tab
    } else {
      onValidation(formErrors); // Update error count
    }
  };


  return (
    <div className='main-form'>
      <Form noValidate validated={validated} onSubmit={handleSubmit}>
        {userInfo?.roleName === "ROLE_SHIPPER" && (
          <div>
            <div className="main-form-container">
              <div className='supplier-info'>
                <Form.Group controlId="supplierName">
                  <Form.Select
                    autoFocus
                    required
                    name="supplierName"
                    value={annexureParent.supplierId}
                    onChange={handleSupplierChange}
                  >
                    <option value="">Select Supplier</option>
                    {entityData?.supplier?.map((supplier) => (
                      <option key={supplier.entityInfo.entityId} value={supplier.entityInfo.entityId}>
                        {supplier.entityInfo.entityName}
                      </option>
                    ))}
                  </Form.Select>
                  <Form.Control.Feedback type="invalid">
                    Please select one of the values from the Supplier.
                  </Form.Control.Feedback>
                </Form.Group>

                <br />

                <Form.Group controlId="supplierContact">
                  <Form.Select
                    required
                    name="supplierContactId"
                    value={annexureParent.supplierContactId}
                    onChange={handleSupplierChange}
                    disabled={!filteredSupplierContacts.length}
                  >
                    <option value="" disabled>Select Supplier Contact</option>
                    {filteredSupplierContacts?.map((contact) => (
                      <option key={contact.userId} value={contact.userId}>
                        {contact.firstName + " " + contact.lastName}
                      </option>
                    ))}
                  </Form.Select>
                  <Form.Control.Feedback type="invalid">
                    Please select a supplier contact.
                  </Form.Control.Feedback>
                </Form.Group>

                <br />

                <Form.Group controlId="supplierLocation">
                  <Form.Select
                    required
                    name="supplierLocationId"
                    value={annexureParent.supplierLocationId}
                    onChange={handleSupplierChange}
                    disabled={!filteredSupplierLocations.length}
                  >
                    <option value="" disabled>Select Supplier Location</option>
                    {filteredSupplierLocations?.map((location) => (
                      <option key={location.id} value={location.id}>
                        {location.addressLine1}, {location.city}, {location.state}
                      </option>
                    ))}
                  </Form.Select>
                  <Form.Control.Feedback type="invalid">
                    Please select a supplier location.
                  </Form.Control.Feedback>
                </Form.Group>
                <br />
              </div>
              <div className='consignee-info'>
                <Form.Group controlId="consigneeName">
                  <Form.Select
                    autoFocus
                    required
                    name="consigneeName"
                    value={annexureParent.consigneeId}
                    onChange={handleConsigneeChange}
                  >
                    <option value="">Select Consignee</option>
                    {entityData?.consignee?.map((consignee) => (
                      <option key={consignee.entityInfo.entityId} value={consignee.entityInfo.entityId}>
                        {consignee.entityInfo.entityName}
                      </option>
                    ))}
                  </Form.Select>
                  <Form.Control.Feedback type="invalid">
                    Please select one of the values from the consignee.
                  </Form.Control.Feedback>
                </Form.Group>

                <br />

                <Form.Group controlId="consigneeContact">
                  <Form.Select
                    required
                    name="consigneeContactId"
                    value={annexureParent.consigneeContactId}
                    onChange={handleConsigneeChange}
                    disabled={!filteredConsigneeContacts.length}
                  >
                    <option value="" disabled>Select Consignee Contact</option>
                    {filteredConsigneeContacts?.map((contact) => (
                      <option key={contact.userId} value={contact.userId}>
                        {contact.firstName + " " + contact.lastName}
                      </option>
                    ))}
                  </Form.Select>
                  <Form.Control.Feedback type="invalid">
                    Please select a consignee contact.
                  </Form.Control.Feedback>
                </Form.Group>

                <br />

                <Form.Group controlId="consigneeLocation">
                  <Form.Select
                    required
                    name="consigneeLocationId"
                    value={annexureParent.consigneeLocationId}
                    onChange={handleConsigneeChange}
                    disabled={!filteredConsigneeLocations.length}
                  >
                    <option value="" disabled>Select Consignee Location</option>
                    {filteredConsigneeLocations?.map((location) => (
                      <option key={location.id} value={location.id}>
                        {location.addressLine1}, {location.city}, {location.state}
                      </option>
                    ))}
                  </Form.Select>
                  <Form.Control.Feedback type="invalid">
                    Please select a consignee location.
                  </Form.Control.Feedback>
                </Form.Group>
                <br />
              </div>
              <div className='shipper-info'>
                <Form.Group controlId="shipperContact">
                  <Form.Select
                    required
                    name="shipperContactId"
                    value={annexureParent.shipperContactId}
                    onChange={handleShipperChange}
                  >
                    <option value="">Select Shipper Contact</option>
                    {entityData?.currentEntity?.contacts?.map((contact) => (
                      <option key={contact.userId} value={contact.userId}>
                        {contact.firstName + " " + contact.lastName}
                      </option>
                    ))}
                  </Form.Select>
                  <Form.Control.Feedback type="invalid">
                    Please select a shipper contact.
                  </Form.Control.Feedback>
                </Form.Group>

                <br />

                <Form.Group controlId="shipperLocation">
                  <Form.Select
                    required
                    name="shipperLocationId"
                    value={annexureParent.shipperLocationId}
                    onChange={handleShipperChange}
                  >
                    <option value="">Select Shipper Location</option>
                    {entityData?.currentEntity?.locations?.map((location) => (
                      <option key={location.id} value={location.id}>
                        {location.addressLine1}, {location.city}, {location.state}
                      </option>
                    ))}
                  </Form.Select>
                  <Form.Control.Feedback type="invalid">
                    Please select a shipper location.
                  </Form.Control.Feedback>
                </Form.Group>
                <br />
              </div>
            </div>
            <div className='shipment-info'>
              <Form.Group controlId="whoArrangesShipment">
                <Form.Label style={{ marginRight: '1rem' }}>Person who arranges the shipment</Form.Label>
                <Form.Check
                  inline
                  type="radio"
                  id="whoArrangesShipmentSupplier"
                  name="whoArrangesShipment"
                  value={'Supplier'}
                  label={selectedSupplierEntity?.entityInfo?.entityName || 'Select Supplier'}
                  checked={annexureParent.whoArrangesShipment === 'Supplier'}
                  onChange={handleChange}
                  disabled={!selectedSupplierEntity}
                  required
                />
                <Form.Check
                  inline
                  type="radio"
                  id="whoArrangesShipmentShipper"
                  name="whoArrangesShipment"
                  value={'Shipper'}
                  label={entityData?.currentEntity?.entityInfo?.entityName || 'Current Entity Name'}
                  checked={annexureParent.whoArrangesShipment === 'Shipper'}
                  onChange={handleChange}
                  required
                />
                <Form.Control.Feedback type="invalid">
                  Please select who arranges the shipment.
                </Form.Control.Feedback>
              </Form.Group>
            </div>
          </div>
        )}

        {userInfo?.roleName != "ROLE_SHIPPER" && (
          <div>
            <div className="main-form-container">
              <div className='supplier-info'>
                <FloatingLabel controlId="supplierName" label="Supplier Name" className="mb-3">
                  <Form.Control
                    type="text"
                    placeholder="Supplier Name"
                    value={annexureParent.supplierName || ''}
                    disabled
                  />
                </FloatingLabel>
                <FloatingLabel controlId="supplierContact" label="Supplier Contact" className="mb-3">
                  <Form.Control
                    type="text"
                    placeholder="Supplier Contact"
                    value={annexureParent.supplierContactName || ''}
                    disabled
                  />
                </FloatingLabel>
                <FloatingLabel controlId="supplierLocation" label="Supplier Location" className="mb-3">
                  <Form.Control
                    type="text"
                    placeholder="Supplier Location"
                    value={annexureParent.supplierLocationName || ''}
                    disabled
                  />
                </FloatingLabel>
              </div>
              <div className='consignee-info'>
                <FloatingLabel controlId="consigneeName" label="Consignee Name" className="mb-3">
                  <Form.Control
                    type="text"
                    placeholder="Consignee Name"
                    value={annexureParent.consigneeName || ''}
                    disabled
                  />
                </FloatingLabel>
                <FloatingLabel controlId="consigneeContact" label="Consignee Contact" className="mb-3">
                  <Form.Control
                    type="text"
                    placeholder="Consignee Contact"
                    value={annexureParent.consigneeContactName || ''}
                    disabled
                  />
                </FloatingLabel>
                <FloatingLabel controlId="consigneeLocation" label="Consignee Location" className="mb-3">
                  <Form.Control
                    type="text"
                    placeholder="Consignee Location"
                    value={annexureParent.consigneeLocationName || ''}
                    disabled
                  />
                </FloatingLabel>
              </div>
              <div className='shipper-info'>
                <FloatingLabel controlId="shipperName" label="Shipper Name" className="mb-3">
                  <Form.Control
                    type="text"
                    placeholder="Shipper Name"
                    value={annexureParent.shipperName || ''}
                    disabled
                  />
                </FloatingLabel>
                <FloatingLabel controlId="shipperContact" label="Shipper Contact" className="mb-3">
                  <Form.Control
                    type="text"
                    placeholder="Shipper Contact"
                    value={annexureParent.shipperContactName || ''}
                    disabled
                  />
                </FloatingLabel>
                <FloatingLabel controlId="shipperLocation" label="Shipper Location" className="mb-3">
                  <Form.Control
                    type="text"
                    placeholder="Shipper Location"
                    value={annexureParent.shipperLocationName || ''}
                    disabled
                  />
                </FloatingLabel>
              </div>
            </div>
            <div className='shipment-info'>
              <FloatingLabel controlId="whoArrangesShipment" label="Who Arranges the Shipment" className="mb-3">
                <Form.Control
                  type="text"
                  value={annexureParent.whoArrangesShipment === 'Supplier' ? annexureParent.supplierName : annexureParent.shipperName}
                  disabled
                />
              </FloatingLabel>
            </div>
            <br />
          </div>
        )}
        <Button variant="primary" type="submit">Save and Next</Button>
      </Form >
    </div >
  );
};

export default MainTab;