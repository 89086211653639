// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* LoginPage.css */
.container {
    padding: 0;
}

.header {
    padding-top: 25%;
}

.header-title {
    font-size: 7em;
    color: rgba(0, 125, 0, 0.8);
}

.header-subtitle {
    color: black;
}

.form-container {
    padding: 10%;
    margin-top: 10%;
}

.form-content {
    width: 100%;
    background-color: #b2e0ab66;
    padding: 10%;
    border-radius: 10px;
}

.error-message {
    color: red;
    font-weight: bold;
}

.login-button {
    margin-top: 1rem;
}`, "",{"version":3,"sources":["webpack://./src/pages/Login/Login.css"],"names":[],"mappings":"AAAA,kBAAkB;AAClB;IACI,UAAU;AACd;;AAEA;IACI,gBAAgB;AACpB;;AAEA;IACI,cAAc;IACd,2BAA2B;AAC/B;;AAEA;IACI,YAAY;AAChB;;AAEA;IACI,YAAY;IACZ,eAAe;AACnB;;AAEA;IACI,WAAW;IACX,2BAA2B;IAC3B,YAAY;IACZ,mBAAmB;AACvB;;AAEA;IACI,UAAU;IACV,iBAAiB;AACrB;;AAEA;IACI,gBAAgB;AACpB","sourcesContent":["/* LoginPage.css */\n.container {\n    padding: 0;\n}\n\n.header {\n    padding-top: 25%;\n}\n\n.header-title {\n    font-size: 7em;\n    color: rgba(0, 125, 0, 0.8);\n}\n\n.header-subtitle {\n    color: black;\n}\n\n.form-container {\n    padding: 10%;\n    margin-top: 10%;\n}\n\n.form-content {\n    width: 100%;\n    background-color: #b2e0ab66;\n    padding: 10%;\n    border-radius: 10px;\n}\n\n.error-message {\n    color: red;\n    font-weight: bold;\n}\n\n.login-button {\n    margin-top: 1rem;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
