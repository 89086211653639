import React from 'react';
import { Button } from 'react-bootstrap';
import axiosInstance from '../../services/AxiosConfig';

const PreviewRenderer = (props) => {
  const onClick = async () => {
    console.log("annex: ", props.data);

    try {
      const response = await axiosInstance({
        method: 'GET',
        url: `/api/annex/generate`,
        params: {
          annexureId: props.data.AnnexId // Ensure this is the correct property for the annexure ID
        },
        responseType: 'blob' // Ensures that the response is treated as binary (ZIP file)
      });

      // Create a Blob URL from the binary data (ZIP file)
      const fileURL = window.URL.createObjectURL(response.data);

      // Create a link element, set the download URL and filename, and trigger the download
      const alink = document.createElement("a");
      alink.href = fileURL;
      alink.download = "annexures.zip"; // Filename for the ZIP file
      document.body.appendChild(alink);
      alink.click();
      document.body.removeChild(alink); // Clean up the link element
      URL.revokeObjectURL(fileURL); // Free memory
    } catch (error) {
      console.error('Error downloading the ZIP file:', error);
    }
  };

  return (
    <Button size='sm' onClick={onClick}>
      Download Annexures
    </Button>
  );
};

export default PreviewRenderer;