import React, { useState } from 'react';

const ResetTempPassword = ({ cognitoUser, userAttributes }) => {
    const [newPassword, setNewPassword] = useState('');
    const [message, setMessage] = useState('');

    const handleResetTempPassword = (event) => {
        event.preventDefault();

        // Remove non-writable attributes
        const writableAttributes = {};
        for (const key in userAttributes) {
            if (
                userAttributes.hasOwnProperty(key) &&
                key !== 'sub' &&
                key !== 'email' &&
                key !== 'email_verified' &&
                key !== 'phone_number_verified'
            ) {
                writableAttributes[key] = userAttributes[key];
            }
        }

        console.log('handleResetTempPassword - userAttributes: ', writableAttributes);
        cognitoUser.completeNewPasswordChallenge(newPassword, writableAttributes, {
            onSuccess: (result) => {
                console.log('Password changed successfully:', result);
                setMessage('Password has been changed successfully.');
            },
            onFailure: (err) => {
                console.error('Error changing password:', err);
                setMessage('Error changing password.');
            },
        });
    };

    return (
        <div>
            <h2>Change Password</h2>
            <form onSubmit={handleResetTempPassword}>
                <label>
                    New Password:
                    <input
                        type="password"
                        value={newPassword}
                        onChange={(e) => setNewPassword(e.target.value)}
                        required
                    />
                </label>
                <button type="submit">Change Password</button>
            </form>
            {message && <p>{message}</p>}
        </div>
    );
};

export default ResetTempPassword;