import React, { useContext, useEffect, useState } from 'react';
import { Form, Button, InputGroup, FloatingLabel } from 'react-bootstrap';
import axiosInstance from '../../services/AxiosConfig';
import { ErrorAlert, SuccessAlert } from '../../utils/AlertUtils';
import { UserContext } from "../../context/UserContext";
import { useLocation } from "react-router-dom";


function LinkExistingEntry() {
    const [referralCode, setReferralCode] = useState('');
    const [entityData, setEntityData] = useState(null);
    const [message, setMessage] = useState("");
    const [showError, setShowError] = useState(false);
    const [showSuccess, setShowSuccess] = useState(false);
    const { userInfo } = useContext(UserContext);
    const [userInfoEntityId, setUserInfoEntityId] = useState("");
    const location = useLocation();


    const handleShowError = (message) => {
        setShowError(true);
        setMessage(message);
    };

    const handleHideError = () => {
        setShowError(false);
    };

    const handleShowSuccess = (message) => {
        setShowSuccess(true);
        setMessage(message);
    };

    const handleSucessClose = () => {
        setShowSuccess(false);
    };

    const handleSearch = () => {
        axiosInstance.get(`/api/entity/referralId/${referralCode}`)
            .then(response => {
                if (response.data) {
                    setEntityData(response.data);
                } else {
                    setEntityData(null);
                    handleShowError('No matching data found');
                }
            })
            .catch(error => {
                setEntityData(null);
                handleShowError(error.message || 'Something went wrong');
            });
    };

    const onLinkEntity = () => {
        if (entityData?.entityInfo?.referralId && userInfoEntityId) {
            let data = {};
            console.log("entityData.entityInfo.entityType: ", location.state.entityType)
            console.log("userInfoEntityId: ", userInfoEntityId)
            console.log("entityData.entityInfo.entityId: ", entityData.entityInfo.entityId)
            if (location.state.entityType === "Shipper") {
                data = {
                    entityId: userInfoEntityId, // supplier
                    linkedEntityId: entityData.entityInfo.entityId// shipper
                }
            } else if (location.state.entityType === "Consignee") {
                data = {
                    entityId: userInfoEntityId, // shipper
                    linkedEntityId: entityData.entityInfo.entityId// consignee
                }
            }
            axiosInstance.post('/api/entity/linkEntities', data)
                .then(response => {
                    if (response.data) {
                        handleShowSuccess("Entity linked successfully!");
                    }
                })
                .catch(error => {
                    handleShowError(error.message || 'Failed to link the entity');
                });
        }
    };

    useEffect(() => {
        if (userInfo?.entityInfo?.entityId) {
            setUserInfoEntityId(userInfo.entityInfo.entityId);
        }
    }, [userInfo]);

    return (
        <div className="search-entity">
            <h3>Search and Link Entity</h3>
            <InputGroup className="mb-3">
                <FloatingLabel label="Referral Code" controlId="referralCode">
                    <Form.Control
                        type="text"
                        value={referralCode}
                        placeholder="Enter referral code"
                        onChange={(e) => setReferralCode(e.target.value)}
                    />
                </FloatingLabel>
                <Button variant="primary" onClick={handleSearch}>Search</Button>
            </InputGroup>

            {entityData && (
                <div className="entity-info">
                    <p><strong>Entity Name:</strong> {entityData.entityInfo.entityName}</p>
                    <p><strong>Entity Role:</strong> {entityData.entityInfo.entityType}</p>
                    <Button variant="primary" onClick={onLinkEntity}>Link Entity</Button>
                </div>
            )}

            {showError && (
                <ErrorAlert showError={showError} onHide={handleHideError} message={message} />
            )}

            {showSuccess && (
                <SuccessAlert showSucess={showSuccess} onHide={handleSucessClose} message={message} />
            )}
        </div>
    );
}

export default LinkExistingEntry;