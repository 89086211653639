// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.main-form {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin: 0 auto;
    gap: 20px;
    background-color: #b2e0ab66;
}

.main-form-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 0 auto;
    gap: 20px;
}

.supplier-info,
.consignee-info,
.shipper-info {
    flex: 1;
    padding: 10px;
    border-radius: 5px;
}

.shipment-info {
    flex: 1;
    flex-direction: column;
    padding: 10px;
    border-radius: 5px;
}

.form-group {
    margin-bottom: 15px;
}

.form-group label {
    margin-bottom: 5px;
}

.form-group input {
    width: 100%;
    padding: 8px;
    box-sizing: border-box;
}

.error {
    color: red;
    font-size: 12px;
    margin-top: 5px;
}

button {
    padding: 10px;
    background-color: #28a745;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

button:hover {
    background-color: #218838;
}`, "",{"version":3,"sources":["webpack://./src/pages/AnnexV2/MainTab.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,8BAA8B;IAC9B,cAAc;IACd,SAAS;IACT,2BAA2B;AAC/B;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,8BAA8B;IAC9B,cAAc;IACd,SAAS;AACb;;AAEA;;;IAGI,OAAO;IACP,aAAa;IACb,kBAAkB;AACtB;;AAEA;IACI,OAAO;IACP,sBAAsB;IACtB,aAAa;IACb,kBAAkB;AACtB;;AAEA;IACI,mBAAmB;AACvB;;AAEA;IACI,kBAAkB;AACtB;;AAEA;IACI,WAAW;IACX,YAAY;IACZ,sBAAsB;AAC1B;;AAEA;IACI,UAAU;IACV,eAAe;IACf,eAAe;AACnB;;AAEA;IACI,aAAa;IACb,yBAAyB;IACzB,WAAW;IACX,YAAY;IACZ,kBAAkB;IAClB,eAAe;AACnB;;AAEA;IACI,yBAAyB;AAC7B","sourcesContent":[".main-form {\n    display: flex;\n    flex-direction: column;\n    justify-content: space-between;\n    margin: 0 auto;\n    gap: 20px;\n    background-color: #b2e0ab66;\n}\n\n.main-form-container {\n    display: flex;\n    flex-direction: row;\n    justify-content: space-between;\n    margin: 0 auto;\n    gap: 20px;\n}\n\n.supplier-info,\n.consignee-info,\n.shipper-info {\n    flex: 1;\n    padding: 10px;\n    border-radius: 5px;\n}\n\n.shipment-info {\n    flex: 1;\n    flex-direction: column;\n    padding: 10px;\n    border-radius: 5px;\n}\n\n.form-group {\n    margin-bottom: 15px;\n}\n\n.form-group label {\n    margin-bottom: 5px;\n}\n\n.form-group input {\n    width: 100%;\n    padding: 8px;\n    box-sizing: border-box;\n}\n\n.error {\n    color: red;\n    font-size: 12px;\n    margin-top: 5px;\n}\n\nbutton {\n    padding: 10px;\n    background-color: #28a745;\n    color: #fff;\n    border: none;\n    border-radius: 5px;\n    cursor: pointer;\n}\n\nbutton:hover {\n    background-color: #218838;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
