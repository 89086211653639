import React from 'react';
import TestCard from './TestCard';
// import Test1 from "./Test1";

function Test() {

    const JsonData = ['Hareesh', 'Ramesh'];

    return (
        <div>
            <TestCard items={JsonData}/>
        </div>
    );
}

export default Test;