import { Row, Col, Container } from "react-bootstrap";
import ConsigneeDetails from "./ConsigneeDetails";
function Consignees(props){
    return(
        <div>
            <Container>
                <ConsigneeDetails details={props.details}/>                
            </Container>
        </div>
    );
}

export default Consignees;