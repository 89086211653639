import React, { useEffect, useState } from 'react';
import { Col, Form, Row, FloatingLabel } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";

const TruckCarrier = ({ carrier, onUpdate }) => {

    useEffect(() => {
        setSelectedDateOfTransfer(carrier?.dateOfTransfer);
    }, []);

    const handleChange = (e) => {
        console.log("handleChange: in truck carrier tab ", e.target.name, " - ", e.target.value);
        const { name, value } = e.target;
        onUpdate({ [name]: value });
    };

    const [selectedDateOfTransfer, setSelectedDateOfTransfer] = useState(null);

    const handleDateOfTransferChange = (date) => {
        setSelectedDateOfTransfer(date);
        onUpdate({ dateOfTransfer: date });
    };

    return (
        <div style={{ backgroundColor: "rgba(178, 224, 171, .4)", padding: "2%", borderRadius: "10px", marginBottom: "20px" }}>
            <h5>Truck Details</h5>
            <Row className="mb-3">
                <Col xs={12} md={4}>
                    <FloatingLabel controlId="floatingName" label="Carrier Name">
                        <Form.Control
                            placeholder="Enter Carrier Name"
                            name="name"
                            onChange={handleChange}
                            value={carrier?.name}
                        />
                    </FloatingLabel>
                </Col>
                <Col xs={12} md={4}>
                    <FloatingLabel controlId="floatingAddress" label="Address">
                        <Form.Control
                            placeholder="Enter Address"
                            name="address"
                            onChange={handleChange}
                            value={carrier?.address}
                        />
                    </FloatingLabel>
                </Col>
                <Col xs={12} md={4}>
                    <FloatingLabel controlId="floatingContactPerson" label="Contact Person">
                        <Form.Control
                            placeholder="Enter Contact Person"
                            name="contactPerson"
                            onChange={handleChange}
                            value={carrier?.contactPerson}
                        />
                    </FloatingLabel>
                </Col>
            </Row>
            <Row className="mb-3">
                <Col xs={12} md={4}>
                    <FloatingLabel controlId="floatingTelephone" label="Telephone">
                        <Form.Control
                            placeholder="Enter Telephone"
                            name="telephone"
                            onChange={handleChange}
                            value={carrier?.telephone}
                        />
                    </FloatingLabel>
                </Col>
                <Col xs={12} md={4}>
                    <FloatingLabel controlId="floatingFax" label="Fax">
                        <Form.Control
                            placeholder="Enter Fax"
                            name="fax"
                            onChange={handleChange}
                            value={carrier?.fax}
                        />
                    </FloatingLabel>
                </Col>
                <Col xs={12} md={4}>
                    <FloatingLabel controlId="floatingEmail" label="Email">
                        <Form.Control
                            placeholder="Enter Email"
                            name="email"
                            onChange={handleChange}
                            value={carrier?.email}
                        />
                    </FloatingLabel>
                </Col>
            </Row>

            {/* <Col xs={12} md={4}>
          <FloatingLabel controlId="floatingDateOfTransfer" label="Date of Transfer">
            <DatePicker
              selected={carrier.dateOfTransfer}
              onChange={handleDateChange}
              dateFormat="yyyy/MM/dd"
              className="form-control"
              placeholderText="Select Date"
            />
          </FloatingLabel>
        </Col> */}
            <Row className="mb-3">
                <Col className="custom-left">
                    <Form.Label>Date of Transfer</Form.Label>
                </Col>
                <Col className="custom-left">
                    <Form.Group controlId="formDate">
                        <div style={{ position: 'relative', zIndex: 3 }}>
                            <DatePicker
                                selected={selectedDateOfTransfer} // Ensure no old date is selected
                                onChange={handleDateOfTransferChange}
                                dateFormat="yyyy/MM/dd"
                                className="form-control "
                                placeholderText="Enter Date"
                                minDate={new Date()} // Disable dates before today
                            />
                        </div>
                    </Form.Group>
                </Col>
            </Row>
        </div>
    );
};

export default TruckCarrier;