//import logo from './logo.svg';
import './App.css';
// import ReactDOM from "react-dom/client";
import { BrowserRouter, Navigate, useNavigate } from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.min.css';
import MainMenu from './menu/MainMenu';
import { useEffect, useState } from 'react';
import userPool from './userPool';
import { AuthProvider } from './context/AuthContext';
import { Provider } from 'react-redux';
import AppRoutes from './AppRoutes';
import { UserContext, UserProvider } from './context/UserContext';
import React from 'react';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function App() {

  const [userInfo, setUserInfo] = useState(null);

  useEffect(() => {
    let user = userPool.getCurrentUser();
    if (user) {
      console.log("user info from App: ", userInfo);
      <Navigate to="/home" replace />
    }
  }, []);

  return (
    //<Provider store={annexStore}>
    // <AuthProvider>
    <UserContext.Provider value={{ userInfo, setUserInfo }}>
      <div className="App">
        <BrowserRouter basename='/'>
          {userInfo && <MainMenu />}
          <ToastContainer />
          <AppRoutes />
        </BrowserRouter>
      </div>
    </UserContext.Provider>
    // </AuthProvider>
    //</Provider>
  );
}
// const root = ReactDOM.createRoot(document.getElementById('root'));
// root.render(<App />);
export default App;
// export default  connect(mapStateToProps, mapDispatchToProps)(App);

/*
function App() {
  return (
    <div className="App">
      <Menu/>
      <Dashboard/>
      <Tracking/>
    </div>
  );
}
*/