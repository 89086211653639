import React from "react";
import { Form, Button } from "react-bootstrap";

function LocationList({ locations, editMode, onEditToggle, onInputChange, onSave, onAddNew }) {
    return (
        <>
            {locations.map((location, index) => (
                <Form key={location.id} className="mb-3">
                    <Form.Group className="mb-3">
                        <Form.Label>Address Line 1</Form.Label>
                        <Form.Control
                            type="text"
                            name="addressLine1"
                            value={location.addressLine1}
                            onChange={(e) => onInputChange("locations", index, e)}
                            readOnly={!editMode[index]}
                        />
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Label>Address Line 2</Form.Label>
                        <Form.Control
                            type="text"
                            name="addressLine2"
                            value={location.addressLine2}
                            onChange={(e) => onInputChange("locations", index, e)}
                            readOnly={!editMode[index]}
                        />
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Label>City</Form.Label>
                        <Form.Control
                            type="text"
                            name="city"
                            value={location.city}
                            onChange={(e) => onInputChange("locations", index, e)}
                            readOnly={!editMode[index]}
                        />
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Label>State</Form.Label>
                        <Form.Control
                            type="text"
                            name="state"
                            value={location.state}
                            onChange={(e) => onInputChange("locations", index, e)}
                            readOnly={!editMode[index]}
                        />
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Label>Zip Code</Form.Label>
                        <Form.Control
                            type="text"
                            name="zipCode"
                            value={location.zipCode}
                            onChange={(e) => onInputChange("locations", index, e)}
                            readOnly={!editMode[index]}
                        />
                    </Form.Group>
                    <Button onClick={() => onEditToggle("locations", index)}>
                        {editMode[index] ? "Cancel" : "Edit"}
                    </Button>
                    {editMode[index] && <Button onClick={() => onSave("locations", index)}>Save</Button>}
                </Form>
            ))}
            <Button onClick={onAddNew}>Add New Location</Button>
        </>
    );
}

export default LocationList;