import React from 'react';
import { Col, Row, FloatingLabel, Form } from 'react-bootstrap';
import './Annex.css';

const Annex = ({ annex, disabled, onUpdate, errors }) => {
    const handleChange = (e) => {
        const { name, value } = e.target;
        onUpdate(name, value);
    };

    return (
        <div style={{ marginBottom: "20px", padding: "10px", border: "1px solid #ccc", borderRadius: "5px" }}>
            <h5>Container {annex.annexureSequence}</h5>
            <Row className="mb-3">
                <Col xs={12} md={4}>
                    <FloatingLabel controlId="quantity" label="Quantity">
                        <Form.Control
                            type="number"
                            name="quantity"
                            value={annex.quantity || ""}
                            onChange={handleChange}
                            disabled={disabled}
                            isInvalid={errors.quantity} // Show error if invalid
                        />
                        <Form.Control.Feedback type="invalid">
                            Quantity is required.
                        </Form.Control.Feedback>
                    </FloatingLabel>
                </Col>
                <Col xs={12} md={4}>
                    <FloatingLabel controlId="sealNo" label="Seal No">
                        <Form.Control
                            placeholder="Enter Seal No"
                            name="sealNo"
                            value={annex.sealNo || ""}
                            onChange={handleChange}
                            disabled={disabled}
                            isInvalid={errors.sealNo} // Show error if invalid
                        />
                        <Form.Control.Feedback type="invalid">
                            Seal Number is required.
                        </Form.Control.Feedback>
                    </FloatingLabel>
                </Col>
                {/* </Row> */}
                {/* <Row className="mb-3"> */}
                <Col xs={12} md={4}>
                    <FloatingLabel controlId="containerNo" label="Container No">
                        <Form.Control
                            placeholder="Enter Container No"
                            name="containerNo"
                            value={annex.containerNo || ""}
                            onChange={handleChange}
                            disabled={disabled}
                            isInvalid={errors.containerNo} // Show error if invalid
                        />
                        <Form.Control.Feedback type="invalid">
                            Container Number is required.
                        </Form.Control.Feedback>
                    </FloatingLabel>
                </Col>
            </Row>
        </div>
    );
};

export default Annex;