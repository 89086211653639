import { Alert } from "react-bootstrap";
import React from 'react';

const ErrorAlert = ({ showError, onHide, message }) => (
    <Alert
        key="danger"
        variant="danger"
        show={showError}
        onClose={onHide}
        dismissible
    >
        <Alert.Heading>Oh snap! You got an error!</Alert.Heading>
        <p>
            <span>{message}</span>
        </p>
    </Alert>
);

export { ErrorAlert };

const SuccessAlert = ({ showSucess, onHide, message }) => (
    <Alert
        key="success"
        variant="success"
        show={showSucess}
        onClose={onHide}
        dismissible
    >
        <Alert.Heading>Success!</Alert.Heading>
        <p>
            <span>{message}</span>
        </p>
    </Alert>
);

export { SuccessAlert };