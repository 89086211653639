// 'use strict';
import { Row, Col, InputGroup, Form, Button, Alert } from "react-bootstrap";
import React, { useMemo, useState, useEffect } from 'react';
import { AgGridReact } from 'ag-grid-react';
import "ag-grid-community/styles/ag-grid.css"; // Mandatory CSS required by the grid
import "ag-grid-community/styles/ag-theme-quartz.css"; // Optional Theme appli
import { ModuleRegistry } from 'ag-grid-community';
import { ClientSideRowModelModule } from 'ag-grid-community';

ModuleRegistry.registerModules([ClientSideRowModelModule]);

const Contacts = (props) => {
 

  const [rowData, setRowData] = useState([]);
  // { Address: 'Flat 203, TR Colony, Kothaguda', City: 'Hyderabad', 'State, County': 'Telangana', 'Country, ZipCode': 'India, 500084', PrimaryFlag: 'Yes', Status: 'Active' }

  const [firstname, setFirstname] = useState("");
  const [lastname, setLastname] = useState("");
  const [primary, setPrimary] = useState("");
  const [telephone, setTelephone] = useState("");
  const [fax, setFax] = useState("");
  const [email, setEmail] = useState("");
  const [country, setCountry] = useState("");
  const [status, setStatus] = useState("");

  const [contCreationStatus, setContCreationStatus] = useState(false);
  const [contCreationMessage, setContCreationMessage] = useState("");

  const [displayAddContact, setdisplayAddContact] = useState("none");
  const [displayAddContactButton, setdisplayAddContactButton] = useState("Add Contact");
  const [displayContacts, setDisplayContacts] = useState(false);

  const [contactMsg, setContactMsg] = useState({
    "userId": 0,
    "firstName": "",
    "lastName": "",
    "emailId": "",
    "phoneNumber": "",
    "faxNumber": "",
    "primaryUser": true,
    "language": "",
    "status": "",
    "roleName": "ROLE_CONSIGNEE",
    "entityId": 0
  });

  useEffect(() => {

    const newArray = [...rowData];
    if(newArray.length>0){
      setDisplayContacts(true);
      clearDefaults();
    }else{
      setDisplayContacts(false);
    } 

  }, [rowData]);

  const setCancel = () => {
    setAddContact();
  }

  const clearDefaults = () => {
    console.log("called cleardefaults")
    console.log("end of cleardefaults")
  }

  const saveAction = () => {
    // { Address: 'Flat 203, TR Colony, Kothaguda', City: 'Hyderabad', 'State, County': 'Telangana', 'Country, ZipCode': 'India, 500084', PrimaryFlag: 'Yes', Status: 'Active' }
    var contact = {};
    contact["Name"] = firstname.concat(",", lastname);
    contact["Telephone"] = telephone;
    contact["Fax"] = fax;
    contact["Email Address"] = email;
    contact["PrimaryFlag"] = primary;
    contact["Status"] = status;
    console.log("contact: ", contact);

    // console.log("before rowData: ", rowData);
    const newArray = [...rowData, contact];
    console.log("before newArray: ", newArray);
    setRowData(newArray);
    console.log("after rowData: ", rowData);
    setCancel();
    clearDefaults();
          // setContCreationMessage("Contact has been created successfully");
          // setContCreationStatus(true);

    var jsonMsg = contactMsg;
    // var userid = localStorage.getItem("userid");
    // jsonMsg["userId"]= userid;
    jsonMsg["firstName"]= firstname;
    jsonMsg["lastName"]= lastname;
    jsonMsg["emailId"]= email;
    jsonMsg["phoneNumber"]= telephone;
    jsonMsg["faxNumber"]= fax;
    jsonMsg["language"]= "English";
    jsonMsg["roleName"]= "ROLE_CONSIGNEE";
    jsonMsg["entityId"]= 7;

    var token = localStorage.getItem("token");
    console.log("before calling API");

    fetch('/api/users/create', {
      method: 'POST',
      body: JSON.stringify(jsonMsg),
      headers: new Headers({
          'Content-Type': 'application/json; charset=UTF-8',
          'Authorization': `Bearer ${token}`,
          'Custom-Header': 'Custom-Value'
      }),
  })
      .then((response) => response.text())
      .then((data) => {
        console.log("setting up contact creation status");
          setContCreationMessage("Contact has been created successfully");
          setContCreationStatus(true);
      })
      .catch((err) => {
          console.log(err.message);
      });    

  }

  const [columnDefs, setColumnDefs] = useState([
    {
      field: "Name",
      checkboxSelection: true,
      editable: true,
      cellEditor: 'agSelectCellEditor'
    },
    { field: "Telephone" },
    { field: "Fax" },
    { field: "Email Address" },
    { field: "PrimaryFlag" },
    { field: "Status" }
  ]);

  const defaultColDef = useMemo(() => {
    return {
      filter: 'agTextColumnFilter',
      floatingFilter: true,
    }
  }, []);

  const DisplayAlert = () => {
    return (
      <div>
        {contCreationStatus ? (
          <div>
            <br />
            <Alert variant="success" onClose={() => setContCreationStatus(false)} dismissible>
              {/* <Alert.Heading>Oh snap! You got an update!</Alert.Heading> */}
              <p>
                <span>{contCreationMessage}</span>
              </p>
            </Alert>
          </div>
        ) : (
          <div></div>
        )}
      </div>
    );
  }

  const setAddContact = () => {
    if (displayAddContact === "none") {
      setdisplayAddContact("block");
      setdisplayAddContactButton("Hide Contact");
    } else {
      setdisplayAddContact("none");
      setdisplayAddContactButton("Add Contact");
    }
  }


  const ContactsBlock = () => {
    return (
      <div style={{ width: "100%", backgroundColor: "rgba(178, 224, 171,.4", padding: "1%", borderRadius: "10px" }}>
        <Row>

          <Col lg="9" style={{justifyItems:"left"}}><span><b>Contact</b></span></Col>
          <Col>
            <Row>
              <Col><Button variant="primary" size="sm" href="/annexform">Modify</Button></Col>
              <Col><Button variant="primary" size="sm" href="/annexform">Delete</Button></Col>
            </Row>
          </Col>
        </Row>
        <br />
        <div className="ag-theme-quartz" style={{ height: 400 }}>
          <AgGridReact
            rowData={rowData}
            columnDefs={columnDefs}
            defaultColDef={defaultColDef}
            rowSelection="single"
            suppressRowClickSelection={true}
            pagination={true}
            paginationPageSize={10}
            paginationPageSizeSelector={[10, 25, 50]}
          />
        </div>
      </div>
    );
  }

  const ContactHeader = () => {

    return (
      <div>
        <Row>
          <Col style={{ display: "flex", flexDirection: "row-reverse" }}><Button variant="primary" size="sm" onClick={setAddContact}>{displayAddContactButton}</Button></Col>
        </Row>
      </div>

    );
  }


  return (
    <div>
      <ContactHeader />
      <div style={{ display: displayAddContact }}>
        <br />
        <div style={{ width: "100%", backgroundColor: "rgba(178, 224, 171,.4", padding: "1.5%", borderRadius: "10px" }}>
          <Form>
            <Row>
              <Col>
                <Row>
                  <Col className="custom-left"><span>First Name</span></Col>
                  <Col className="custom-left">
                    <InputGroup className="mb-3">
                      <Form.Control placeholder="Enter First Name" aria-label="First Name" aria-describedby="basic-addon1" defaultValue={firstname} onChange={e => setFirstname(e.target.value)} name="firstname" id="firstname" autoComplete="off" />
                    </InputGroup>                    </Col>
                </Row>
              </Col>
              <Col>
                <Row>
                  <Col className="custom-left"><span>Last Name</span></Col>
                  <Col className="custom-left">
                    <InputGroup className="mb-3">
                      <Form.Control placeholder="Enter Last Name" aria-label="Last Name" aria-describedby="basic-addon1" defaultValue={lastname} onChange={e => setLastname(e.target.value)} name="lastname" id="lastname" autoComplete="off" />
                    </InputGroup>                    </Col>
                </Row>
              </Col>
              <Col>
                <Row>
                  <Col className="custom-left"><span>Primary Status</span></Col>
                  <Col className="custom-left">
                    <InputGroup className="mb-3">
                      <Form.Control placeholder="Enter Primary Flag" aria-label="Primary Flag" aria-describedby="basic-addon1" defaultValue={primary} onChange={e => setPrimary(e.target.value)} name="primary" id="primary" autoComplete="off" />
                    </InputGroup>                    </Col>
                </Row>
              </Col>
            </Row>

            <Row className="customer-start">
              <Col>
                <Row>
                  <Col className="custom-left"><span>Telephone</span></Col>
                  <Col className="custom-left">
                    <InputGroup className="mb-3">
                      <Form.Control placeholder="Enter Telephone" aria-label="Telephone" aria-describedby="basic-addon1" defaultValue={telephone} onChange={e => setTelephone(e.target.value)} name="Telephone" id="Telephone" autoComplete="off" />
                    </InputGroup>                    </Col>
                </Row>
              </Col>
              <Col>
                <Row>
                  <Col className="custom-left"><span>Fax</span></Col>
                  <Col className="custom-left">
                    <InputGroup className="mb-3">
                      <Form.Control placeholder="Enter Fax" aria-label="Fax" aria-describedby="basic-addon1" defaultValue={fax} onChange={e => setFax(e.target.value)} name="Fax" id="Fax" autoComplete="off" />
                    </InputGroup>                    </Col>
                </Row>
              </Col>
              <Col>
                <Row>
                  <Col className="custom-left"><span>Email Address</span></Col>
                  <Col className="custom-left">
                    <InputGroup className="mb-3">
                      <Form.Control placeholder="Enter Email" aria-label="Email" aria-describedby="basic-addon1" defaultValue={email} onChange={e => setEmail(e.target.value)} name="Email" id="Email" autoComplete="off" />
                    </InputGroup>
                  </Col>
                </Row>
              </Col>
            </Row>

            <Row>
              <Col lg="4">
                <Row>
                  <Col className="custom-left"><span>Status</span></Col>
                  <Col className="custom-left">
                    <InputGroup className="mb-3">
                      <Form.Control placeholder="Enter Status" aria-label="Status" aria-describedby="basic-addon1" defaultValue={status} onChange={e => setStatus(e.target.value)} name="status" id="status" autoComplete="off" />
                    </InputGroup>                    </Col>
                </Row>
              </Col>
              <Col lg="8">
                <Row style={{ paddingLeft: "30%", paddingRight: "30%" }}>
                  <Col className="custom-left"><Button variant="primary" size="sm" onClick={saveAction}>Save</Button></Col>
                  <Col className="custom-left"><Button variant="primary" size="sm" onClick={setCancel}>Cancel</Button></Col>
                </Row>
              </Col>
            </Row>

          </Form>
        </div>
      </div>  
      <br />
      <DisplayAlert />
      {
        (displayContacts)
          ? (<ContactsBlock />)
          : (<div style={{ width: "100%", backgroundColor: "rgba(178, 224, 171,.4", padding: "5%", borderRadius: "10px" }}>There are no contacts for this Shipper yet. Please create using <b>Add Contact</b> button</div>)
      }
      {/* <LocationsBlock/> */}
    </div>
  );
}

export default Contacts;