// 'use strict';
import { Row, Col, InputGroup, Form, Button, Alert } from "react-bootstrap";
import React, { useMemo, useState, useEffect } from 'react';
import { AgGridReact } from 'ag-grid-react';
import "ag-grid-community/styles/ag-grid.css"; // Mandatory CSS required by the grid
import "ag-grid-community/styles/ag-theme-quartz.css"; // Optional Theme appli
import { ModuleRegistry } from 'ag-grid-community';
import { ClientSideRowModelModule } from 'ag-grid-community';

ModuleRegistry.registerModules([ClientSideRowModelModule]);

const Locations = () => {

  // console.log("shipper locations props: ",props);  

  const [rowData, setRowData] = useState([]);
  // { Address: 'Flat 203, TR Colony, Kothaguda', City: 'Hyderabad', 'State, County': 'Telangana', 'Country, ZipCode': 'India, 500084', PrimaryFlag: 'Yes', Status: 'Active' }

  const [address1, setAddress1] = useState("");
  const [address2, setAddress2] = useState("");
  const [address3, setAddress3] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [county, setCounty] = useState("");
  const [country, setCountry] = useState("");
  const [zipcode, setZipcode] = useState("");
  const [primary, setPrimary] = useState("");
  const [status, setStatus] = useState("");
  const [contactName, setContactName] = useState("");

  const [locCreationStatus, setLocCreationStatus] = useState(false);
  const [locCreationMessage, setLocCreationMessage] = useState("");

  const [displayAddLocation, setdisplayAddLocation] = useState("none");
  const [displayAddLocationButton, setdisplayAddLocationButton] = useState("Add Location");
  const [displayLocations, setDisplayLocations] = useState(false);

  const [locationMsg, setLocationMsg] = useState({
    "locationId": 0,
    "entityId": 0,
    "addressLine1": "",
    "addressLine2": "",
    "addressLine3": "",
    "city": "",
    "state": "",
    "county": "",
    "country": "",
    "zipCode": "",
    "isHeadQuarter": true,
    "primaryContactId": 0,
    "nonPrimaryContactIds": []
  });

  useEffect(() => {

    const newArray = [...rowData];
    console.log("Length of array: ", newArray.length);
    console.log("data of array: ", newArray);
    if (newArray.length > 0) {
      setDisplayLocations(true);
      setCancel();
    } else {
      setDisplayLocations(false);
    }

  }, [rowData]);

  const setCancel = () => {
    setAddress1("");
    setAddress2("");
    setAddress3("");
    setCity("");
    setState("");
    setCounty("");
    setCountry("");
    setZipcode("");
    setPrimary("");
    setStatus("");
    setAddLocation();
  }

  const saveAction = () => {
    // { Address: 'Flat 203, TR Colony, Kothaguda', City: 'Hyderabad', 'State, County': 'Telangana', 'Country, ZipCode': 'India, 500084', PrimaryFlag: 'Yes', Status: 'Active' }
    var location = {};
    location["Address"] = address1.concat(",", address2, ",", address3);
    location["City"] = city;
    location["State, County"] = state.concat(",", county);
    location["Country, ZipCode"] = country.concat(",", zipcode);
    location["PrimaryFlag"] = primary;
    location["Status"] = status;
    console.log("location: ", location);

    // console.log("before rowData: ", rowData);
    const newArray = [...rowData, location];
    // console.log("before newArray: ", newArray);
    setRowData(newArray);
    // console.log("after rowData: ", rowData);
    // setLocCreationStatus(true);
    // setLocCreationMessage("Location has been created successfully");

    var jsonMsg = locationMsg;

    jsonMsg["addressLine1"] = address1;
    jsonMsg["addressLine2"] = address2;
    jsonMsg["addressLine3"] = address3;
    jsonMsg["city"] = city;
    jsonMsg["state"] = state;
    jsonMsg["county"] = county;
    jsonMsg["country"] = country;
    jsonMsg["zipCode"] = zipcode;
    jsonMsg["primaryContactId"] = contactName;
    jsonMsg["entityId"] = 3;
    

    var token = localStorage.getItem("token");
    console.log("before calling API");

    fetch('/api/location/create', {
      method: 'POST',
      body: JSON.stringify(jsonMsg),
      headers: new Headers({
        'Content-Type': 'application/json; charset=UTF-8',
        'Authorization': `Bearer ${token}`,
        'Custom-Header': 'Custom-Value'
      }),
    })
      .then((response) => response.text())
      .then((data) => {
        console.log("setting up Location creation status");
        setLocCreationMessage("Location has been created successfully");
        setLocCreationStatus(true);
      })
      .catch((err) => {
        console.log(err.message);
      });
  }

  const [columnDefs, setColumnDefs] = useState([
    {
      field: "Address",
      checkboxSelection: true,
      editable: true,
      cellEditor: 'agSelectCellEditor'
    },
    { field: "City" },
    { field: "State, County" },
    { field: "Country, ZipCode" },
    { field: "PrimaryFlag" },
    { field: "Status" }
  ]);

  const defaultColDef = useMemo(() => {
    return {
      filter: 'agTextColumnFilter',
      floatingFilter: true,
    }
  }, []);

  const DisplayAlert = () => {
    return (
      <div>
        {locCreationStatus ? (
          <div>
            <br />
            <Alert variant="success" onClose={() => setLocCreationStatus(false)} dismissible>
              {/* <Alert.Heading>Oh snap! You got an update!</Alert.Heading> */}
              <p>
                <span>{locCreationMessage}</span>
              </p>
            </Alert>
          </div>
        ) : (
          <div></div>
        )}
      </div>
    );
  }

  const setAddLocation = () => {
    if (displayAddLocation === "none") {
      setdisplayAddLocation("block");
      setdisplayAddLocationButton("Hide Location");
    } else {
      setdisplayAddLocation("none");
      setdisplayAddLocationButton("Add Location");
    }
  }


  const LocationsBlock = () => {
    return (
      <div style={{ width: "100%", backgroundColor: "rgba(178, 224, 171,.4", padding: "1%", borderRadius: "10px" }}>
        <Row>

          <Col lg="9" style={{ justifyItems: "left" }}><span><b>Locations</b></span></Col>
          <Col>
            <Row>
              <Col><Button variant="primary" size="sm" href="/annexform">Modify</Button></Col>
              <Col><Button variant="primary" size="sm" href="/annexform">Delete</Button></Col>
            </Row>
          </Col>
        </Row>
        <br />
        <div className="ag-theme-quartz" style={{ height: 400 }}>
          <AgGridReact
            rowData={rowData}
            columnDefs={columnDefs}
            defaultColDef={defaultColDef}
            rowSelection="single"
            suppressRowClickSelection={true}
            pagination={true}
            paginationPageSize={10}
            paginationPageSizeSelector={[10, 25, 50]}
          />
        </div>
      </div>
    );
  }

  const LocationHeader = () => {

    return (
      <div>
        <Row>
          <Col style={{ display: "flex", flexDirection: "row-reverse" }}><Button variant="primary" size="sm" onClick={setAddLocation}>{displayAddLocationButton}</Button></Col>
        </Row>
      </div>

    );
  }


  return (
    <div>
      <LocationHeader />
      <div style={{ display: displayAddLocation }}>
        <br />
        <div style={{ width: "100%", backgroundColor: "rgba(178, 224, 171,.4", padding: "1.5%", borderRadius: "10px" }}>
          <Form>
            <div style={{ paddingLeft: "10%", paddingRight: "30%" }}>
              <Row>
                <Col className="custom-left"><span>Contact</span></Col>
                <Col className="custom-left">
                  <Form.Select aria-label="Default contact" onChange={e => setContactName(e.target.value)} key="contactname">
                    <option key="contact0">Choose Contact</option>
                    <option value="2" key="contact1">Vinod</option>
                    <option value="1" key="contact2">Hari</option>
                    <option value="3" key="contact3">Prasant</option>
                    <option value="4" key="contact4">Hareesh</option>
                    <option value="7" key="contact4">Sur, Shipper</option>
                  </Form.Select>              </Col>

              </Row>
            </div>
            <br />
            <Row>
              <Col>
                <Row>
                  <Col className="custom-left"><span>Address Line 1</span></Col>
                  <Col className="custom-left">
                    <InputGroup className="mb-3">
                      <Form.Control placeholder="Enter Address Line 1" aria-label="Address line 1" aria-describedby="basic-addon1" defaultValue={address1} onChange={e => setAddress1(e.target.value)} name="address1" id="address1" autoComplete="off" />
                    </InputGroup>                    </Col>
                </Row>
              </Col>
              <Col>
                <Row>
                  <Col className="custom-left"><span>Address Line 2</span></Col>
                  <Col className="custom-left">
                    <InputGroup className="mb-3">
                      <Form.Control placeholder="Enter Address Line 2" aria-label="Address line 2" aria-describedby="basic-addon1" defaultValue={address2} onChange={e => setAddress2(e.target.value)} name="address2" id="address2" autoComplete="off" />
                    </InputGroup>                    </Col>
                </Row>
              </Col>
              <Col>
                <Row>
                  <Col className="custom-left"><span>Address Line 3</span></Col>
                  <Col className="custom-left">
                    <InputGroup className="mb-3">
                      <Form.Control placeholder="Enter Address Line 3" aria-label="Address line 3" aria-describedby="basic-addon1" defaultValue={address3} onChange={e => setAddress3(e.target.value)} name="address3" id="address3" autoComplete="off" />
                    </InputGroup>                    </Col>
                </Row>
              </Col>
            </Row>

            <Row className="customer-start">
              <Col>
                <Row>
                  <Col className="custom-left"><span>City</span></Col>
                  <Col className="custom-left">
                    <InputGroup className="mb-3">
                      <Form.Control placeholder="Enter City" aria-label="City" aria-describedby="basic-addon1" defaultValue={city} onChange={e => setCity(e.target.value)} name="city" id="city" autoComplete="off" />
                    </InputGroup>                    </Col>
                </Row>
              </Col>
              <Col>
                <Row>
                  <Col className="custom-left"><span>State</span></Col>
                  <Col className="custom-left">
                    <InputGroup className="mb-3">
                      <Form.Control placeholder="Enter State" aria-label="State" aria-describedby="basic-addon1" defaultValue={state} onChange={e => setState(e.target.value)} name="state" id="state" autoComplete="off" />
                    </InputGroup>                    </Col>
                </Row>
              </Col>
              <Col>
                <Row>
                  <Col className="custom-left"><span>County</span></Col>
                  <Col className="custom-left">
                    <InputGroup className="mb-3">
                      <Form.Control placeholder="Enter County" aria-label="County" aria-describedby="basic-addon1" defaultValue={county} onChange={e => setCounty(e.target.value)} name="county" id="county" autoComplete="off" />
                    </InputGroup>
                  </Col>
                </Row>
              </Col>
            </Row>

            <Row>
              <Col>
                <Row>
                  <Col className="custom-left"><span>Country</span></Col>
                  <Col className="custom-left">
                    <InputGroup className="mb-3">
                      <Form.Control placeholder="Enter Country" aria-label="Country" aria-describedby="basic-addon1" defaultValue={country} onChange={e => setCountry(e.target.value)} name="country" id="country" autoComplete="off" />
                    </InputGroup>                    </Col>
                </Row>
              </Col>
              <Col>
                <Row>
                  <Col className="custom-left"><span>Zipcode</span></Col>
                  <Col className="custom-left">
                    <InputGroup className="mb-3">
                      <Form.Control placeholder="Enter Zipcode" aria-label="Zipcode" aria-describedby="basic-addon1" defaultValue={zipcode} onChange={e => setZipcode(e.target.value)} name="zipcode" id="zipcode" autoComplete="off" />
                    </InputGroup>                    </Col>
                </Row>
              </Col>
              <Col>
                <Row>
                  <Col className="custom-left"><span>Primary Flag</span></Col>
                  <Col className="custom-left">
                    <InputGroup className="mb-3">
                      <Form.Control placeholder="Enter Primary" aria-label="Primary" aria-describedby="basic-addon1" defaultValue={primary} onChange={e => setPrimary(e.target.value)} name="primary" id="primary" autoComplete="off" />
                    </InputGroup>                    </Col>
                </Row>
              </Col>
            </Row>

            <Row>
              <Col lg="4">
                <Row>
                  <Col className="custom-left"><span>Status</span></Col>
                  <Col className="custom-left">
                    <InputGroup className="mb-3">
                      <Form.Control placeholder="Enter Status" aria-label="Status" aria-describedby="basic-addon1" defaultValue={status} onChange={e => setStatus(e.target.value)} name="status" id="status" autoComplete="off" />
                    </InputGroup>                    </Col>
                </Row>
              </Col>
              <Col lg="8">
                <Row style={{ paddingLeft: "30%", paddingRight: "30%" }}>
                  <Col className="custom-left"><Button variant="primary" size="sm" onClick={saveAction}>Save</Button></Col>
                  <Col className="custom-left"><Button variant="primary" size="sm" onClick={setCancel}>Cancel</Button></Col>
                </Row>
              </Col>
            </Row>

          </Form>
        </div>
      </div>
      <br />
      <DisplayAlert />
      {
        (displayLocations)
          ? (<LocationsBlock />)
          : (<div style={{ width: "100%", backgroundColor: "rgba(178, 224, 171,.4", padding: "5%", borderRadius: "10px" }}>There are no locations for this Shipper yet. Please create using <b>Add Location</b> button</div>)
      }
      {/* <LocationsBlock/> */}
    </div>
  );
}

export default Locations;