// import { useState, useEffect } from "react";
import { Row, Col, Button, Container, Modal } from 'react-bootstrap';
import ShipperDashboard from "./ShipperDashboard";
import { Link } from 'react-router-dom';
import React, { useState } from 'react';
import UploadSignature from '../Signature/UploadSignature';

function Shipper(details) {

    // console.log('details: ',details.username);

    // const [supplierName, setSupplierName] = useState(null);

    // useEffect(() => {
    //         setSupplierName(details.username);
    // }, []);

    const [showUploadModal, setShowUploadModal] = useState(false);

    const handleOpenModal = () => {
        setShowUploadModal(true);
    };

    const handleCloseModal = () => {
        setShowUploadModal(false);
    };


    const ShipperHeader = () => {

        return (
            <div style={{ marginTop: "1%" }}>
                <h2>Shipper Dashboard</h2>

                <div style={{ width: "100%", backgroundColor: "rgba(178, 224, 171,.5)", padding: "1%", borderRadius: "10px" }}>
                    <Row>
                        {console.log('details.userInfo.username: ', details)}
                        <Col lg="8" style={{ display: "flex", justifyContent: "flex-start" }}><p><b>Shipper: </b>{details.userInfo.firstName + " " + details.userInfo.lastName}</p></Col>
                        <Col lg="8" style={{ display: "flex", justifyContent: "flex-start" }}><p><b>Referral Code: </b>{details.userInfo.entityInfo.referralId}</p></Col>
                        <Col lg="4" >
                            <Row>
                                {/* <Col><Button variant="primary" size="sm" href="/shipper-creation">Supplier</Button></Col> */}
                                <Col><Button variant="primary" size="sm" as={Link} to="/consignee-creation" state={{ entityType: "Consignee" }}>Create Consignee</Button></Col>
                                <Col><Button variant="primary" size="sm" as={Link} to="/link-companies" state={{ entityType: "Consignee" }}>Link Consignee</Button></Col>
                                <Col><Button variant="primary" size="sm" as={Link} to="/annexform">New Annexure</Button></Col>
                                {/* Button to trigger the modal */}
                                <Col><Button onClick={handleOpenModal}>Upload Signature</Button></Col>

                                {/* Modal Popup for UploadSignature */}
                                <Modal show={showUploadModal} onHide={handleCloseModal} centered>
                                    <Modal.Header closeButton>
                                        <Modal.Title>Upload Signature</Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                        {/* UploadSignature Component */}
                                        <UploadSignature />
                                    </Modal.Body>
                                    <Modal.Footer>
                                        <Button variant="secondary" onClick={handleCloseModal}>
                                            Close
                                        </Button>
                                    </Modal.Footer>
                                </Modal>
                            </Row>
                        </Col>
                    </Row>
                </div>

                <br />
            </div>
        );
    }


    return (
        <Container>
            <ShipperHeader />
            <ShipperDashboard username={details.userInfo.username} />
        </Container>
    );
}

export default Shipper;