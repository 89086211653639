import { Button, Container, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import React from 'react';

function AdminDashboard(details) {

    return (
        <div>
            <br />
            <h3>Admin Dashboard</h3>
            <br />
            <Container>
                <div style={{ width: "100%", backgroundColor: "rgba(178, 224, 171,1)", padding: "5%", borderRadius: "5px" }}>
                    <Row>
                        <Col>
                            <Button size="sm" as={Link} to="/supplier-creation" state={{ entityType: "Supplier" }}>Create Supplier</Button>
                        </Col>
                        <Col><Button size="sm" as={Link} to="/shipper-creation" state={{ entityType: "Shipper" }}>Create Shipper</Button></Col>
                        <Col><Button size="sm" as={Link} to="/consignee-creation" state={{ entityType: "Consignee" }}>Create Consignee</Button></Col>
                    </Row>
                </div>
            </Container>
        </div>
    );
}

export default AdminDashboard;