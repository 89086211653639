import React, { useContext, useState } from 'react';
import axiosInstance from '../../services/AxiosConfig';
import { UserContext, UserProvider } from '../../context/UserContext';

const UploadSignature = ({ onUploadSuccess }) => {
    const { userInfo, setUserInfo } = useContext(UserContext);
    const [selectedFile, setSelectedFile] = useState(null);
    const [errorMessage, setErrorMessage] = useState('');
    const [successMessage, setSuccessMessage] = useState('');

    const validFileTypes = ["image/gif", "image/jpeg", "image/png", "image/bmp"];
    const maxSizeInKB = 200;
    const validHeight = 145;
    const validMinWidth = 150;
    const validMaxWidth = 400;

    const handleFileChange = (e) => {
        setErrorMessage('');
        setSuccessMessage('');
        const file = e.target.files[0];
        setSelectedFile(file);
    };

    const validateFile = async (file) => {
        // Validate file type
        if (!validFileTypes.includes(file.type)) {
            return "Invalid file type. Only GIF, JPG, PNG, and BMP are allowed.";
        }

        // Validate file size
        const fileSizeInKB = file.size / 1024;
        if (fileSizeInKB > maxSizeInKB) {
            return `File size exceeds the maximum limit of ${maxSizeInKB} KB.`;
        }

        // Validate image dimensions using FileReader to read the image
        // const imageDimensions = await getImageDimensions(file);
        // if (imageDimensions.height !== validHeight || imageDimensions.width < validMinWidth || imageDimensions.width > validMaxWidth) {
        //     return `Invalid image dimensions. Height must be ${validHeight} pixels and width between ${validMinWidth} and ${validMaxWidth} pixels.`;
        // }

        return null;
    };

    const getImageDimensions = (file) => {
        return new Promise((resolve, reject) => {
            const img = new Image();
            img.onload = () => resolve({ width: img.width, height: img.height });
            img.onerror = reject;

            const reader = new FileReader();
            reader.onload = (e) => {
                img.src = e.target.result;
            };
            reader.readAsDataURL(file);
        });
    };

    const handleUpload = async () => {
        if (!selectedFile) {
            setErrorMessage("Please select a file first.");
            return;
        }

        const validationError = await validateFile(selectedFile);
        if (validationError) {
            setErrorMessage(validationError);
            return;
        }

        // Proceed with upload if validation passes
        const formData = new FormData();
        formData.append("file", selectedFile);

        try {
            const response = await axiosInstance.post("/api/upload/signature", formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });

            if (response.status === 200) {
                if (response.data) {
                    console.log("response.data setting userInfo: ", response.data);
                    setUserInfo({ ...response.data });
                }
                setSuccessMessage("File uploaded successfully.");
                setErrorMessage('');
                onUploadSuccess();  // Notify the modal that the upload was successful
            } else {
                setErrorMessage(`File upload failed: ${response.data || response.message}`);
            }
        } catch (error) {
            setErrorMessage(`File upload failed: ${error.response?.data || error.message}`);
        }
    };

    return (
        <div>
            <h3>Upload Signature</h3>
            <input type="file" accept=".png,.jpg,.jpeg,.bmp,.gif" onChange={handleFileChange} />
            <button onClick={handleUpload}>Upload</button>

            {errorMessage && <p style={{ color: 'red' }}>{errorMessage}</p>}
            {successMessage && <p style={{ color: 'green' }}>{successMessage}</p>}
        </div>
    );
};

export default UploadSignature;