import React, { useState } from 'react';
import { Button } from 'react-bootstrap';
import axiosInstance from '../../services/AxiosConfig';
import { useSearchParams } from "react-router-dom";

const AnnexApproval = ({ annexureParent, role, onUpdateAnnexData }) => {
    const [annexMessage, setAnnexMessage] = useState(null);
    const [searchParams, setSearchParams] = useSearchParams();

    const getApprovalStatusMessage = () => {
        if (annexureParent.status === "3" || (annexureParent?.approvals?.supplierFirstApprovalStatus === "Approved" && annexureParent?.approvals?.consigneeFirstApprovalStatus === "Approved")) {
            return "Annexure is fully approved by both Supplier and Consignee.";
        } else if (annexureParent?.approvals?.supplierFirstApprovalStatus === "Approved") {
            if (role === "ROLE_CONSIGNEE") {
                return "Supplier has approved. Waiting for your approval as Consignee.";
            } else {
                return "You have approved the Annexure.";
            }
        } else if (annexureParent?.approvals?.consigneeFirstApprovalStatus === "Approved") {
            if (role === "ROLE_SUPPLIER") {
                return "Consignee has approved. Waiting for your approval as Supplier.";
            } else {
                return "You have approved the Annexure.";
            }
        } else if (annexureParent.supplierApprovalStatus === "Rejected" || annexureParent.consigneeApprovalStatus === "Rejected") {
            return "Annexure has been rejected.";
        } else if (role === "ROLE_SUPPLIER" && annexureParent?.approvals?.supplierFirstApprovalStatus === "Pending") {
            return "You need to approve or reject as Supplier.";
        } else if (role === "ROLE_CONSIGNEE" && annexureParent?.approvals?.consigneeFirstApprovalStatus === "Pending") {
            return "You need to approve or reject as Consignee.";
        }
    };

    const approveAnnex = async () => {
        console.log('Approve Annex clicked');
        const annexId = searchParams.get("id");
        const url = `/api/annex/update/approval?annexureParentId=${annexId}&status=Approved&user=${role}`;
        try {
            console.log('Calling API');
            await axiosInstance.put(url)
                .then((response) => {
                    if (response.data) {
                        const newObject = { ...response.data };
                        if (role === "ROLE_SUPPLIER") {
                            newObject.supplierApprovalStatus = 'Approved';
                            newObject.status = "2";
                        }
                        if (role === "ROLE_CONSIGNEE") {
                            newObject.consigneeApprovalStatus = 'Approved';
                            newObject.status = "2";
                        }
                        if (newObject.supplierApprovalStatus === "Approved" && newObject.consigneeApprovalStatus === "Approved") {
                            newObject.status = "4";
                        }
                        onUpdateAnnexData(newObject);
                        setAnnexMessage({ successAlert: true, message: "Annexure approved successfully." });
                    }
                }).catch((error) => {
                    setAnnexMessage({ successAlert: false, message: `Error while approving Annexure: ${error}` });
                });
        } catch (error) {
            setAnnexMessage({ successAlert: false, message: `Error while approving Annexure: ${error}` });
        }
    };

    const rejectAnnex = async () => {
        console.log('Reject Annex clicked');
        const annexId = annexureParent.annexureParentId;
        const url = `/api/annex/update/approval?annexureParentId=${annexId}&status=Rejected&user=${role}`;
        try {
            console.log('Calling API');
            await axiosInstance.put(url)
                .then((response) => {
                    if (response.data) {
                        const newObject = { ...annexureParent };
                        if (role === "ROLE_SUPPLIER") {
                            newObject.supplierApprovalStatus = 'Rejected';
                        }
                        if (role === "ROLE_CONSIGNEE") {
                            newObject.consigneeApprovalStatus = 'Rejected';
                        }
                        if (newObject.supplierApprovalStatus === "Rejected" || newObject.consigneeApprovalStatus === "Rejected") {
                            newObject.status = "2";
                        }
                        onUpdateAnnexData(newObject);
                        setAnnexMessage({ successAlert: true, message: "Annexure rejected successfully." });
                    }
                }).catch((error) => {
                    setAnnexMessage({ successAlert: false, message: `Error while rejecting Annexure: ${error}` });
                });
        } catch (error) {
            setAnnexMessage({ successAlert: false, message: `Error while rejecting Annexure: ${error}` });
        }
    };

    const disableButtons = () => {
        if (annexureParent?.approvals?.supplierFirstApprovalStatus === "Approved" && annexureParent?.approvals?.consigneeFirstApprovalStatus === "Approved") {
            return true;
        } else if (annexureParent?.approvals?.supplierFirstApprovalStatus === "Pending" && role === "ROLE_SUPPLIER") {
            return false;
        } else if (annexureParent?.approvals?.consigneeFirstApprovalStatus === "Pending" && role === "ROLE_CONSIGNEE") {
            return false;
        } else {
            return true;
        }
    };

    return (
        <div style={{ backgroundColor: "rgba(178, 224, 171, .4)", padding: "2%", borderRadius: "10px", marginBottom: "20px" }}>
            <p>{getApprovalStatusMessage()}</p>
            <Button onClick={approveAnnex} disabled={disableButtons()} variant="success" style={{ marginRight: '10px' }}>Approve Annex</Button>
            <Button onClick={rejectAnnex} disabled={disableButtons()} variant="danger">Reject Annex</Button>
            {annexMessage && (
                <div className={`alert ${annexMessage.successAlert ? 'alert-success' : 'alert-danger'}`} role="alert">
                    {annexMessage.message}
                </div>
            )}
        </div>
    );
};

export default AnnexApproval;