// import { useState, useEffect } from "react";
import Login from "../Login/Login";
import HomePage from "./HomePage";
import userPool from '../../userPool';
import React from 'react';

const LogOut = () => {
    // Clear local storage or session storage
    localStorage.removeItem('idToken');
    localStorage.removeItem('accessToken');
    localStorage.removeItem('refreshToken');
    localStorage.removeItem("username");
    localStorage.removeItem("name");
    localStorage.removeItem("role");
    localStorage.removeItem("userid");

    // Clear local Cognito session
    const user = userPool.getCurrentUser();
    if (user) {
        user.signOut();
    }

    // Redirect to AWS Cognito logout URL
    const cognitoDomain = 'your-cognito-domain';
    const clientId = 'your-client-id';
    console.log("window", window.location.origin);
    // const logoutUrl = `https://annex7.auth.us-east-1.amazoncognito.com/logout?client_id=${userPool.getClientId()}&logout_uri=${encodeURIComponent(window.location.origin)}&redirect_uri=${encodeURIComponent(window.location.origin)}&response_type=token`;

    window.location.href = "/";
};

// function LogOut() {
//     localStorage.removeItem("username");
//     localStorage.removeItem("name");
//     localStorage.removeItem("role");
//     localStorage.removeItem("userid");
//     localStorage.removeItem("accessToken");

//     return (
//         <HomePage />
//     );

// }

export default LogOut;