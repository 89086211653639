import React, { useContext, useEffect, useState } from 'react';
import { Col, Form, Row, FloatingLabel, Button, Spinner } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import Annex from './Annex';
import axiosInstance from '../../services/AxiosConfig';
import { ErrorAlert, SuccessAlert } from '../../utils/AlertUtils';
import { UserContext } from '../../context/UserContext';
import { Bounce, toast } from 'react-toastify';
import SignatureModal from './SignatureModal';
import { handleCheckout } from './CheckoutNew';

const ShipCarrier = ({ annexureParent, carrier, shippingLineInfo, entityData, onUpdate, onParentUpdate }) => {
    const { userInfo } = useContext(UserContext);
    const [annexureList, setAnnexureList] = useState([]);
    const [selectedDateOfTransfer, setSelectedDateOfTransfer] = useState(null);
    const [errorMessage, setErrorMessage] = useState(null);
    const [successMessage, setSucccessMessage] = useState(null);
    const [showSuccess, setShowSuccess] = useState(false);
    const [message, setMessage] = useState("");
    // const [selectAll, setSelectAll] = useState(false); // State for the Select All checkbox
    const [showModal, setShowModal] = useState(false);
    const [signatureUrl, setSignatureUrl] = useState(null);
    const [saving, setSaving] = useState(false);
    const [validationErrors, setValidationErrors] = useState({});

    // Load annexureList initially from annexureParent
    useEffect(() => {
        console.log("annexureParent in ship: ", annexureParent);
        if (annexureParent.annexureList && annexureParent.annexureList.length > 0) {
            setAnnexureList(annexureParent.annexureList);
        }
        onParentUpdate({ noOfContainers: annexureParent?.annexureList.length });
        setSelectedDateOfTransfer(annexureParent?.shipCarrier?.dateOfTransfer);
        // const allChecked = annexureParent.annexureList.every((annex) => isChecked(annex));
        // setSelectAll(allChecked);
        // if (userInfo?.roleName === "ROLE_CONSIGNEE") {
        //     annexureParent.annexureList.forEach(annex => {
        //         setCheckedItems(prev => ({
        //             ...prev,
        //             [annex.annexureSequence]: annex.consigneeSignoff
        //         }))
        //     })
        // } else {
        //     annexureParent.annexureList.forEach(annex => {
        //         setCheckedItems(prev => ({
        //             ...prev,
        //             [annex.annexureSequence]: annex.whoArrangesShipmentSignoff
        //         }))
        //     })
        // }
    }, []);

    const handleSucessClose = () => {
        setShowSuccess(false);
    };

    const handleChange = (e) => {
        console.log("handleChange: in ship carrier tab ", e.target.name, " - ", e.target.value);
        const { name, value } = e.target;
        if (name === "shippingLine") {
            // setSelectedShippingLine(value);
            onParentUpdate({ shippingLineId: value });
        } else {
            onUpdate({ [name]: value });
        }
    };

    const handleContainerChange = (e) => {
        const { value } = e.target;
        const noOfContainers = Number(value);

        if (noOfContainers < annexureList.length) {
            // toast.error("Use delete icon to remove containers!", {
            //     position: "bottom-right",
            //     autoClose: 5000,
            //     hideProgressBar: false,
            //     closeOnClick: true,
            //     pauseOnHover: true,
            //     theme: "dark",
            //     transition: Bounce,
            // });
            onParentUpdate({ noOfContainers: noOfContainers });
        } else {
            onParentUpdate({ noOfContainers: noOfContainers });
        }
    }

    const handleContainerBlur = (e) => {
        const { value } = e.target;
        const noOfContainers = Number(value);

        setAnnexureList((prevAnnexureList) => {
            let updatedAnnexureList = [...prevAnnexureList];

            if (noOfContainers > prevAnnexureList.length) {
                // Add containers if the number increases
                const containersToAdd = noOfContainers - prevAnnexureList.length;
                const newContainers = Array.from({ length: containersToAdd }, (_, index) => ({
                    annexureSequence: prevAnnexureList.length + index + 1, // Continue id numbering
                    quantity: '',
                    dateOfShipment: null,
                }));

                updatedAnnexureList = [...prevAnnexureList, ...newContainers];
                onParentUpdate({ noOfContainers: noOfContainers, annexureList: updatedAnnexureList });
            } else if (noOfContainers < prevAnnexureList.length) {
                // Remove containers if the number decreases
                toast.error("Use delete icon to remove containers!", {
                    position: "bottom-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    theme: "dark",
                    transition: Bounce,
                });
                //updatedAnnexureList = prevAnnexureList.slice(0, noOfContainers);
            }
            return updatedAnnexureList;
        });
    };

    const handleAnnexUpdate = (index, field, value) => {
        // if (field === "checked") {
        //     //person who arranges shipment else consignee based on role.
        //     const updatedAnnexureList = [...annexureList].map((annex) => {
        //         if (annex.annexureSequence !== index) return annex;
        //         if (annexureParent.whoArrangesShipment === "Shipper") {
        //             if (userInfo?.roleName === "ROLE_SHIPPER") {
        //                 return { ...annex, whoArrangesShipmentSignoff: value }
        //             }
        //         } else if (annexureParent.whoArrangesShipment === "Supplier") {
        //             if (userInfo?.roleName === "ROLE_SUPPLIER") {
        //                 return { ...annex, whoArrangesShipmentSignoff: value }
        //             }
        //         } else if (userInfo?.roleName === "ROLE_CONSIGNEE") {
        //             return { ...annex, consigneeSignoff: value }
        //         } else {
        //             return annex;
        //         }
        //     });
        //     setAnnexureList(updatedAnnexureList);
        //     onParentUpdate({ annexureList: updatedAnnexureList });
        //     // Clear validation error for the updated field
        //     setValidationErrors((prevErrors) => ({
        //         ...prevErrors,
        //         [annexureSequence]: {
        //             ...prevErrors[annexureSequence],
        //             [field]: false,
        //         },
        //     }));
        // } else {
        const updatedAnnexureList = [...annexureList].map((annex) =>
            annex.annexureSequence === index
                ? { ...annex, [field]: value }
                : annex
        );
        setAnnexureList(updatedAnnexureList);
        onParentUpdate({ annexureList: updatedAnnexureList });
        // Clear validation error for the updated field
        setValidationErrors((prevErrors) => ({
            ...prevErrors,
            [index]: {
                ...prevErrors[index],
                [field]: false,
            },
        }));
        // }
    };

    const handleDateOfTransferChange = (date) => {
        setSelectedDateOfTransfer(date);
        onUpdate({ dateOfTransfer: date });
    };

    const handleDeleteAnnex = (sequenceToRemove) => {
        setAnnexureList((prevAnnexureList) => {
            // Remove the annexure with the matching annexureSequence
            const updatedAnnexureList = prevAnnexureList.filter((annex) => annex.annexureSequence !== sequenceToRemove);

            // Reassign annexureSequence for remaining annexures
            const reIndexedAnnexureList = updatedAnnexureList.map((annex, index) => ({
                ...annex,
                annexureSequence: index + 1, // Reassign annexureSequence based on new index
            }));

            // Update parent component with the new annexureList and noOfContainers
            onParentUpdate({ annexureList: reIndexedAnnexureList, noOfContainers: reIndexedAnnexureList.length });

            return reIndexedAnnexureList;
        });
    };

    // Validation logic
    const validateAnnexureList = () => {
        const newErrors = {};
        let allValid = true;

        annexureList.forEach((annex) => {
            const errors = {};
            if (!annex.quantity) {
                errors.quantity = true;
                allValid = false;
            }
            if (!annex.sealNo) {
                errors.sealNo = true;
                allValid = false;
            }
            if (!annex.containerNo) {
                errors.containerNo = true;
                allValid = false;
            }

            if (Object.keys(errors).length > 0) {
                newErrors[annex.annexureSequence] = errors;
            }
        });

        setValidationErrors(newErrors);

        return allValid;
    };

    const handleSave = () => {
        // if (validateAnnexureList()) {
        setSaving(true);
        let apiURL = '';
        if (userInfo?.roleName === "ROLE_SHIPPER") {
            apiURL = '/api/annex/update/shipper';
        }

        else if (userInfo?.roleName === "ROLE_SUPPLIER") {
            apiURL = '/api/annex/update/supplier';
        }

        else if (userInfo?.roleName === "ROLE_CONSIGNEE") {
            apiURL = '/api/annex/update/consignee';
        } else {
            apiURL = '/api/annex/update/shipper';
            // setErrorMessage("Please contact your Administrator. Yo do not have enough priviliges");
            // return
        }
        console.log("annexureParent: ", annexureParent);

        axiosInstance.post(apiURL, annexureParent)
            .then((response) => {
                console.log("Axios response: ", response);
                if (response.status === 200) {
                    // setActiveTab(key);
                } else {
                    setErrorMessage(response?.data);
                }
            }).catch((error) => {
                console.log("Axios error: ", error);
                setErrorMessage(error?.response?.data?.error);
            }).finally(() => {
                setSaving(false); // Set loading to false when the request is complete
            });
        // }
    }

    const handleSignoffApi = async () => {
        if (validateAnnexureList()) {
            setSaving(true);
            let apiURL = '';

            // Determine the API URL based on the user's role
            if (userInfo?.roleName === "ROLE_SHIPPER" || userInfo?.roleName === "ROLE_SUPPLIER") {
                apiURL = '/api/annex/update/signOff/whoArranges';
            } else if (userInfo?.roleName === "ROLE_CONSIGNEE") {
                apiURL = '/api/annex/update/consignee';
            }

            try {
                const response = await axiosInstance.post(apiURL, annexureParent); // Use await to handle the response
                console.log("Axios response: ", response);

                if (response.status === 200) {
                    onParentUpdate(response.data); // Update the parent with new data
                    if (userInfo?.roleName === "ROLE_SUPPLIER") {
                        // After saving, call the handleCheckout function
                        await handleCheckout(annexureParent.annexureParentId, (message) => setErrorMessage(message)); // Call the checkout function
                    }
                } else {
                    setErrorMessage(response?.data);
                }
            } catch (error) {
                console.log("Axios error: ", error);
                setErrorMessage(error?.response?.data?.error);
            } finally {
                setSaving(false); // Set loading to false when the request is complete
            }
        }
    };

    const canSignOff = () => {
        if (userInfo?.roleName === "ROLE_SHIPPER" && annexureParent.whoArrangesShipment === "Shipper" && annexureParent.status == "3") {
            return true;
        }
        else if (userInfo?.roleName === "ROLE_SUPPLIER") {
            if (annexureParent.whoArrangesShipment === "Supplier" && annexureParent.status == "3") {
                return true;
            } else if (annexureParent.whoArrangesShipment === "Shipper" && annexureParent.status == "4") {
                // supplier signedoff and payment to be done by shipper
                return true;
            }
        }
        else if (userInfo?.roleName === "ROLE_CONSIGNEE" && annexureParent.status == "6") {
            return true;
        } else {
            //TODO: return false
            return false;
        }
    }

    // const isChecked = (annex) => {
    //     if (userInfo?.roleName === "ROLE_SHIPPER" && annexureParent.whoArrangesShipment === "Shipper") {
    //         return annex?.whoArrangesShipmentSignoff;
    //     }
    //     else if (userInfo?.roleName === "ROLE_SUPPLIER" && annexureParent.whoArrangesShipment === "Supplier") {
    //         return annex?.whoArrangesShipmentSignoff;
    //     }
    //     else if (userInfo?.roleName === "ROLE_CONSIGNEE") {
    //         return annex?.consigneeSignoff;
    //     } else {
    //         return false;
    //     }
    // }

    // const handleSelectAll = (e) => {
    //     const checked = e.target.checked;
    //     setSelectAll(checked);

    //     const updatedAnnexureList = annexureList.map((annex) => {
    //         if (userInfo?.roleName === "ROLE_CONSIGNEE") {
    //             return { ...annex, consigneeSignoff: checked };
    //         } else {
    //             return { ...annex, whoArrangesShipmentSignoff: checked };
    //         }
    //     });

    //     setAnnexureList(updatedAnnexureList);
    //     onParentUpdate({ annexureList: updatedAnnexureList });
    // };

    const getSignature = async () => {
        try {
            const response = await axiosInstance.get('/api/signature', { responseType: 'blob' }); // Axios call to fetch signature as blob
            if (response.status === 200) {
                const signatureBlob = response.data;
                const signatureUrl = URL.createObjectURL(signatureBlob);
                setSignatureUrl(signatureUrl);
                setShowModal(true);
            } else {
                setErrorMessage("Failed to fetch signature. Please try again.");
            }
        } catch (error) {
            console.error("Error fetching signature:", error);
            setErrorMessage("An error occurred while fetching the signature.");
        }
    };

    const getSignatureV2 = async () => {
        try {
            console.log("getSignatureV2: ", userInfo?.signatureUploadStatus);
            if (userInfo?.signatureUploadStatus == 'Completed') {
                const response = await axiosInstance.get('/api/signature', { responseType: 'blob' });

                if (response.status === 200) {
                    const signatureBlob = response.data;
                    const signatureUrl = URL.createObjectURL(signatureBlob);
                    setSignatureUrl(signatureUrl);
                    setShowModal(true);

                    // Handle the user's action in the modal (for example, 'Continue with Current Signature')
                    return new Promise((resolve, reject) => {
                        // Store resolve and reject for use in modal actions
                        window.modalResolve = resolve;
                        window.modalReject = reject;
                    });
                } else {
                    setErrorMessage("Failed to fetch signature. Please try again.");
                    return false;
                }
            } else {
                setSignatureUrl(signatureUrl);
                setShowModal(true);

                // Handle the user's action in the modal (for example, 'Continue with Current Signature')
                return new Promise((resolve, reject) => {
                    // Store resolve and reject for use in modal actions
                    window.modalResolve = resolve;
                    window.modalReject = reject;
                });
            }
        } catch (error) {
            console.error("Error fetching signature:", error);
            setErrorMessage("An error occurred while fetching the signature.");
            return false;
        }
    };

    const handleContinueModal = () => {
        setShowModal(false);
        console.log("continue Modal");
        setShowSuccess(true);
        setMessage("Annexure Affixed with Signature successfully");
        setErrorMessage("");
        if (window.modalResolve) window.modalResolve(true); // Resolve the promise for continue action
    };

    const handleUploadModal = () => {
        setShowModal(false);
        setShowSuccess(true);
        setMessage("Annexure Affixed with Signature successfully");
        setErrorMessage("");
        if (window.modalResolve) window.modalResolve(true); // Resolve the promise for upload action
    };

    const handleSignoff = async () => {
        if (userInfo?.roleName === "ROLE_SUPPLIER" && annexureParent.status == "4") {
            // After saving, call the handleCheckout function
            await handleCheckout(annexureParent.annexureParentId, (message) => setErrorMessage(message)); // Call the checkout function
            return;
        }
        // perform validation here
        try {
            const isSignatureValid = await getSignatureV2(); // Wait for the signature validation
            if (!isSignatureValid) {
                console.log("Signature validation failed");
                return; // If signature process fails, stop execution
            }
        } catch (error) {
            console.error("Signature validation failed:", error);
            return; // Handle any errors during the signature fetching process
        }
        // if (entityData?.currentEntity?.entityInfo?.signatureUploadStatus === 'Completed') {
        if (!annexureParent?.annexureList || annexureParent.annexureList.length === 0) {
            setErrorMessage("Please add atleast one container");
            return; // Message if the list is null or empty
        }

        handleSignoffApi();

        // console.log("continue: ", annexureParent);
        // if (userInfo?.roleName === "ROLE_CONSIGNEE") {
        //     if (annexureParent.annexureList.every(annex => annex?.consigneeSignoff)) {

        //         handleSave();
        //     } else {
        //         setErrorMessage("Please select all containers");
        //         return;
        //     }
        // } else {
        //     // if (annexureParent.annexureList.every(annex => annex?.quantity && annex?.sealNo && annex?.containerNo)) {
        //     handleSave();
        //     // } else {
        //     //     setErrorMessage("Some fields in the containers are empty. Please fill all required fields and select all");
        //     //     return;
        //     // }
        // }

        // } else {
        //     setErrorMessage("please upload your signature");
        //     return;
        // }
    }


    const canSave = () => {
        console.log("canSave: ", annexureParent);
        if (annexureParent.shippingLineId) {
            if (userInfo?.roleName === "ROLE_CONSIGNEE" && annexureParent?.status == "3") {//annexureParent?.approvals?.consigneeFirstApprovalStatus === "Approved") {
                return true;
            } else if (userInfo?.roleName === "ROLE_SUPPLIER" && annexureParent?.status == "6") {//&& annexureParent?.approvals?.supplierFirstApprovalStatus === "Approved") {
                return true;
            } else if (userInfo?.roleName === "ROLE_SHIPPER" && (!annexureParent?.status || annexureParent?.status == "3" || annexureParent?.status == "1")) {
                return true;
            }
        }
        return false;
    }

    return (
        <div style={{ backgroundColor: "rgba(178, 224, 171, .4)", padding: "2%", borderRadius: "10px", marginBottom: "20px" }}>
            <h5>Shipment Details</h5>
            <Row className="mb-3">
                <Col xs={12} md={4}>
                    {/* {console.log("selectedShippingLine: ", shippingLineInfo)} */}
                    <FloatingLabel controlId="floatingShippingLine" label="Shipping Line Name">
                        <Form.Select
                            name="shippingLine"
                            value={annexureParent.shippingLineId}
                            onChange={handleChange}
                        >
                            <option value="">Select Shipping Line</option>
                            {shippingLineInfo?.map((line) => (
                                <option key={line.shippingLineId} value={line.shippingLineId}>
                                    {line.shippingLineName}
                                </option>
                            ))}
                        </Form.Select>
                    </FloatingLabel>
                </Col>
                <Col xs={12} md={4}>
                    <FloatingLabel controlId="floatingAddress" label="Address">
                        <Form.Control
                            placeholder="Enter Address"
                            name="address"
                            onChange={handleChange}
                            value={carrier?.address || ''}
                        />
                    </FloatingLabel>
                </Col>
                <Col xs={12} md={4}>
                    <FloatingLabel controlId="floatingContactPerson" label="Contact Person">
                        <Form.Control
                            placeholder="Enter Contact Person"
                            name="contactPerson"
                            onChange={handleChange}
                            value={carrier?.contactPerson || ''}
                        />
                    </FloatingLabel>
                </Col>
            </Row>
            <Row className="mb-3">
                <Col xs={12} md={4}>
                    <FloatingLabel controlId="floatingTelephone" label="Telephone">
                        <Form.Control
                            placeholder="Enter Telephone"
                            name="telephone"
                            onChange={handleChange}
                            value={carrier?.telephone || ''}
                        />
                    </FloatingLabel>
                </Col>
                <Col xs={12} md={4}>
                    <FloatingLabel controlId="floatingFax" label="Fax">
                        <Form.Control
                            placeholder="Enter Fax"
                            name="fax"
                            onChange={handleChange}
                            value={carrier?.fax || ''}
                        />
                    </FloatingLabel>
                </Col>
                <Col xs={12} md={4}>
                    <FloatingLabel controlId="floatingEmail" label="Email">
                        <Form.Control
                            placeholder="Enter Email"
                            name="email"
                            onChange={handleChange}
                            value={carrier?.email || ''}
                        />
                    </FloatingLabel>
                </Col>
            </Row>
            <Row className="mb-3">
                <Col xs={12} md={4}>
                    <FloatingLabel controlId="portOfLoading" label="Port of Loading">
                        <Form.Control
                            placeholder="Enter Telephone"
                            name="portOfLoading"
                            onChange={handleChange}
                            value={carrier?.portOfLoading || ''}
                        />
                    </FloatingLabel>
                </Col>
                <Col xs={12} md={4}>
                    <FloatingLabel controlId="portOfDestination" label="Port of Destination">
                        <Form.Control
                            placeholder="Enter Fax"
                            name="portOfDestination"
                            onChange={handleChange}
                            value={carrier?.portOfDestination || ''}
                        />
                    </FloatingLabel>
                </Col>
                <Col xs={12} md={4}>
                    <FloatingLabel controlId="noOfContainers" label="No of Containers">
                        <Form.Control
                            type="number"
                            name="noOfContainers"
                            value={annexureParent.noOfContainers || ''}
                            onChange={handleContainerChange}
                            onBlur={handleContainerBlur}
                            step="1"
                            max="10"
                            onWheel={(e) => e.target.blur()} // Disable the scroll wheel change behavior
                        />
                    </FloatingLabel>
                </Col>
            </Row>
            <Row className="mb-3">
                <Col xs={12} md={4}>
                    <FloatingLabel controlId="voyageNo" label="Voyage No">
                        <Form.Control
                            placeholder="Enter Voyage No"
                            name="voyageNo"
                            onChange={handleChange}
                            value={carrier?.voyageNo || ''}
                        />
                    </FloatingLabel>
                </Col>
                <Col className="custom-left">
                    <Form.Label>Date of Transfer</Form.Label>
                </Col>
                <Col className="custom-left">
                    <Form.Group controlId="formDate">
                        <div style={{ position: 'relative', zIndex: 3 }}>
                            <DatePicker
                                selected={selectedDateOfTransfer} // Ensure no old date is selected
                                onChange={handleDateOfTransferChange}
                                dateFormat="yyyy/MM/dd"
                                className="form-control "
                                placeholderText="Enter Date"
                                minDate={new Date()} // Disable dates before today
                            />
                        </div>
                    </Form.Group>
                </Col>
            </Row>

            {/* Select All Checkbox */}
            {/* <div style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
                <input
                    type="checkbox"
                    checked={selectAll}
                    onChange={handleSelectAll}
                    style={{ marginRight: '10px' }}
                    disabled={!canSignOff()}
                />
                <label>Select All</label>
            </div> */}

            {/* Render Annex components based on annexureList */}
            {annexureParent.status >= 3 && annexureList.map((annex, index) => (
                console.log("annex: ", annex),
                <div key={annex.annexureSequence} style={{ display: 'flex', alignItems: 'center' }}>
                    {/* <input
                        type="checkbox"
                        checked={isChecked(annex)}
                        onChange={(event) => handleAnnexUpdate(annex.annexureSequence, "checked", event.target.checked)}
                        style={{ marginRight: '10px' }}
                        disabled={!canSignOff()}
                    /> */}
                    <div style={{ flexGrow: 1 }}>
                        <Annex
                            annex={annex}
                            disabled={annexureParent.status != 3}
                            onUpdate={(field, value) => handleAnnexUpdate(annex.annexureSequence, field, value)}
                            errors={validationErrors[annex.annexureSequence] || {}}
                        />
                    </div>
                    {/* Delete button/icon */}
                    <Button variant="danger" onClick={() => handleDeleteAnnex(annex.annexureSequence)} style={{ marginLeft: '10px' }}>
                        X
                    </Button>
                </div>
            ))}

            {/* <Annex
                    key={annex.id}
                    annex={annex}
                    onUpdate={(field, value) => handleAnnexUpdate(index, field, value)}
                /> */}
            {saving && (
                <div className="spinner-container">
                    <Spinner animation="grow" variant="success" />
                    <span className="spinner-text">Saving...</span>
                </div>
            )}
            <Row>
                <Col>
                    <div style={{ display: "flex", flexDirection: "row-reverse" }}>
                        <Button variant="primary" type="submit" disabled={!canSave()}
                            onClick={handleSave}> Save</Button>
                    </div>
                </Col>
                <Col>
                    <div style={{ display: "flex", flexDirection: "row-reverse" }}>
                        <Button variant="primary" type="submit" disabled={!canSignOff()}
                            onClick={handleSignoff}>
                            {["ROLE_SUPPLIER"].includes(userInfo?.roleName)
                                ? "Signoff and Generate Annexure"
                                : "Signoff"}
                        </Button>
                    </div>
                </Col>
            </Row>
            {errorMessage && <ErrorAlert message={errorMessage} />}
            <SuccessAlert
                showSucess={showSuccess}
                onHide={handleSucessClose}
                message={message}
            />
            <SignatureModal
                showModal={showModal}
                signatureUrl={signatureUrl}
                handleContinue={handleContinueModal}
                handleUpload={handleUploadModal} // Pass the upload handler
                handleClose={() => setShowModal(false)} // Handle close
            />
            {/* <input type="file" onChange={handleFileUpload} accept="image/*" /> */}
        </div>
    );
};

export default ShipCarrier;