// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* src/HomePage.css */
.homepage-container {
    margin: 0 auto;
    padding: 20px;
    /* border: 1px solid #ccc; */
    border-radius: 5px;
    /* background-color: #f9f9f9; */
  }
  
  .tab-container {
    display: flex;
    justify-content: space-around;
    margin-bottom: 20px;
  }
  
  .tab {
    flex: 1;
    padding: 10px;
    background-color: #909090;
    border: none;
    cursor: pointer;
    text-align: center;
  }
  
  .tab.active {
    background-color: rgba(0, 125, 0,.8);
    color: white;
  }
  
  .tab-content {
    margin-top: 20px;
  }`, "",{"version":3,"sources":["webpack://./src/pages/Home/HomePage.css"],"names":[],"mappings":"AAAA,qBAAqB;AACrB;IACI,cAAc;IACd,aAAa;IACb,4BAA4B;IAC5B,kBAAkB;IAClB,+BAA+B;EACjC;;EAEA;IACE,aAAa;IACb,6BAA6B;IAC7B,mBAAmB;EACrB;;EAEA;IACE,OAAO;IACP,aAAa;IACb,yBAAyB;IACzB,YAAY;IACZ,eAAe;IACf,kBAAkB;EACpB;;EAEA;IACE,oCAAoC;IACpC,YAAY;EACd;;EAEA;IACE,gBAAgB;EAClB","sourcesContent":["/* src/HomePage.css */\n.homepage-container {\n    margin: 0 auto;\n    padding: 20px;\n    /* border: 1px solid #ccc; */\n    border-radius: 5px;\n    /* background-color: #f9f9f9; */\n  }\n  \n  .tab-container {\n    display: flex;\n    justify-content: space-around;\n    margin-bottom: 20px;\n  }\n  \n  .tab {\n    flex: 1;\n    padding: 10px;\n    background-color: #909090;\n    border: none;\n    cursor: pointer;\n    text-align: center;\n  }\n  \n  .tab.active {\n    background-color: rgba(0, 125, 0,.8);\n    color: white;\n  }\n  \n  .tab-content {\n    margin-top: 20px;\n  }"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
