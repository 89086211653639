import React from 'react';
import { Button } from 'react-bootstrap';
import axiosInstance from '../../services/AxiosConfig';

const TrackRenderer = (props) => {
  const handleClick = async () => {
    try {
      const response = await axiosInstance.get(`/api/shippingLine/${props.data.TrackingId}`); // Call backend API
      const shippingLine = response.data;

      // Open the shippingLineWebsite in a new tab
      if (shippingLine.shippingLineWebsite) {
        window.open(shippingLine.shippingLineWebsite, '_blank');
      } else {
        console.error("Website URL not found.");
      }
    } catch (error) {
      console.error("Error fetching shipping line details:", error);
    }
  };

  // Don't render the button if TrackingId is not available
  if (!props.data.TrackingId) {
    return null;
  }

  return (
    <Button size='sm' onClick={handleClick}>
      Track
    </Button>
  );
};

export default TrackRenderer;