import { Row, Col, Container } from "react-bootstrap";
import ShipperDetails from "./ShipperDetails";
function Shippers(props) {
    // console.log("Reports - Shippers - props: ",props.details);
    return (
        <div>
            <Container>
                <ShipperDetails details={props.details}/>
            </Container>
        </div>
    );
}

export default Shippers;