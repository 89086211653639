import { useState, useEffect } from "react";
import { Tabs, Tab, Container } from "react-bootstrap";
import Shippers from "./Shippers";
import Suppliers from "./Suppliers";
import Consignees from "./Consignees";
import Login from "../Login/Login";
import React from 'react';
function Reports() {

    const [loginStatus, setLoginStatus] = useState(false);
    const [role, setRole] = useState(null);
    const [shipperData, setShipperData] = useState({});
    const [supplierData, setSupplierData] = useState({});
    const [consigneeData, setConsigneeData] = useState({});
    const [shipperDataLoad, setShipperDataLoad] = useState(false);
    const [supplierDataLoad, setSupplierDataLoad] = useState(false);
    const [consigneeDataLoad, setConsigneeDataLoad] = useState(false);
    const [shipperErrorMsg, setShipperErrorMsg] = useState("");
    const [supplierErrorMsg, setSupplierErrorMsg] = useState("");
    const [consigneeErrorMsg, setConsigneeErrorMsg] = useState("");

    const fetchShipperData = async () => {
        try {
            //   console.log("Reports - Shipper Data API - called");
            const response = await fetch("https://run.mocky.io/v3/d44f82cd-8df9-4a03-8920-f6bd8c447cb3"); //with data
            // const response = await fetch("https://run.mocky.io/v3/e8c5f371-3b62-496c-9a21-97ab238e1e04"); // with zero data
            // const response = await fetch("https://run.mocky.io/v3/d44f82cd-8df9-4a03-8920----"); // with server issue
            const jsonData = await response.json();
            //   console.log("Reports - Shipper Data API - data: ", jsonData.data);
            setShipperData(jsonData.data);
            setShipperErrorMsg("");
            setShipperDataLoad(true);
            //   console.log("Reports - Shipper Data API - reponse: success");
        } catch (error) {
            console.log("Reports - Shipper Data API - error: ", error);
            setShipperErrorMsg("There is an issue while getting shippers from database.");
            setShipperDataLoad(true);
        }
    };

    const fetchConsigneeData = async () => {
        try {
            //   console.log("Reports - Shipper Data API - called");
            const response = await fetch("https://run.mocky.io/v3/8465fd40-e53c-4b49-a016-11abf1442b07"); //with data
            // const response = await fetch("https://run.mocky.io/v3/e8c5f371-3b62-496c-9a21-97ab238e1e04"); // with zero data
            // const response = await fetch("https://run.mocky.io/v3/d44f82cd-8df9-4a03-8920----"); // with server issue
            const jsonData = await response.json();
            //   console.log("Reports - Shipper Data API - data: ", jsonData.data);
            setConsigneeData(jsonData.data);
            setConsigneeErrorMsg("");
            setConsigneeDataLoad(true);
            //   console.log("Reports - Shipper Data API - reponse: success");
        } catch (error) {
            console.log("Reports - Consignee Data API - error: ", error);
            setConsigneeErrorMsg("There is an issue while getting Consignees from database.");
            setConsigneeDataLoad(true);
        }
    };

    const fetchSupplierData = async () => {
        try {
            //   console.log("Reports - Shipper Data API - called");
            const response = await fetch("https://run.mocky.io/v3/367b0f7c-8397-4a43-b8c2-420b177a653e"); //with data
            // const response = await fetch("https://run.mocky.io/v3/e8c5f371-3b62-496c-9a21-97ab238e1e04"); // with zero data
            // const response = await fetch("https://run.mocky.io/v3/d44f82cd-8df9-4a03-8920----"); // with server issue
            const jsonData = await response.json();
            //   console.log("Reports - Shipper Data API - data: ", jsonData.data);
            setSupplierData(jsonData.data);
            setSupplierErrorMsg("");
            setSupplierDataLoad(true);
            //   console.log("Reports - Shipper Data API - reponse: success");
        } catch (error) {
            console.log("Reports - Supplier Data API - error: ", error);
            setSupplierErrorMsg("There is an issue while getting Suppliers from database.");
            setSupplierDataLoad(true);
        }
    };

    useEffect(() => {

        var localUserName = localStorage.getItem("username");
        // console.log('local username: ', localUserName)
        if (localUserName) {
            var rolename = localStorage.getItem("role");
            setRole(rolename);
            // console.log('local username: ', localUserName)
            setLoginStatus(true);

            if (rolename === "Supplier") {
                fetchShipperData();
                fetchConsigneeData();
            }
            else if (rolename === "Shipper") {
                fetchSupplierData();
                fetchConsigneeData();
            }
            else if (rolename === "Consignee") {
                fetchSupplierData();
                fetchShipperData();
            }
            else if (rolename === "Admin") {
                fetchSupplierData();
                fetchShipperData();
                fetchConsigneeData();
            }
            // fetchSupplierData();
        }
        else {
            setLoginStatus(false);
        }

    }, []);

    const ReportHeader = () => {

        return (
            <div style={{ marginTop: "1%" }}>
                <h3>Reports</h3>
            </div>
        );
    }

    const ShipperInfo = () => {
        return (
            <div style={{ width: "100%", backgroundColor: "rgba(178, 224, 171,.4", padding: "1%", borderRadius: "10px" }}>
                {
                    (shipperDataLoad)
                        ? (
                            (shipperErrorMsg)
                                ? (<div style={{ padding: "10%" }}><p >{shipperErrorMsg}</p>
                                    <p>Please inform adminstrator</p></div>)
                                : ((shipperData.length === 0)
                                    ? (<div style={{ padding: "10%" }}><p>There are no shippers assigned for this supplier.</p></div>)
                                    : (<Shippers details={shipperData} />)))
                        : (<div style={{ padding: "10%" }}><p>Please hold on! We are getting shippers information from database</p></div>)
                }
            </div>
        );
    }

    const ConsigneeInfo = () => {
        return (
            <div style={{ width: "100%", backgroundColor: "rgba(178, 224, 171,.4)", padding: "1%", borderRadius: "10px" }}>
                {
                    (consigneeDataLoad)
                        ? (
                            (consigneeErrorMsg)
                                ? (<div style={{ padding: "10%" }}><p >{consigneeErrorMsg}</p>
                                    <p>Please inform adminstrator</p></div>)
                                : ((consigneeData.length === 0)
                                    ? (<div style={{ padding: "10%" }}><p>There are no consignees assigned for this supplier.</p></div>)
                                    : (<Consignees details={consigneeData} />)))
                        : (<div style={{ padding: "10%" }}><p>Please hold on! We are getting consignees information from database</p></div>)
                }
            </div>
        );
    }

    const SupplierInfo = () => {
        return (
            <div style={{ width: "100%", backgroundColor: "rgba(178, 224, 171,1)", padding: "1%", borderRadius: "10px" }}>
                {
                    (supplierDataLoad)
                        ? (
                            (supplierErrorMsg)
                                ? (<div style={{ padding: "10%" }}><p >{supplierErrorMsg}</p>
                                    <p>Please inform adminstrator</p></div>)
                                : ((supplierData.length === 0)
                                    ? (<div style={{ padding: "10%" }}><p>There are no suppliers assigned for this supplier.</p></div>)
                                    : (<Suppliers details={supplierData} />)))
                        : (<div style={{ padding: "10%" }}><p>Please hold on! We are getting suppliers information from database</p></div>)
                }
            </div>
        );
    }

    const ShipperTab = () => {
        return (
            <div>
                <ReportHeader />
                <Tabs
                    defaultActiveKey="Suppliers"
                    id="uncontrolled-tab-example"
                    className="mb-3"
                >
                    <Tab eventKey="Suppliers" title="Suppliers">
                        <SupplierInfo />
                    </Tab>
                    <Tab eventKey="Consignees" title="Consignees">
                        <ConsigneeInfo />
                    </Tab>
                </Tabs>
            </div>
        );
    }

    const SupplierTab = () => {
        return (
            <div>
                <ReportHeader />
                <Tabs
                    defaultActiveKey="Shippers"
                    id="uncontrolled-tab-example"
                    className="mb-3"
                >
                    <Tab eventKey="Shippers" title="Shippers">
                        <ShipperInfo />
                    </Tab>
                    <Tab eventKey="Consignees" title="Consignees">
                        <ConsigneeInfo />
                    </Tab>
                </Tabs>
            </div>
        );
    }

    const ConsigneeTab = () => {
        return (
            <div>
                <ReportHeader />
                <Tabs
                    defaultActiveKey="Shippers"
                    id="uncontrolled-tab-example"
                    className="mb-3"
                >
                    <Tab eventKey="Shippers" title="Shippers">
                        <ShipperInfo />
                    </Tab>
                    <Tab eventKey="Suppliers" title="Suppliers">
                        <SupplierInfo />
                    </Tab>
                </Tabs>
            </div>
        );
    }

    const AdminTab = () => {
        return (
            <div>
                <ReportHeader />
                <Tabs
                    defaultActiveKey="Shippers"
                    id="uncontrolled-tab-example"
                    className="mb-3"
                >
                    <Tab eventKey="Shippers" title="Shippers">
                        <ShipperInfo />
                    </Tab>
                    <Tab eventKey="Suppliers" title="Suppliers">
                        <SupplierInfo />
                    </Tab>
                    <Tab eventKey="Consignees" title="Consignees">
                        <ConsigneeInfo />
                    </Tab>
                </Tabs>
            </div>
        );
    }

    return (
        <div>
            <Container>
                {
                    (loginStatus) ? (role === "Shipper" ? (<ShipperTab />) : (
                        (role === "Supplier") ? (<SupplierTab />)
                            : (role === "Consignee") ? (<ConsigneeTab />) : (<AdminTab />)
                    )) : (<Login />)
                }
            </Container>
        </div>
    );
}

export default Reports;