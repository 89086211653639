import React, { useContext, useEffect, useState } from 'react';
import { Tabs, Tab, Container, Spinner } from 'react-bootstrap';
import MainTab from './MainTab';
import WasteTab from './WasteTab';
import RecoveryTab from './RecoveryTab';
import CarrierTab from './CarrierTab';
import { useSearchParams } from "react-router-dom";
import axiosInstance from '../../services/AxiosConfig';
import { ErrorAlert } from '../../utils/AlertUtils';
import { UserContext, UserProvider } from '../../context/UserContext';

const AnnexForm = () => {
    const { userInfo } = useContext(UserContext);
    const [searchParams, setSearchParams] = useSearchParams();
    const [annexureParent, setAnnexureParent] = useState({
        annexureParentId: null,
        annexureParentNo: '',
        whoArrangesShipment: 'Shipper',
        shipperId: null,
        shipperName: '',
        supplierId: null,
        supplierName: '',
        consigneeId: null,
        consigneeName: '',
        shipperContactId: null,
        shipperContactName: '',
        supplierContactId: null,
        supplierContactName: '',
        consigneeContactId: null,
        consigneeContactName: '',
        shipperLocationId: null,
        shipperLocationName: '',
        shipperTelephone: '',
        shipperEmail: '',
        shipperFax: '',
        supplierLocationId: null,
        supplierLocationName: '',
        supplierTelephone: '',
        supplierEmail: '',
        supplierFax: '',
        consigneeLocationId: null,
        consigneeLocationName: '',
        consigneeTelephone: '',
        consigneeEmail: '',
        consigneeFax: '',
        wasteGeneratorName: '',
        wasteGeneratorAddress: '',
        wasteGeneratorContactPerson: '',
        wasteGeneratorTelephone: '',
        wasteGeneratorEmail: '',
        wasteGeneratorFax: '',
        wasteDescription: '',
        wasteIdentificationBasel: '',
        wasteIdentificationOECD: '',
        wasteIdentification3a: '',
        wasteIdentification3b: '',
        wasteIdentificationEc: '',
        wasteIdentificationNationalCode: '',
        exportCountry: '',
        transit1: '',
        transit2: '',
        transit3: '',
        importCountry: '',
        recoveryOperation: '',
        recoveryFacility: 'Recovery Facility',
        facilityName: '',
        facilityAddress: '',
        facilityContactPerson: '',
        facilityTelephone: '',
        facilityEmail: '',
        facilityFax: '',
        amount: 0,
        status: '',
        consigneeComments: '',
        BLNumber: '',
        bookingNumber: '',
        creationDate: null,
        lastUpdateDate: null,
        lastUpdateBy: '',
        shippingLineId: null,
        approvals: {},
        truckCarrier: {},
        shipCarrier: {},
        annexureList: [],
        noOfContainers: 0
    });

    const [shippingLineInfo, setShippilngLineInfo] = useState([
        {
            "shippingLineId": 0,
            "shippingLineName": "",
            "shippingLineWebsite": "",
            "status": "",
            "creationDate": "",
            "lastUpdateDate": ""
        }
    ]);

    const [entityData, setEntityData] = useState({ currentEntity: {}, supplier: [], consignee: [] });
    const [entityDataLoaded, setEntityDataLoaded] = useState(false);
    const [activeTab, setActiveTab] = useState('main'); // Track active tab
    const [validationState, setValidationState] = useState({
        main: { validated: false, errorCount: 0 },
        waste: { validated: false, errorCount: 0 },
        recovery: { validated: false, errorCount: 0 },
        carrier: { validated: false, errorCount: 0 }
    });
    // Track whether Waste/Recovery tab has been edited
    const [wasteTabEdited, setWasteTabEdited] = useState(false);
    const [recoveryTabEdited, setRecoveryTabEdited] = useState(false);
    const [errorMessage, setErrorMessage] = useState(null);
    const [loading, setLoading] = useState(false);

    const handleUpdateAnnexureParent = (updatedData) => {
        console.log("Updated data: ", updatedData);
        console.log("Annexure parent: ", annexureParent);
        setAnnexureParent((prev) => {
            const updatedParent = {
                ...prev,  // Keep existing annexureParent state
                ...updatedData,  // Merge the incoming updates (including truckCarrier)
            };

            console.log("Updated annexureParent after merging truckCarrier:", updatedParent);
            return updatedParent;
        });
    };

    const handleTabChange = (key) => {
        // save data and load again n
        if (annexureParent?.annexureParentId == null) {
            console.log("Create new annexure parent: ", annexureParent);
            axiosInstance.post("/api/annex/create", annexureParent)
                .then((response) => {
                    console.log("Axios response: ", response);
                    if (response.status === 200) {
                        // fetch annexure data and load again
                        setActiveTab(key);
                        setAnnexureParent(response.data);
                    } else {
                        setErrorMessage(response?.data);
                    }
                }).catch((error) => {
                    console.log("Axios error: ", error);
                    setErrorMessage(error?.response?.data?.error);
                });
        } else {
            let apiURL = '';
            if (userInfo?.roleName === "ROLE_SHIPPER" && (annexureParent.status == "3" || annexureParent.status == "1")) {
                apiURL = '/api/annex/update/shipper';
            }

            // else if (userInfo?.roleName === "ROLE_SUPPLIER" && annexureParent.status == "3") {
            //     apiURL = '/api/annex/update/supplier';
            // }

            // else if (userInfo?.roleName === "ROLE_CONSIGNEE" && annexureParent.status == "3") {
            //     apiURL = '/api/annex/update/consignee';
            // } 
            else {
                setActiveTab(key);
                // apiURL = '/api/annex/update/shipper';
                // setErrorMessage("Please contact your Administrator. Yo do not have enough priviliges");
                // return;
            }
            if (apiURL != '') {
                axiosInstance.post(apiURL, annexureParent)
                    .then((response) => {
                        console.log("Axios response: ", response);
                        if (response.status === 200) {
                            setActiveTab(key);
                            setAnnexureParent(response.data);
                            if (response.data.annexureList.length > 0) {
                                setAnnexureParent((prevState) => ({
                                    ...prevState,
                                    noOfContainers: annexureParent?.annexureList.length
                                }))
                            }
                        } else {
                            setErrorMessage(response?.data);
                        }
                    }).catch((error) => {
                        console.log("Axios error: ", error);
                        setErrorMessage(error?.response?.data?.error);
                    });
            }
        }
    };

    const handleValidation = (tab, errorCount) => {
        setValidationState(prevState => ({
            ...prevState,
            [tab]: {
                validated: true,
                errorCount
            }
        }));
    };

    // Autofill logic for WasteTab and RecoveryTab
    const autofillWasteRecovery = () => {
        // if (!wasteTabEdited) {
        // Set waste generator info based on who arranges the shipment
        if (annexureParent.whoArrangesShipment === 'Supplier') {
            setAnnexureParent((prevState) => ({
                ...prevState,
                wasteGeneratorName: annexureParent.supplierName || prevState.wasteGeneratorName,
                wasteGeneratorAddress: annexureParent.supplierLocationName || prevState.wasteGeneratorAddress,
                wasteGeneratorContactPerson: annexureParent.supplierContactName || prevState.wasteGeneratorContactPerson,
                wasteGeneratorTelephone: annexureParent.supplierTelephone || prevState.wasteGeneratorTelephone,
                wasteGeneratorEmail: annexureParent.supplierEmail || prevState.wasteGeneratorEmail,
                wasteGeneratorFax: annexureParent.supplierFax || prevState.wasteGeneratorFax,
            }));
        } else {
            setAnnexureParent((prevState) => ({
                ...prevState,
                wasteGeneratorName: annexureParent.shipperName || prevState.wasteGeneratorName,
                wasteGeneratorAddress: annexureParent.shipperLocationName || prevState.wasteGeneratorAddress,
                wasteGeneratorContactPerson: annexureParent.shipperContactName || prevState.wasteGeneratorContactPerson,
                wasteGeneratorTelephone: annexureParent.shipperTelephone || prevState.wasteGeneratorTelephone,
                wasteGeneratorEmail: annexureParent.shipperEmail || prevState.wasteGeneratorEmail,
                wasteGeneratorFax: annexureParent.shipperFax || prevState.wasteGeneratorFax,
            }));
        }
        // }

        // if (!recoveryTabEdited) {
        setAnnexureParent((prevState) => ({
            ...prevState,
            facilityName: annexureParent.consigneeName || prevState.facilityName,
            facilityAddress: annexureParent.consigneeLocationName || prevState.facilityAddress,
            facilityContactPerson: annexureParent.consigneeContactName || prevState.facilityContactPerson,
            facilityTelephone: annexureParent.consigneeTelephone || prevState.facilityTelephone,
            facilityEmail: annexureParent.consigneeEmail || prevState.facilityEmail,
            facilityFax: annexureParent.consigneeFax || prevState.facilityFax,
        }));
        // }
    };

    useEffect(() => {
        autofillWasteRecovery();
    }, [annexureParent.whoArrangesShipment, annexureParent.shipperName, annexureParent.supplierName, annexureParent.consigneeName,
    annexureParent.shipperLocationName, annexureParent.supplierLocationName, annexureParent.consigneeLocationName,
    annexureParent.shipperContactName, annexureParent.supplierContactName, annexureParent.consigneeContactName,
    annexureParent.shipperTelephone, annexureParent.supplierTelephone, annexureParent.consigneeTelephone,
    annexureParent.shipperEmail, annexureParent.supplierEmail, annexureParent.consigneeEmail,
    annexureParent.shipperFax, annexureParent.supplierFax, annexureParent.consigneeFax
    ]);

    const fetchData = async (annexId) => {
        try {
            axiosInstance.get("/api/annex/get/".concat(annexId))
                .then(response => {
                    if (response.data) {
                        setAnnexureParent(response.data);
                        setSupplierData(response.data.supplier);
                        console.log("Query dashboard data: ", response);
                        setAnnexDataLoaded(true);
                    }
                }).catch((error) => {
                    console.log("Shipper Dashboard API - error: ", error);
                });
        } catch (error) {
            // console.log("Shipper Dashboard API - error: ", error);

        }
    };

    const fetchShippingLineData = async () => {
        const linesAPI = "/api/shippingLine/all";
        try {
            const response = await axiosInstance.get(linesAPI);
            setShippilngLineInfo(response.data);
        } catch (error) {
            console.error("Error fetching shipping line data: ", error);
        }
    };

    const fetchEntityData = async () => {
        const entityAPI = "/api/entity/entityInfo";
        try {
            const response = await axiosInstance.get(entityAPI);
            setEntityData(response.data);
            setEntityDataLoaded(true);
            // Fetch shipping line data after entity data is loaded
            await fetchShippingLineData();
        } catch (error) {
            console.error("Error fetching entity data: ", error);
        }
    };

    const fetchAnnexureData = async (annexId) => {
        const annexureAPI = "/api/annex/get/";
        try {
            const response = await axiosInstance.get(annexureAPI.concat(annexId));
            setAnnexureParent(response.data);
            // setAnnexDataLoaded(true);
        } catch (error) {
            console.error("Error fetching annexure data: ", error);
        }
    };

    useEffect(() => {
        console.log("Validation state: ", validationState);
    }, [validationState])

    useEffect(() => {
        const annexId = searchParams.get("id");
        console.log("annexId: ", annexId);

        const loadData = async () => {
            setLoading(true); // Start spinner
            try {
                if (annexId) {
                    await fetchAnnexureData(annexId);
                }

                if (!entityDataLoaded) {
                    await fetchEntityData();
                }
            } catch (error) {
                console.error("Error loading data: ", error);
            } finally {
                setLoading(false); // Stop spinner once all data is loaded
            }
        };

        loadData();
    }, []);

    // TODO: Need to move this to CSS
    const errorStyle = {
        color: 'red',
        fontWeight: 'bold'
    };

    return (
        <Container>
            {errorMessage && <ErrorAlert message={errorMessage} />}
            {loading && (
                <div className="spinner-container">
                    <Spinner animation="grow" variant="success" />
                    <span className="spinner-text">Loading...</span>
                </div>
            )}

            {!loading && (
                <Tabs activeKey={activeTab} onSelect={(k) => handleTabChange(k)}>
                    <Tab
                        eventKey="main"
                        title={
                            <>
                                Main
                                {validationState.main.errorCount > 0 && (
                                    <span style={errorStyle}> ({validationState.main.errorCount} errors)</span>
                                )}
                            </>
                        }
                    >
                        <MainTab
                            annexureParent={annexureParent}
                            onUpdate={handleUpdateAnnexureParent}
                            entityData={entityData}
                            validated={validationState.main.validated}
                            onValidation={(errors) => handleValidation('main', errors)}
                            moveToNextTab={() => handleTabChange('waste')}
                        />
                    </Tab>

                    <Tab
                        eventKey="waste"
                        title={
                            <>
                                Waste
                                {validationState.waste.errorCount > 0 && (
                                    <span style={errorStyle}> ({validationState.waste.errorCount} errors)</span>
                                )}
                            </>
                        }
                    >
                        <WasteTab
                            annexureParent={annexureParent}
                            onUpdate={handleUpdateAnnexureParent}
                            validated={validationState.waste.validated}
                            onValidation={(errors) => handleValidation('waste', errors)}
                            moveToNextTab={() => handleTabChange('recovery')}
                        />
                    </Tab>

                    <Tab
                        eventKey="recovery"
                        title={
                            <>
                                Recovery
                                {validationState.recovery.errorCount > 0 && (
                                    <span style={errorStyle}> ({validationState.recovery.errorCount} errors)</span>
                                )}
                            </>
                        }
                    >
                        <RecoveryTab
                            annexureParent={annexureParent}
                            onUpdate={handleUpdateAnnexureParent}
                            validated={validationState.recovery.validated}
                            onValidation={(errors) => handleValidation('recovery', errors)}
                            moveToNextTab={() => handleTabChange('carrier')}
                        />
                    </Tab>

                    <Tab
                        eventKey="carrier"
                        title={
                            <>
                                Carrier
                                {validationState.carrier.errorCount > 0 && (
                                    <span style={errorStyle}> ({validationState.carrier.errorCount} errors)</span>
                                )}
                            </>
                        }
                    >
                        <CarrierTab
                            annexureParent={annexureParent}
                            shippingLineInfo={shippingLineInfo}
                            entityData={entityData}
                            onUpdate={handleUpdateAnnexureParent}
                            validated={validationState.carrier.validated}
                            onValidation={(errors) => handleValidation('carrier', errors)}
                        />
                    </Tab>
                </Tabs>
            )}
        </Container>
    );
};
export default AnnexForm;