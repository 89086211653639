import dashboard from "../Dashboard/dashboardData.json";
import React from 'react';
function Tracking() {

  const DashboardDataLayOut = () => {
    return (
        dashboard.map((dataRow) => (
            <tr>
                <td>{dataRow.annexNo}</td>
                <td>{dataRow.supplier}</td>
                <td>{dataRow.shipper}</td>
                <td>{dataRow.shipper}</td>
                <td>{dataRow.status}</td>
                <td>{dataRow.supApproval}</td>
                <td><button type="button" class="btn btn-primary btn-sm">Track</button></td>
            </tr>
        )
        )
    );
}

const DashboardHeader = () => {
  return (
      <thead>
          <tr>
              <th>Annex No</th>
              <th>Supplier</th>
              <th>Shipper</th>
              <th>Consignee</th>
              <th>Status</th>
              <th>Estimated Delivery Date</th>
              <th>Track</th>
          </tr>
      </thead>
  );
}

  return (
    <div>
      <h2>Tracking</h2>
      <p>Provide Annex No or Waybill No to track the shipment</p>
      <div className="row">
        <div className="col">
          <input type="text" class="form-control" placeholder="WayBill or Annex No" aria-label="WayBill or Annex No" aria-describedby="button-addon2" />
        </div>
        <div className="col">
        <button class="btn btn-outline-secondary" type="button" id="button-addon2">Search</button>
        </div>
      </div>
      <p>Last 5 tracking status updates</p>
      <table className="table" width={"100%"}>
                <DashboardHeader />
                <tbody>
                    <DashboardDataLayOut />
                </tbody>
            </table>      
    </div>
  );
}

export default Tracking;