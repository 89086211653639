// 'use strict';
import React, { useMemo, useState, useEffect } from 'react';
// import { createRoot } from 'react-dom/client';
import { AgGridReact } from 'ag-grid-react';
import "ag-grid-community/styles/ag-grid.css"; // Mandatory CSS required by the grid
import "ag-grid-community/styles/ag-theme-quartz.css"; // Optional Theme appli
import { ModuleRegistry } from 'ag-grid-community';
import { ClientSideRowModelModule } from 'ag-grid-community';
import { Row, Col, Button } from 'react-bootstrap';
import Spinner from 'react-bootstrap/Spinner';
import ViewRenderer from './ViewRenderer';
import axiosInstance from '../../services/AxiosConfig';
import statusMapping from '../../utils/StatusUtils';
import TrackRenderer from './TrackRenderer';

ModuleRegistry.registerModules([ClientSideRowModelModule]);

// const gridDiv = document.querySelector('#myGrid');

const ConsigneeDashboard = () => {
  const [isDataLoaded, setDataLoaded] = useState(false);
  const [rowData, setRowData] = useState([]);
  const [errorMsg, setErrorMsg] = useState("");
  const [apiData, setApiData] = useState([]);

  const [columnDefs, setColumnDefs] = useState([
    {
      field: "AnnexId",
      checkboxSelection: true,
      editable: true,
      cellEditor: 'agSelectCellEditor'
    },
    { field: "AnnexNo" },
    { field: "Shipper" },
    { field: "Supplier" },
    {
      field: "status",
      valueGetter: (params) => getStatusValue(params.data.Status)
    },
    // { field: "Track", headerName: 'Track', cellRenderer: TrackRenderer },
    { field: "View", headerName: 'View', cellRenderer: ViewRenderer }
  ]);

  const getStatusValue = (status) => {
    if (status === 3 && apiData.supplierApprovalStatus === 'Approved' && apiData.consigneeApprovalStatus === 'Approved') {
      return 'Approved';
    }
    return statusMapping[status] || "Unknown Status";
  };

  const defaultColDef = useMemo(() => {
    return {
      filter: 'agTextColumnFilter',
      floatingFilter: true,
    }
  }, []);

  useEffect(() => {
    var userEmailApi = "/api/annex/get";

    axiosInstance.get(userEmailApi)
      .then(response => {
        setApiData(response.data);
        var dashboardArray = response.data.map(function (data) {
          return {
            "AnnexNo": data.annexureParentNo,
            "Shipper": data.shipperName,
            "Supplier": data.supplierName,
            "Consignee": data.consigneeName,
            "Status": data.status,
            "AnnexId": data.annexureParentId
          }
        });

        console.log("dashboardArray: ", dashboardArray);

        setRowData(dashboardArray);
        setDataLoaded(true);
        setErrorMsg("");
      }).catch((error) => {
        console.log("Shipper Dashboard API - error: ", error);
        setErrorMsg("There is an issue while getting annexures from database. Please inform adminstrator.");
        setDataLoaded(true);
      });
  }, []);

  const Controls = () => {
    return (
      <Row>

        <Col lg="9"><span></span></Col>
        <Col>
          <Row>
            {/* <Col><Button variant="primary" size="sm" href="/annexform">View</Button></Col> */}
            {/* <Col><Button variant="primary" size="sm">Track</Button></Col> */}
          </Row>
        </Col>
      </Row>
    );
  }

  const DashboardData = () => {
    return (
      <div className="ag-theme-quartz" style={{ height: 400 }}>
        <AgGridReact
          rowData={rowData}
          columnDefs={columnDefs}
          defaultColDef={defaultColDef}
          rowSelection="single"
          suppressRowClickSelection={true}
          pagination={true}
          paginationPageSize={10}
          paginationPageSizeSelector={[10, 25, 50]}
        />
      </div>
    );
  }

  const DashboardBlock = () => {

    let dataLength = rowData.length;
    // console.log("Supplier Dashboard - dataLength: ", dataLength)

    return (
      <div>
        {
          (errorMsg)
            ? (<div style={{ padding: "5%" }}>{errorMsg}</div>)
            : (
              (dataLength === 0)
                ? (<div style={{ padding: "5%" }}>
                  <p>There are no annexures for this Consignee at this moment!</p>
                </div>)
                : (
                  <div>
                    <Controls />
                    <br />
                    <DashboardData />
                  </div>
                )
            )
        }
      </div>
    );
  }

  const LoadBLock = () => {
    return (
      <div style={{ padding: "10%" }}><p>Please Hold on!! Gathering the annexures information from database.</p>
        <Spinner animation="grow" variant="dark" /></div>
    );
  }

  return (
    <div style={{ width: "100%", backgroundColor: "rgba(178, 224, 171,.4", padding: "2%", borderRadius: "10px" }}>
      {
        (isDataLoaded)
          ? (<DashboardBlock />)
          : (<LoadBLock />)
      }
    </div>
  );
}

export default ConsigneeDashboard;