const statusMapping = {
  1: "Created",
  2: "Approvals In Progress",
  3: "Approved", // do not allow anyone to edit
  4: "Signoff Complete",
  5: "Payment In Progress",
  6: "Payment Completed",
  7: "Consignee Signoff Complete",
};

export default statusMapping