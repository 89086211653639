import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import { CgHome, CgInsights, CgProfile } from 'react-icons/cg';
import { FaHome } from 'react-icons/fa';
import { TbLogout } from 'react-icons/tb';
import { Link } from 'react-router-dom';
import React from 'react';
// import NavDropdown from 'react-bootstrap/NavDropdown';

function MainMenu() {
    return (
        // <div style={{marginLeft:"2%",marginRight:"2%"}}>
        <Navbar collapseOnSelect expand="md" data-bs-theme="dark" style={{ backgroundColor: "rgba(0, 125, 0,.8)" }}>
            <Container>
                <Navbar.Brand href="/"><b>ANTS</b></Navbar.Brand>
                <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                <Navbar.Collapse id="responsive-navbar-nav">
                    <Nav className="me-auto">
                        <Nav.Link as={Link} to="/home"><div style={{ color: "white" }}><FaHome /> Home</div></Nav.Link>
                    </Nav>
                    <Nav >
                        <Nav.Link as={Link} to="/reports"><div style={{ color: "white" }}><CgInsights /> Reports</div></Nav.Link>
                        <Nav.Link as={Link} to="/profile"><div style={{ color: "white" }}><CgProfile /> Profile</div></Nav.Link>
                        <Nav.Link as={Link} to="/Logout"><div style={{ color: "white" }}><TbLogout /> Logout</div></Nav.Link>
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
        // </div>
    );
}

export default MainMenu;