// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.custom-datepicker-calendar {
    z-index: 1050 !important;
    /* Use !important to ensure it overrides default styles */
    /* position: absolute !important; */
    /* Ensure the position is absolute */
}

.react-datepicker {
    position: relative;
    /* Ensure the parent of the calendar popup is positioned */
}`, "",{"version":3,"sources":["webpack://./src/pages/AnnexV2/WasteTab.css"],"names":[],"mappings":"AAAA;IACI,wBAAwB;IACxB,yDAAyD;IACzD,mCAAmC;IACnC,oCAAoC;AACxC;;AAEA;IACI,kBAAkB;IAClB,0DAA0D;AAC9D","sourcesContent":[".custom-datepicker-calendar {\n    z-index: 1050 !important;\n    /* Use !important to ensure it overrides default styles */\n    /* position: absolute !important; */\n    /* Ensure the position is absolute */\n}\n\n.react-datepicker {\n    position: relative;\n    /* Ensure the parent of the calendar popup is positioned */\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
