import React, { useState, useEffect, useContext } from "react";
import Supplier from "./Supplier";
import Shipper from "./Shipper";
import Consignee from "./Consignee";
import AdminDashboard from "./AdminDashboard";
import axiosInstance from '../../services/AxiosConfig';
import { ErrorAlert } from "../../utils/AlertUtils";
import axios from "axios";
import { UserContext } from "../../context/UserContext";

function Dashboard() {
    const [message, setMessage] = useState("");
    const [showError, setShowError] = useState(false);
    // const [userInfo, setUserInfo] = useState({
    //     "email": "",
    //     "username": "",
    //     "role": ""
    // });
    const { userInfo, setUserInfo } = useContext(UserContext);

    const handleShowError = (message) => {
        setShowError(true);
        setMessage(message);
    };

    const handleHideError = () => {
        setShowError(false);
    };

    const roleComponents = new Map([
        ["ROLE_SUPPLIER", Supplier],
        ["ROLE_SHIPPER", Shipper],
        ["ROLE_CONSIGNEE", Consignee],
        ["ROLE_ADMIN", AdminDashboard],
    ]);

    useEffect(() => {
        axiosInstance.get('/api/users/userInfo')
            .then(response => {
                setUserInfo(response.data);
            }).catch((error) => {
                handleShowError(error.message);
            });
    }, []);

    return (
        <div>
            {userInfo?.roleName ? (
                <>
                    {
                        roleComponents.has(userInfo.roleName)
                            ? React.createElement(roleComponents.get(userInfo.roleName), { userInfo })
                            : <></>
                    }
                </>
            ) : (
                <ErrorAlert
                    showError={showError}
                    onHide={handleHideError}
                    message={message}
                />
            )}
        </div>
    );

}

export default Dashboard;