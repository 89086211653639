import React from "react";
import { Form, Button } from "react-bootstrap";

function UserList({ contacts, editMode, onEditToggle, onInputChange, onSave, onAddNew }) {
    return (
        <>
            {contacts.map((contact, index) => (
                <Form key={contact.userId} className="mb-3">
                    <Form.Group className="mb-3">
                        <Form.Label>First Name</Form.Label>
                        <Form.Control
                            type="text"
                            name="firstName"
                            value={contact.firstName}
                            onChange={(e) => onInputChange("contacts", index, e)}
                            readOnly={!editMode[index]}
                        />
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Label>Last Name</Form.Label>
                        <Form.Control
                            type="text"
                            name="lastName"
                            value={contact.lastName}
                            onChange={(e) => onInputChange("contacts", index, e)}
                            readOnly={!editMode[index]}
                        />
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Label>Email</Form.Label>
                        <Form.Control
                            type="email"
                            name="emailId"
                            value={contact.emailId}
                            onChange={(e) => onInputChange("contacts", index, e)}
                            readOnly={!editMode[index]}
                        />
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Label>Phone Number</Form.Label>
                        <Form.Control
                            type="text"
                            name="phoneNumber"
                            value={contact.phoneNumber}
                            onChange={(e) => onInputChange("contacts", index, e)}
                            readOnly={!editMode[index]}
                        />
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Label>Fax Number</Form.Label>
                        <Form.Control
                            type="text"
                            name="faxNumber"
                            value={contact.faxNumber}
                            onChange={(e) => onInputChange("contacts", index, e)}
                            readOnly={!editMode[index]}
                        />
                    </Form.Group>
                    <Button onClick={() => onEditToggle("users", index)}>
                        {editMode[index] ? "Cancel" : "Edit"}
                    </Button>
                    {editMode[index] && <Button onClick={() => onSave("contacts", index)}>Save</Button>}
                </Form>
            ))}
            <Button onClick={onAddNew}>Add New User</Button>
        </>
    );
}

export default UserList;